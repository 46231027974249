<template>
  <b-tabs id="integrations-tabs" class="mt-3 ">
    <b-tab :title="$gettext('payment gateways')">
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>mobilpay</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.mobilpay_id"
            name="id"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            component="file"
            :name="$gettext('private key')"
            @input="fileToBase64($event, 'mobilpay_pkey')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            component="file"
            :name="$gettext('certificate')"
            @input="fileToBase64($event, 'mobilpay_cert')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.mobilpay_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.mobilpay_id ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>stripe</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.stripe_publishable_key"
            :name="$gettext('publishable key')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.stripe_secret_key"
            :name="$gettext('secret key')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.stripe_webhook_key"
            :name="$gettext('webhook key')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.stripe_payment_method_id"
            :name="$gettext('payment method')"
            component="select"
            :options="paymentOptions"
            :rules="innerValue.stripe_publishable_key &&
                    innerValue.stripe_secret_key &&
                    innerValue.stripe_publishable_key ? 'required' : ''"
          />
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>paysafe</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.paysafe_username"
            :name="$gettext('api key username')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.paysafe_password"
            :name="$gettext('api key password')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.paysafe_token_username"
            :name="$gettext('single use token username')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.paysafe_token_password"
            :name="$gettext('single use token password')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.paysafe_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.paysafe_username &&
                    innerValue.paysafe_password &&
                    innerValue.paysafe_token_username &&
                    innerValue.paysafe_token_password ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" md="4">
          <custom-input
            noLabel
            v-model="innerValue.paysafe_has_zip_code"
            :name="$gettext('zip code')"
            component="checkbox"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            noLabel
            v-model="innerValue.paysafe_has_three_d_secure"
            :name="$gettext('three d secure')"
            component="checkbox"
          />
        </b-col>
        <b-col cols="12">
          <b-btn
            variant="primary"
            @click="addPaysafeCurrency"
            size="sm">
            <icon icon="plus" /><translate>add currency</translate>
          </b-btn>
        </b-col>
        <b-col cols="12">
          <b-form-group
            class="mt-4"
            :label="$gettext('currencies')"
            label-size="sm">
            <b-form-row>
              <b-col
                sm="6"
                md="4"
                v-for="(currency, index) in innerValue.paysafe_currencies"
                :key="index">
                <b-input-group size="sm">
                  <custom-input
                    v-model="currency.currency_code"
                    :id="'pc'+index"
                    noLabel
                    groupType="input"
                    :placeholder="$gettext('currency')"
                    apiKey="agency|commonCurrency"
                    labelKey="code"
                    component="select"
                    style="width: 40%;"
                    rules="required"
                    :reduce="obj => obj.code"/>
                  <custom-input
                    noLabel
                    groupType="input"
                    v-model="currency.account_number"
                    :placeholder="$gettext('account number')"
                    rules="required"
                    style="width: 40%;"
                  />
                  <b-input-group-append>
                    <b-btn
                      @click="removePaysafeCurrency(index)"
                      variant="danger"
                      size="sm"
                      style="max-height: 28px;">
                      <b-icon icon="dash"/>
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>
          </b-form-group>
          <hr />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>ecommpay</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.ecommpay_project_id"
            :name="`${$gettext('project id')}`"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            :name="`${$gettext('secret key')}`"
            v-model="innerValue.ecommpay_secret_key"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.ecommpay_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.ecommpay_project_id &&
                    innerValue.ecommpay_secret_key ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>epdq</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.epdq_pspid"
            name="PSPID"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            name="SHA-IN"
            v-model="innerValue.epdq_sha_in"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            name="SHA-OUT"
            v-model="innerValue.epdq_sha_out"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.epdq_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.epdq_pspid &&
                    innerValue.epdq_sha_in ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>gate-e</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.gate_e_merchant_id"
            :name="`${$gettext('merchant id')}`"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            :name="`${$gettext('security key')}`"
            v-model="innerValue.gate_e_security_key"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.gate_e_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.gate_e_merchant_id &&
                    innerValue.gate_e_security_key ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>opayo</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.opayo_vendor"
            :name="`${$gettext('vendor')}`"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            :name="`${$gettext('secret key')}`"
            v-model="innerValue.opayo_secret_key"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.opayo_payment_method_id"
            :name="$gettext('payment method')"
            :rules="innerValue.opayo_vendor &&
                    innerValue.opayo_secret_key ? 'required' : null"
            component="select"
            :options="paymentOptions"
          />
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
      </b-row>
    </b-tab>
    <b-tab :title="$gettext('bedbanks')">
      <b-row class="mt-4">
        <b-col md="12">
          <h5>stuba API</h5>
          <b-row>
            <b-col sm="6" md="3">
              <custom-input
                :name="`${$gettext('stuba org')}`"
                v-model="innerValue.stuba_org"
              />
            </b-col>
            <b-col sm="6" md="3">
              <custom-input
                :name="`${$gettext('stuba user')}`"
                v-model="innerValue.stuba_user"
              />
            </b-col>
            <b-col sm="6" md="3">
              <custom-input
                :name="`${$gettext('stuba password')}`"
                type="password"
                v-model="innerValue.stuba_password"
              />
            </b-col>
            <b-col sm="6" md="3">
              <custom-input
                v-model="innerValue.stuba_supplier_id"
                :name="$gettext('stuba supplier')"
                component="select"
                :requestParams="{ is_supplier: true }"
                :placeholders="{ company: innerValue.code }"
                apiKey="agency|autocompletePartner"
                labelKey="name"
                :reduce="obj => obj.id"
                :disabled="isNew"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab :title="$gettext('others')">
      <b-row class="mt-4">
        <b-col cols="12">
          <h5>flexitech</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.flexitech_api_id"
            :name="$gettext('api id')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.flexitech_subscription_key"
            :name="$gettext('subscription key')"
          />
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
        <b-col cols="12">
          <h5 v-translate>pnr converter</h5>
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.pnr_converter_public_key"
            :name="$gettext('public key')"
          />
        </b-col>
        <b-col sm="6" md="4">
          <custom-input
            v-model="innerValue.pnr_converter_private_key"
            :name="$gettext('private key')"
          />
        </b-col>
        <template v-if="market === 'ro'">
          <b-col cols="12">
            <hr/>
          </b-col>
          <b-col cols="12">
            <h5>mondial</h5>
          </b-col>
          <b-col sm="6" md="4">
            <custom-input
              v-model="innerValue.mondial_supplier_id"
              :name="$gettext('mondial')"
              component="select"
              :requestParams="{ is_supplier: true }"
              :placeholders="{ company: innerValue.code }"
              apiKey="agency|autocompletePartner"
              labelKey="name"
              :reduce="obj => obj.id"
              :disabled="isNew"
            />
          </b-col>
          <b-col sm="6" md="4">
            <custom-input
              v-model="innerValue.mondial_commission"
              component="number"
              :name="$gettext('commission')"
            />
          </b-col>
          <b-col sm="6" md="4">
            <custom-input
              v-model="innerValue.mondial_api_key"
              :name="$gettext('api key')"
            />
          </b-col>
          <b-col sm="6" md="4">
            <custom-input
              v-model="innerValue.mondial_agency_code"
              :name="$gettext('agency code')"
            />
          </b-col>
          <b-col sm="6" md="4">
            <custom-input
              v-model="innerValue.mondial_partner_code"
              :name="$gettext('partner code')"
            />
          </b-col>
        </template>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
import uniqueId from 'lodash/uniqueId'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split('base64,').pop())
    reader.onerror = (error) => reject(error)
  })
}

export default {
  mixins: [vModelMixin],
  props: ['isNew', 'market'],
  data() {
    return {
      paymentOptions: [],
    }
  },
  methods: {
    addPaysafeCurrency() {
      this.innerValue.paysafe_currencies.push({ id: -uniqueId() })
    },
    removePaysafeCurrency(index) {
      this.innerValue.paysafe_currencies.splice(index, 1)
    },
    fileToBase64(file, key) {
      getBase64(file).then((base64encoded) => {
        this.$set(this.innerValue, key, base64encoded)
      })
    },
  },
  created() {
    if (this.innerValue.payment_methods.length) {
      this.paymentOptions = [
        { text: this.$gettext('please select'), value: null },
        ...this.innerValue.payment_methods.map((paymentMethod) => ({
          value: paymentMethod.id,
          text: paymentMethod.label,
        })),
      ]
    }
  },
}
</script>

<style>
  #integrations-tabs .nav-tabs .nav-link {
    display: block !important;
  }
</style>
