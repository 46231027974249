var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","small":"","hover":"","responsive":"","items":_vm.innerValue.users,"fields":_vm.fields,"show-empty":_vm.innerValue.users && !_vm.innerValue.users.length,"empty-text":_vm.$gettext('no results found')},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
        'text-red': item.is_super,
        'text-gray': !item.active
        }},[_vm._v(" "+_vm._s(item.title)+" "),_c('b',[_vm._v(_vm._s(((item.first_name) + " " + (item.last_name))))])])]}},{key:"cell(username)",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{
        'text-red': item.is_super,
        'text-gray': !item.active
        }},[_vm._v(" "+_vm._s(item.username)+" ")])]}},{key:"head(buttons)",fn:function(){return [_c('b-btn',{staticClass:"float-right",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.edit()}}},[_c('fa-icon',{attrs:{"icon":"plus"}}),_c('translate',[_vm._v("add")])],1)]},proxy:true},{key:"cell(buttons)",fn:function(ref){
        var index = ref.index;
        var item = ref.item;
return [_c('edit-delete-buttons',{on:{"edit":function($event){return _vm.edit(index)},"remove":function($event){return _vm.remove(index)}}},[_c('b-btn',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.impersonate(item.id)}}},[_c('icon',{attrs:{"icon":"user-alt"}}),_c('translate',[_vm._v("impersonate")])],1)],1)]}}])}),_c('b-modal',{ref:"modal",attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","button-size":"sm","size":"lg","lazy":true,"title":_vm.objIndex !== undefined ? _vm.$gettext('edit') : _vm.$gettext('add')},on:{"ok":function($event){$event.preventDefault();return _vm.save($event)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('modal-buttons',{attrs:{"type":_vm.objIndex !== undefined ? 'update' : 'add'},on:{"save":_vm.save,"cancel":_vm.cancel}})]},proxy:true}])},[_c('ValidationObserver',{ref:"modalForm",attrs:{"slim":""}},[_c('Form',{attrs:{"companyOffices":_vm.innerValue.offices,"companyBrands":_vm.innerValue.brands,"paxTitles":_vm.paxTitles},on:{"save":_vm.save},model:{value:(_vm.obj),callback:function ($$v) {_vm.obj=$$v},expression:"obj"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }