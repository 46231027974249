<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.company.invoice_rules"
      :fields="fields"
      :show-empty="innerValue.company.invoice_rules && !innerValue.company.invoice_rules.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(address)="{ item }">
        {{ [item.address1, item.address2].join(' ') }}
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <div class="d-flex justify-content-between w-100">
          <custom-input
            v-model="obj.active"
            :name="$gettext('is active')"
            component="checkbox"
            noLabel
          />
          <modal-buttons
            :type="objIndex !== undefined ? 'update' : 'add'"
            @save="save"
            @cancel="cancel"
          />
        </div>
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          @save="save"
          :vatRateOptions="vatRateOptions"
          :accountingProductOptions="accountingProductOptions"
          :currencies="currencies"
          :unitsOfTime="unitsOfTime"
          :paymentTypeOptions="paymentTypeOptions"
        />
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './InvoicingRuleEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  props: ['saleCurrencies', 'vatTypes', 'accountingProductTypes'],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        {
          key: 'first_invoice_date',
          label: this.$gettext('first invoice date'),
          formatter: (value) => this.$options.filters.date(value),
        },
        {
          key: 'interval',
          label: this.$gettext('interval'),
          formatter: (value) => {
            if (!value) return this.$gettext('one-off')
            const auxStr = value.split('P')[1]
            const interval = auxStr.substr(0, auxStr.length - 1)
            const unitOfTime = this.unitsOfTime
              .find((uot) => uot.value === auxStr[auxStr.length - 1]).text
            return `${interval} ${unitOfTime}`
          },
        },
        { key: 'amount', label: this.$gettext('amount') },
        {
          key: 'currency_code',
          label: this.$gettext('currency'),
          formatter: (value) => this.currencies
            .find((currency) => currency.value === value).text,
        },
        {
          key: 'vat_rate_code',
          label: this.$gettext('vat rate'),
          formatter: (value) => this.vatRateOptions
            .find((vatType) => vatType.value === value).text,
        },
        {
          key: 'acc_product_code',
          label: this.$gettext('accounting product'),
          formatter: (value) => this.accountingProductOptions
            .find((accProdType) => accProdType.value === value).text,
        },
        { key: 'buttons', label: '' },
      ],
      unitsOfTime: [
        { value: 'D', text: this.$gettext('days') },
        { value: 'M', text: this.$gettext('months') },
        { value: 'Y', text: this.$gettext('years') },
      ],
      paymentTypeOptions: [
        { text: this.$gettext('one off'), value: true },
        { text: this.$gettext('recurring'), value: false },
      ],
      obj: {},
      objIndex: null,
    }
  },
  computed: {
    vatRateOptions() {
      return this.innerValue.agencyCompany.vat_types.map((vatType) => ({
        value: vatType.code,
        text: vatType.label,
      }))
    },
    accountingProductOptions() {
      return this.innerValue.agencyCompany.accounting_product_types.map((accProdType) => ({
        value: accProdType.code,
        text: accProdType.label,
      }))
    },
    currencies() {
      return this.innerValue.agencyCompany.sale_currencies.map((currency) => ({
        value: currency.currency_code,
        text: currency.currency_code,
      }))
    },
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (index !== undefined) {
        this.obj = {
          ...cloneDeep(this.innerValue.company.invoice_rules[index]),
          intervalCount: null,
          intervalUnit: null,
        }
      } else {
        this.obj = {
          intervalCount: null,
          intervalUnit: null,
          active: true,
          is_one_off: false,
        }
      }
      this.$refs.modal.show()
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.company.invoice_rules.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.obj.intervalCount && this.obj.intervalUnit) {
          this.obj.interval = `P${this.obj.intervalCount}${this.obj.intervalUnit}`
        }
        if (this.objIndex !== undefined) {
          this.innerValue.company.invoice_rules.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.company.invoice_rules.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
