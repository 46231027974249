/* eslint-disable no-param-reassign */
const defaultStyles = {
  default: {
    fontSize: 8,
  },
  title: {
    fontSize: 15,
    bold: true,
  },
  title1: {
    fontSize: 15,
    bold: true,
  },
  title2: {
    fontSize: 12,
    bold: true,
  },
  title3: {
    fontSize: 10,
    bold: true,
  },
  invoiceNo: {
    fontSize: 11,
    italics: true,
  },
  table: {
    margin: [0, 5, 0, 15],
    fontSize: 8,
  },
  tableHeader: {
    fontSize: 10,
    bold: true,
  },
  reportHeader: {
    fontSize: 8,
    bold: true,
  },
  grayBkgCell: {
    fillColor: '#eee',
    fontSize: 9,
  },
}

const createHr = (color) => {
  if (!color) color = '#aaa'
  return {
    table: {
      widths: ['*'],
      margin: [0, 0, 0, 0],
      body: [[' '], [' ']],
      fontSize: 1,
    },
    layout: {
      hLineWidth: (i, node) => ((i === 0 || i === node.table.body.length) ? 0 : 1),
      vLineWidth: () => 0,
      hLineColor: () => color,
    },
  }
}

const transformHTML = (text) => {
  text = text.replace(/&amp;/g, '&')
  text = text.replace(/&nbsp;/g, ' ')
  text = text.replace(/&acirc;/g, 'a')
  text = text.replace(/&icirc;/g, 'i')
  text = text.replace(/&ndash;/g, '-')
  text = text.replace(/&rdquo;/g, '"')
  text = text.replace(/&ldquo;/g, '"')
  text = text.replace(/&euro;/g, '€')
  return text.replace(/<[^>]+>/gm, '').trim()
}

export {
  defaultStyles,
  createHr,
  transformHTML,
}
