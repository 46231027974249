/* eslint-disable object-curly-newline */
// API endpoints
// EX: { name, url, cache, target, primaryKey
// TO DO: add include/exclude

export default [
  { name: 'auth', url: 'login', target: null },
  { name: 'logout', url: 'logout', target: null },
  { name: 'whoAmI', url: 'whoami', target: null },
  { name: 'company', url: 'admin/company', target: 'company', primaryKey: 'code' },
  { name: 'message', url: 'admin/banner_message', target: 'banner_message' },
  { name: 'user', url: 'admin/user', target: 'user' },
  { name: 'airport', url: 'admin/airport', target: 'airport', primaryKey: 'code' },
  { name: 'airline', url: 'admin/airline', target: 'airline' },
  { name: 'etripSupplier', url: 'admin/xml/etrip_supplier', target: 'supplier' },
  { name: 'currency', url: 'admin/currency', target: 'currency', primaryKey: 'code' },
  { name: 'bookingType', url: 'admin/booking_type', target: 'booking_type' },
  { name: 'country', url: 'admin/country', target: 'country' },
  { name: 'countrySubdivision', url: 'admin/country_subdivision', target: 'country_subdivision' },
  { name: 'city', url: 'admin/city', target: 'city' },
  { name: 'cityArea', url: 'admin/city_area', target: 'city_area' },
  { name: 'commonGeography', url: 'common/autocomplete/geography', target: null, cache: 60 * 60 * 24 }, // to do: change cache from crud.js
  { name: 'commonCurrency', url: 'common/autocomplete/currency', cache: 60 },
  { name: 'autocompletePartner', url: 'pub/:company/autocomplete/partner', target: null },
  { name: 'serialNumberRule', url: 'pub/:company/serial_number_rule', target: 'serial_number_rule' },
  { name: 'serialNumber', url: 'pub/:company/serial_number', target: 'serial_number' },
  { name: 'atolCertificateReport', url: 'admin/report/atol_certificates' },
  { name: 'protectionCoverReport', url: 'admin/report/insurance' },
  { name: 'atolRevenueReport', url: 'admin/report/atol_revenue' },
  { name: 'paxTitle', url: 'pub/:company/pax_title' },
  { name: 'stubaImport', url: 'admin/stuba_hotel_import' },
]
