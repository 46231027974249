// Main router

import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthData from '@/shared/http/auth-data'
import Auth from '@/shared/http/auth'
import { getFilters, setFilters } from '@/shared/services/filters'
import Page404 from '@/shared/components/pages/Page404.vue'
import Page500 from '@/shared/components/pages/Page500.vue'

import Login from './app/auth/Login.vue'
import View from './app/View.vue'
import Routes from './app/router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Page404,
    meta: {},
  },
  {
    path: '/400',
    name: 'Page404',
    component: Page404,
    meta: {},
  },
  {
    path: '/500',
    name: 'Page500',
    component: Page500,
    meta: {},
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {},
  },
  {
    path: '/',
    component: View,
    children: Routes,
    redirect: { name: 'Dashboard' },
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

router.beforeEach(async (to, from, next) => {
  // Check if it's not logged
  if (!AuthData.isLoggedIn) {
    // If already checked if it's logged in redirect to Login
    if (AuthData.isLoggedInChecked) {
      // Avoid infinite loop
      if (to.name === 'Login') next()
      else next({ name: 'Login' })
      return
    }

    // Get user by cookie if available
    const companyUser = await Auth.refreshLoggedUser()
    const agencyUser = await Auth.agencyWhoAmI()

    // If the user is invalid reset auth
    if (!companyUser || !agencyUser || companyUser.id !== agencyUser.id) {
      AuthData.resetData()

      // Check if route requires auth
      const requiresAuth = to.matched.find((el) => el.meta.requiresAuth)
      if (requiresAuth) {
        next({ name: 'Login' }) // Force to login (it will retrigger the route middleware)
      } else {
        next() // Go to the normal route (it won't retrigger the route middleware)
      }
      return
    }
  }

  // Check if it's logged
  if (AuthData.isLoggedIn) {
    // Cache query params for reuse
    if (to.meta && to.meta.registerQueryParams) {
      // If no filters on url
      if (!Object.keys(to.query).length) {
        if (!from.name) next() // If page is refreshed without params redirect
        else {
          // Check if there are saved filters and redirect
          const filters = getFilters(to.path)
          if (filters) next({ path: to.path, query: filters })
          else next()
        }
      } else {
        // Set new filters and redirect
        setFilters(to.path, to.query)
        next()
      }
    } else if (to.name === 'Login') { // If login redirect home
      next({ name: 'Dashboard' })
    } else { // Redirect on normal route
      next()
    }
  }
})

export default router
