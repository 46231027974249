<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.ledger_currencies"
      :fields="fields"
      :show-empty="innerValue.ledger_currencies && !innerValue.ledger_currencies.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          :offices="offices"
          :salesCurrencies="innerValue.sale_currencies"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import uniqueId from 'lodash/uniqueId'
import cloneDeep from 'lodash/cloneDeep'
import Form from './LedgerEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  props: {
    offices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        {
          key: 'office_id',
          label: this.$gettext('office'),
          formatter: (value) => {
            const office = this.offices.find((off) => off.id === value)
            if (office) return office.name
            return ''
          },
        },
        { key: 'currency_code', label: this.$gettext('currency code') },
        {
          key: 'start_date',
          label: this.$gettext('start date'),
          formatter: (value) => this.$options.filters.date(value),
        },
        { key: 'start_amount', label: this.$gettext('start amount') },
        { key: 'buttons', label: '' },
      ],
      objIndex: null,
      obj: {},
    }
  },
  methods: {
    edit(index) {
      if (this.innerValue.sale_currencies.length && this.offices.length) {
        this.objIndex = index
        if (index !== undefined) this.obj = cloneDeep(this.innerValue.ledger_currencies[index])
        else {
          this.obj = { id: -uniqueId() }
        }
        this.$refs.modal.show()
      } else {
        this.$toast({
          title: this.$gettext('warning'),
          message: this.$gettext('can\'t create ledger entry without added offices and sale currencies'),
          variant: 'danger',
        })
      }
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.ledger_currencies.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.ledger_currencies.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.ledger_currencies.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
