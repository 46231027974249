<template>
  <div>
    <b-btn-group size="sm">
      <b-btn type="button" @click="$emit('cancel')" variant="warning">
        <icon icon="times" /><translate>cancel</translate>
      </b-btn>
      <b-btn v-disableOnRequests type="submit" @click="$emit('save')" variant="success">
        <template v-if="type === 'add'">
          <icon icon="plus"/>
          <translate>add</translate>
        </template>
        <template v-else-if="type === 'update'">
          <icon icon="check"/>
          <translate>update</translate>
        </template>
        <template v-else>
          <icon icon="save"/>
          <translate>save</translate>
        </template>
      </b-btn>
    </b-btn-group>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null,
      validator: (val) => ['add', 'update'].includes(val),
    },
  },
}
</script>
