<template>
  <div>
  <v-select
    :appendToBody="$attrs.appendToBody"
    :multiple="multiple"
    :name="name"
    v-model="innerValue"
    :options="results"
    :placeholder="placeholder || name"
    @search="onSearch"
    :reduce="localReduce"
    :disabled="disabled"
    :label="labelKey"
    :selectable="selectable"
    :clearable="$attrs.clearable"
    @option:selecting="onSelecting"
    @option:selected="onSelect"
    :class="{
      'select-is-invalid': state === false,
      'select-is-valid': state === true,
    }"
  >

  <template v-slot:option="option">
    <icon :icon="isCheckedClass(option)" /> {{ option[labelKey] }}
  </template>

    <!-- INPUT -->
    <template #search="{attributes, events}">
      <input
        autocomplete="off"
        class="vs__search"
        v-bind="attributes"
        @input="onTextChange($event.target.value)"
        v-on="events"
      />
    </template>

    <!-- NO OPTIONS -->
    <template #no-options>
      <span key="no" v-if="!loading">
        <translate>no results found</translate>
      </span>
      <span key="load" v-else>
        <translate>loading</translate>...
      </span>
    </template>

    <!-- FOOTER -->
    <li slot="list-footer" v-if="lastPage > currentPage && apiKey">
      <b-btn-group size="sm" class="w-100 mt-1">
        <b-btn
          :disabled="currentPage === 1"
          variant="outline-dark"
          @click="previous()"
        >
          <translate>previous</translate>
        </b-btn>
        <b-btn
          variant="outline-dark"
          @click="next()"
        >
          <translate>next</translate>
        </b-btn>
      </b-btn-group>
    </li>
  </v-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'SelectInput',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    apiKey: {
      type: String,
      default: null,
    },
    labelKey: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    restrictToList: {
      type: Boolean,
      default: true,
    },
    reduce: {
      type: Function,
      default: null,
    },
    requestParams: {
      type: Object,
      default: null,
    },
    initialParams: {
      type: Object,
      default: null,
    },
    notSelectable: {
      type: Function,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholders: {
      type: Object,
      default: null,
    },
  },
  mixins: [vModelMixin],
  data: () => ({
    loading: false,
    results: [],
    pageSize: 10,
    currentPage: 1,
    lastPage: 1,
  }),
  computed: {
    localReduce() {
      if (this.reduce) return this.reduce
      if (this.apiKey) return (obj) => obj
      return (obj) => obj.value
    },
  },
  watch: {
    // sometimes values take time to come
    // considering a computed but it conflicts a little with filteredResults
    options() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  async mounted() {
    // eslint-disable-next-line no-restricted-globals
    if (this.apiKey && this.value && !isNaN(this.value)) {
      const api = this.apiKey.split('|')
      const crudService = api[0] === 'agency' ? this.$crud : this.$newCrud
      const data = await crudService[api[0]][api[1]]
        .get({
          placeholders: this.placeholders,
          params: { id: this.value, ...this.initialParams },
        })
      const result = {}
      result[this.labelKey] = data[this.labelKey]
      result.id = data.id
      this.results = [result]
      this.loading = false
    }
  },
  methods: {
    onSelect(event) {
      this.$emit('change', event)
    },
    onSelecting(event) {
      if (this.innerValue) {
        if (this.multiple) {
          const index = this.innerValue.indexOf(event.value)
          if (index !== -1) this.innerValue.splice(index, 1)
        }
      }
    },
    isCheckedClass(option) {
      if (this.innerValue) {
        if (this.multiple) {
          if (this.innerValue.indexOf(option.value) !== -1) return 'times'
        }
        if (this.innerValue === option.value) return 'check'
      }
      return null
    },
    onTextChange(text) {
      if (!this.restrictToList) {
        this.$emit('input', text)
      }
    },
    selectable(option) {
      if (this.multiple) {
        const values = this.innerValue ? this.innerValue : []
        return values.indexOf(option) === -1
      }

      if (this.notSelectable) {
        return !this.notSelectable(option)
      }

      return true
    },
    filteredResults: debounce(async (search, loading, vm) => {
      if (!vm.options.length && search) {
        loading(true)
        let params = {
          page: vm.currentPage,
          pageSize: vm.pageSize,
          key: search,
        }

        if (vm.requestParams) params = { ...params, ...vm.requestParams }

        const api = vm.apiKey.split('|')
        const crudService = api[0] === 'agency' ? vm.$crud : vm.$newCrud
        // eslint-disable-next-line no-param-reassign
        vm.results = await crudService[api[0]][api[1]].get({
          placeholders: vm.placeholders,
          params,
        })

        loading(false)
      }
    }, 350),
    onSearch(search, loading) {
      this.filteredResults(search, loading, this)
    },
    previous() {
      if (this.currentPage !== 1) {
        this.currentPage -= 1
        this.getResults()
      }
    },
    next() {
      if (this.lastPage > this.currentPage) {
        this.currentPage += 1
        this.getResults()
      }
    },
    async getResults() {
      let params = { page: this.currentPage, pageSize: this.pageSize }
      if (this.requestParams) params = { ...params, ...this.requestParams }

      const api = this.apiKey.split('|')
      const crudService = api[0] === 'agency' ? this.$crud : this.$newCrud
      // eslint-disable-next-line camelcase
      const { data, last_page } = await crudService[api[0]][api[1]]
        .get({
          placeholders: this.placeholders,
          params,
        })

      this.results = data.map((obj) => {
        const resultProps = {}
        resultProps[this.labelKey] = obj[this.labelKey]
        resultProps.id = obj.id
        return resultProps
      })
      // eslint-disable-next-line camelcase
      this.lastPage = last_page

      this.loading = false
    },
    init() {
      if (this.options.length) {
        this.results = this.options.map((obj) => {
          const resultProps = {}
          if (typeof obj !== 'object') {
            resultProps[this.labelKey] = obj
            resultProps.id = obj
            resultProps.value = obj
          } else {
            resultProps[this.labelKey] = obj[this.labelKey]
            resultProps.id = obj.value
            resultProps.value = obj.value
          }
          return resultProps
        })
      }
    },
  },
}
</script>
