<template>
  <b-textarea
    :id="id"
    :name="name"
    :state="state"
    :placeholder="placeholder || name"
    size="sm"
    v-model="innerValue"
    :rows="rows"
  />
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'TextAreaInput',
  inheritAttrs: false,
  mixins: [vModelMixin],
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 1,
    },
  },
}
</script>
