<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.office_id"
          :name="$gettext('office')"
          rules="required"
          component="select"
          labelKey="text"
          :options="officeOptions"
          :reduce="obj => obj.value"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.currency_code"
          :name="$gettext('currency')"
          rules="required"
          component="select"
          labelKey="text"
          :options="currencyOptions"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.start_amount"
          :name="$gettext('start amount')"
          component="number"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.start_date"
          :name="$gettext('start date')"
          component="date"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: {
    offices: {
      type: Array,
      required: true,
    },
    salesCurrencies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      officeOptions: [{ value: null, text: this.$gettext('please select') }],
      currencyOptions: [],
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
  created() {
    this.offices.map((office) => {
      this.officeOptions.push({ text: office.name, value: office.id })
    })
    this.salesCurrencies.map((currency) => {
      this.currencyOptions.push(currency.currency_code)
    })
  },
}
</script>
