<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      :filters="filters"
      ref="crudView"
      :hidePagination="true"
      :hideSorting="true"
      :hideAdd="true"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
        >
        </filters>
      </template>

      <template v-slot:filter-buttons-left="{ results }">
        <b-dropdown
          :disabled="!results.length"
          size="sm"
          right
          variant="danger"
          :text="$gettext('download')"
        >
          <b-dropdown-item name="pdf" @click="openPdfReport()">
            PDF
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- RESULTS -->
      <template v-slot:results="{ results }">
        <results
          :results="results"
        />
      </template>
    </crud-view>

    <!-- PDF MODAL -->
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      :ok-disabled="$root.pendingRequestsObj.active"
      button-size="sm"
      ref="pdfView"
      :lazy="true"
      :title="$gettext('view')"
    >
      <pdf-doc-view
        @cancel="hidePdfModal"
        :documentTemplate="documentTemplate"
      />
    </b-modal>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'
import PdfDocView from '@/shared/components/modals/PdfDocView.vue'

import Filters from './Filters.vue'
import Results from './Results.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
    PdfDocView,
  },
  data() {
    return {
      crudEndpoint: 'agency|atolCertificateReport',
      filters: this.getDefaultFilters(),
      documentTemplate: null,
    }
  },
  methods: {
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    hidePdfModal() {
      this.$refs.pdfView.hide()
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {
      }
    },
    openPdfReport() {
      const { results } = this.$refs.crudView

      const dd = {
        content: [
          {
            table: {
              body: [
                [],
              ],
            },
          },
        ],
      }

      results.forEach((item, index) => {
        dd.content[index] = {
          style: {
            fontSize: 12,
            width: 100,
          },
          table: {
            widths: ['*', 'auto'],
            headerRows: 1,
            body: [
              [
                {
                  text: 'Certificate type', bold: true, color: '#323639', fillColor: '#f0f3f5',
                },
                {
                  text: 'No issued', bold: true, color: '#323639', fillColor: '#f0f3f5',
                },
              ],
            ],
          },
        }
        item.certificates.forEach((certificate) => {
          dd.content[index].table.body.push([certificate.type, certificate.count])
        })
        dd.content[index].table.body.push([
          {
            text: `Total for ${item.name}`, bold: true, color: '#007AFF', fontSize: 14,
          },
          {
            text: item.total, bold: true, color: '#007AFF', fontSize: 14,
          },
        ])
      })

      for (let i = 0; i < dd.content.length; i += 1) {
        dd.content[i].margin = [0, 10]
      }

      dd.content.unshift({
        text: 'Report Results',
        style: {
          fontSize: 18,
          bold: true,
        },
      })

      this.documentTemplate = dd

      this.$refs.pdfView.show()
    },
  },
}
</script>
