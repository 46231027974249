// Fonts plugin

import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Icon from '@/shared/components/icons/Icon.vue'

library.add(fas)
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('icon', Icon) // wrapper with class
