<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      :filters="filters"
      ref="crudView"
      :hidePagination="true"
      :hideSorting="true"
      :hideAdd="true"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
        />
      </template>

      <!-- RESULTS -->
      <template v-slot:results="{ results }">
        <results
          :results="results"
        />
      </template>
    </crud-view>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'

import Filters from './Filters.vue'
import Results from './Results.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
  },
  data() {
    return {
      crudEndpoint: 'agency|protectionCoverReport',
      filters: this.getDefaultFilters(),
    }
  },
  methods: {
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {
      }
    },
  },
}
</script>
