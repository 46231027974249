<template>
  <b-row class="mt-4">
    <b-col cols="12">
      <b-form-group>
        <template v-slot:label>
          <span v-translate>sale currencies</span>
          <b-btn
            class="ml-2"
            variant="primary"
            @click="addSaleCurrency"
            size="sm">
            <b-icon icon="plus"/>
          </b-btn>
        </template>
        <b-form-row>
          <b-col
            sm="6"
            md="4"
            v-for="(saleCurrency, saleCurrencyIndex) in innerValue.sale_currencies"
            :key="'sc' + saleCurrencyIndex">
            <b-input-group size="sm" class="mt-2">
              <custom-input
                v-model="saleCurrency.currency_code"
                :id="'sc'+saleCurrencyIndex"
                :placeholder="$gettext('sale currency')"
                rules="required"
                apiKey="agency|commonCurrency"
                labelKey="code"
                component="select"
                groupType="input"
                class="w-75"
                noLabel
                :reduce="obj => obj.code"/>
              <b-input-group-append>
                <b-btn
                  v-show="innerValue.sale_currencies.length > 1"
                  @click="removeSaleCurrency(saleCurrencyIndex)"
                  variant="danger"
                  size="sm"
                  style="max-height: 28px;">
                  <b-icon icon="dash"/>
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
import uniqueId from 'lodash/uniqueId'

export default {
  mixins: [vModelMixin],
  methods: {
    addSaleCurrency() {
      for (let i = 0; i < this.innerValue.sale_currencies.length; i += 1) {
        const saleCurrency = this.innerValue.sale_currencies[i]
        if (!saleCurrency.currency_code) return
      }
      this.innerValue.sale_currencies.push({ id: -uniqueId() })
    },
    removeSaleCurrency(index) {
      this.innerValue.sale_currencies.splice(index, 1)
    },
  },
  created() {
    if (!this.innerValue.sale_currencies.length) {
      this.innerValue.sale_currencies.push({ id: -uniqueId() })
    }
  },
}
</script>
