<template>
  <b-btn-group size="sm" class="float-right">
    <slot></slot>
    <b-btn
      @click="$emit('edit')"
      variant="primary"
    >
      <icon icon="edit" /><translate>edit</translate>
    </b-btn>
    <b-btn
      :disabled="removeDisabled"
      v-if="!hideDelete"
      @click="$emit('remove')"
      variant="danger"
    >
      <icon icon="times" /><translate>delete</translate>
    </b-btn>
  </b-btn-group>
</template>

<script>
export default {
  props: ['removeDisabled', 'hideDelete'],
}
</script>
