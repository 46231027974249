<template>
  <b-row>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.date"
        :name="$gettext('date')"
        component="date"
        rules="required"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        multiple
        v-model="innerValue.booking_types"
        :name="$gettext('booking types')"
        :options="bookingTypeOptions"
        component="select"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        multiple
        v-model="innerValue.booking_status"
        :name="$gettext('booking status')"
        :options="bookingStatusOptions"
        component="select"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        noLabel
        v-model="innerValue.ignore_infants"
        :name="$gettext('ignore infants')"
        component="checkbox"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['bookingTypeOptions'],
  data() {
    return {
      bookingStatusOptions: [
        { value: 'quote', text: this.$gettext('quote') },
        { value: 'option', text: this.$gettext('option') },
        { value: 'confirmed', text: this.$gettext('confirmed') },
        { value: 'cancelled', text: this.$gettext('cancelled') },
      ],
    }
  },
}
</script>
