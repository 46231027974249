// V-model child-parent communication mixin

export default {
  props: ['value'],
  created() {
    if (this.value !== undefined) {
      this.innerValue = this.value
    }
  },
  data() {
    return {
      innerValue: null,
    }
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
  },
}
