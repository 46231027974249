<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.start_date"
          :name="$gettext('start date')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.end_date"
          :name="$gettext('end date')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.payment_due_date"
          :name="$gettext('payment due date')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.original_payment_plan_id"
          :name="$gettext('payment plan')"
          component="select"
          :reduce="obj => obj.value"
          @change="onPaymentPlanUpdated"
          :options="paymentOptions"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.payment_plan.name"
          :name="$gettext('plan name')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          component="number"
          v-model="innerValue.payment_plan.users_limit"
          :name="$gettext('number of users')"
          rules="required"
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col cols="12" v-for="(gr, grIndex) in accessOptions" :key="'group' + grIndex">
        <h5 class="mb-4">{{ gr.group }}</h5>
        <b-row>
          <template v-for="(st, stIndex) in gr.options">
            <b-col cols="12" sm="6" md="4" lg="3" :key="'setting' + stIndex">
              <b-form-checkbox
                style="margin-bottom: 1.5rem;"
                :id="'checkbox' + grIndex + '/' + stIndex"
                :value="st.string"
                v-model="innerValue.payment_plan[gr.group + '_access']"
                size="sm"
              >
                {{ $gettext(st.label) }}
              </b-form-checkbox>
            </b-col>
          </template>
        </b-row>
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col cols="12">
        <b-btn
          variant="primary"
          @click="addPricingModel"
          size="sm">
          <icon icon="plus" /><translate>add pricing model</translate>
        </b-btn>
      </b-col>
      <b-col cols="12">
        <b-row
          class="mt-4"
          v-for="(pricingModel, index) in innerValue.payment_plan.subscription_pricing_models"
          :key="index">
          <b-col cols="12">
            <h5 style="display: inline-block;">
              <span v-translate>pricing model</span> {{ index + 1 }}
            </h5>
            <b-btn
              v-show="innerValue.payment_plan.subscription_pricing_models.length > 1"
              @click="removePricingModel(index)"
              variant="danger"
              size="sm"
              class="ml-2">
              <b-icon icon="dash"/>
            </b-btn>
          </b-col>
          <b-col cols="12" sm="6">
            <custom-input
              component="number"
              v-model="pricingModel.fee"
              :name="$gettext('monthly fee')"
              rules="required"
            />
          </b-col>
          <b-col cols="12" sm="6">
            <custom-input
              v-model="pricingModel.currency_code"
              :name="$gettext('currency')"
              component="select"
              apiKey="agency|commonCurrency"
              labelKey="code"
              rules="required"
              :reduce="obj => obj.code"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.amount_paid"
          :name="$gettext('amount paid')"
          component="number"
          disabled
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.auto_renew"
          :name="$gettext('automatically renew')"
          component="checkbox"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          v-model="innerValue.discount_info"
          :name="$gettext('discount info')"
          component="textarea"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: {
    paymentPlans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      accessOptions: [
        {
          group: 'agency',
          options: [
            { string: 'test_1', label: this.$gettext('test 1') },
            { string: 'test_2', label: this.$gettext('test 2') },
            { string: 'test_3', label: this.$gettext('test 3') },
          ],
        },
        {
          group: 'etrip',
          options: [
            { string: 'test_1', label: this.$gettext('test 1') },
            { string: 'test_2', label: this.$gettext('test 2') },
            { string: 'test_3', label: this.$gettext('test 3') },
          ],
        },
      ],
      paymentOptions: [],
    }
  },
  created() {
    this.paymentPlans.forEach((pp) => {
      this.paymentOptions.push({
        value: pp.id,
        text: pp.name,
      })
    })
  },
  methods: {
    save() {
      this.$emit('save')
    },
    onPaymentPlanUpdated(evt) {
      const paymentPlan = this.paymentPlans.find((pp) => pp.id === evt.id)
      delete paymentPlan.id
      for (let i = 0; i < paymentPlan.pricing_models.length; i += 1) {
        delete paymentPlan.pricing_models[i].id
      }
      this.innerValue.payment_plan.name = paymentPlan.name
      this.innerValue.payment_plan.agency_access = paymentPlan.agency_access
      this.innerValue.payment_plan.etrip_access = paymentPlan.etrip_access
      this.innerValue.payment_plan.users_limit = paymentPlan.users_limit
      // eslint-disable-next-line max-len
      this.innerValue.payment_plan.subscription_pricing_models = paymentPlan.pricing_models
    },
    addPricingModel() {
      this.innerValue.payment_plan.subscription_pricing_models.push({
        fee: '',
        currency_code: '',
      })
    },
    removePricingModel(index) {
      this.innerValue.payment_plan.subscription_pricing_models.splice(index, 1)
    },
  },
}
</script>
