// Filters service

import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

// Set filters in localStorage
function setFilters(routePath, queryParams) {
  // Ids are numbers
  Object.keys(queryParams).forEach((key) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(queryParams[key]) && !isNaN(parseFloat(queryParams[key]))) {
      // eslint-disable-next-line no-param-reassign
      queryParams[key] = +queryParams[key]
    } else if (queryParams[key] === 'true') {
      // eslint-disable-next-line no-param-reassign
      queryParams[key] = true
    } else if (queryParams[key] === 'false') {
      // eslint-disable-next-line no-param-reassign
      queryParams[key] = false
    }
  })
  localStorage.setItem(routePath, JSON.stringify(queryParams))
}

// Get filters from localStorage
function getFilters(route) {
  let filters

  if (route.$route) filters = localStorage.getItem(route.$route.path)
  else filters = localStorage.getItem(route)

  return JSON.parse(filters)
}

// Clear empty properties
function normalizeParams(params) {
  const filteredParams = omitBy(params, (el) => (isNil(el) || el === ''))
  return filteredParams
}

export {
  setFilters,
  getFilters,
  normalizeParams,
}
