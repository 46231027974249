<template>
  <div>
    <!-- FILTERS CARD -->
    <b-overlay
      :show="$root.pendingRequestsObj.active"
      spinner-type="grow"
      opacity="0.2"
      spinner-variant="primary">
      <b-card v-show="!noFilters">
        <template v-slot:header>
          <b>
            <icon icon="filter" /><translate>filters</translate>
          </b>
        </template>

        <ValidationObserver ref="filters" slim>
          <b-form novalidate @submit.prevent="search(true)">
            <!-- FILTER SLOT -->
            <slot name="filters" />
              <hr />
              <!-- FILTER BUTTONS SLOT -->
              <b-row>
                <b-col md="12">
                  <b-btn-group
                    size="sm"
                    class="float-right text-xs-center">
                    <slot name='filter-buttons-left' :results="results ? results : []"></slot>
                    <b-btn variant="warning" @click="resetFilters">
                      <icon icon="sync-alt" /><translate>reset</translate>
                    </b-btn>
                    <b-btn
                      type="submit"
                      v-disableOnRequests
                      variant="success"
                      @click.prevent="search(true)">
                      <icon icon="search" /><translate>search</translate>
                    </b-btn>
                    <slot name="filter-buttons" :results="results ? results : []">
                      <b-btn
                        v-if="!hideAdd"
                        variant="primary"
                        @click="add"
                        size="sm">
                        <icon icon="plus" /><translate>add</translate>
                      </b-btn>
                    </slot>
                  </b-btn-group>
                </b-col>
              </b-row>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-overlay>

    <!-- RESULTS CARD -->
    <b-card class="mt-3" no-body v-show="initialValidationPassed">
      <template v-slot:header>
        <slot name="results-header">
          <b>
            <icon icon="list" /><translate>results</translate>
              <template v-if="totalRows !== null">
              - {{ totalRows }}
              <span v-if="totalRows === 1" v-translate>result</span>
              <span v-else v-translate>results</span>
            </template>
          </b>
        </slot>
      </template>

      <!-- RESULTS TABLE -->
      <div v-show="totalRows !== null">
        <slot
          :sort-changed="sortChanged"
          :results="results ? results : []"
          :pageIndex="(currentPage - 1) * pageSize"
          name="results"
        />
      </div>
      <b-skeleton-table
        v-if="totalRows === null"
        animation="fade"
      />

      <!-- RESULTS FOOTER -->
      <template v-slot:footer v-if="!hideSorting && !hidePagination">
        <slot name="results-footer">
          <b-row>
            <b-col/>
            <b-col cols="12" sm="6" md="4" lg="3">
              <b-overlay
                :show="$root.pendingRequestsObj.active"
                spinner-type="grow"
                opacity="0.2"
                spinner-small
                spinner-variant="primary">
                <b-form-select
                  v-if="totalRows && !hideSorting"
                  size="sm"
                  v-model="pageSize"
                  @change="search()"
                  :options="resultsPerPage"
                />
              </b-overlay>
            </b-col>
          </b-row>
          <b-pagination
            :disabled="$root.pendingRequestsObj.active"
            class="mb-0 mt-3"
            align="center"
            @change="search()"
            size="sm"
            v-if="results && !hidePagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
          />
        </slot>
      </template>
    </b-card>
  </div>
</template>

<script>
import { normalizeParams } from '@/shared/services/filters'
import cloneDeep from 'lodash/cloneDeep'

export default {
  props: ['fields', 'filters', 'crudEndpoint', 'noFilters', 'hidePagination', 'hideSorting', 'hideAdd', 'apiQuery', 'sortDirectionInit', 'sortByInit'],
  mounted() {
    this.updateFilters()
  },
  data() {
    return {
      initialValidationPassed: true,
      results: null,
      totalRows: null,
      currentPage: 1,
      pageSize: 25,
      sortBy: this.sortByInit || null,
      sortDirection: this.sortDirectionInit || null,
      resultsPerPage: [
        { text: this.$gettext('25 results / page'), value: 25 },
        { text: this.$gettext('50 results / page'), value: 50 },
        { text: this.$gettext('75 results / page'), value: 75 },
        { text: this.$gettext('100 results / page'), value: 100 },
      ],
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.updateFilters()
      },
    },
  },
  methods: {
    async updateFilters() {
      const isValid = await this.$refs.filters.validate()

      if (isValid) {
        const queryParams = this.$route.query
        this.initialValidationPassed = true // In case it failed first time

        if (Object.keys(queryParams).length) {
          if (queryParams.page) this.currentPage = +queryParams.page + 1
          if (queryParams.pageSize) this.pageSize = +queryParams.pageSize

          this.getResults(queryParams)
        } else {
          this.search(true, true)
        }
      } else {
        this.initialValidationPassed = false
        this.$toastInvalidForm()
      }
    },
    async search(resetPage, replace) {
      const isValid = await this.$refs.filters.validate()

      if (isValid) {
        const params = cloneDeep(this.filters)

        // Current page
        if (resetPage) params.page = 0
        else params.page = this.currentPage - 1

        // Page size
        params.pageSize = this.pageSize

        // Order
        if (this.sortBy && this.sortDirection) {
          params.order = this.sortBy + (this.sortDirection === 'desc' ? ' desc' : '')
        }

        // Refresh route
        this.$router[replace ? 'replace' : 'push']({ path: this.$route.path, query: normalizeParams(params) })
          .catch((err) => {
            if (err.name === 'NavigationDuplicated') this.getResults(params)
            else throw err
          })
      } else {
        this.$toastInvalidForm()
      }
    },
    async getResults(params) {
      this.results = null
      this.totalRows = null

      const api = this.crudEndpoint.split('|')
      const crudService = api[0] === 'agency' ? this.$crud : this.$newCrud
      const payload = {
        params,
        blocker: true,
        cancellable: this,
      }

      if (this.apiQuery) payload.query = this.apiQuery

      const response = await crudService[api[0]][api[1]]
        .get(payload)

      if (response.items) {
        this.results = response.items
        this.totalRows = response.total || 0
      } else {
        this.results = response
        this.totalRows = response.length || 0
      }

      this.$emit('results-updated', this.results)
      this.$emit('filters-updated', cloneDeep(params))
    },
    async sortChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDirection = ctx.sortDesc ? 'desc' : 'asc'

      await this.search(true)

      const sortByLabel = this.fields.find((field) => field.key === ctx.sortBy).label
      const sortTranslation = ctx.sortDesc ? this.$gettext('sorted descending by') : this.$gettext('sorted ascending by')
      this.$toast({
        title: this.$gettext('sort'),
        message: `${sortTranslation} ${sortByLabel}`,
        variant: 'success',
      })
    },
    add() {
      this.$emit('add')
    },
    resetFilters() {
      this.sortBy = null
      this.sortDirection = null

      this.$emit('reset-filters')
    },
  },
}
</script>
