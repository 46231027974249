// Loading modal plugin

import Vue from 'vue'
import Loading from '@/shared/components/modals/Loading.vue'

const Plugin = {
  install(VueInstance) {
    this.resolve = null
    this.vm = null

    const Modal = VueInstance.extend(Loading)
    this.vm = new Modal().$mount()

    // eslint-disable-next-line no-param-reassign
    VueInstance.prototype.$showLoading = (text) => new Promise((resolve) => {
      this.resolve = resolve

      this.vm.text = text
      this.vm.visible = true

      document.getElementById('app').append(this.vm.$el)
    })

    // eslint-disable-next-line no-param-reassign
    VueInstance.prototype.$hideLoading = () => {
      if (this.resolve) {
        this.vm.text = null
        this.vm.visible = false

        this.resolve(true)
        this.resolve = null
      }
    }
  },
}

Vue.use(Plugin)
