<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col cols="12" md="6">
        <custom-input
          :name="$gettext('name')"
          rules="required"
          v-model="innerValue.name"
        />
      </b-col>

      <b-col cols="12" md="6">
        <custom-input
          :name="$gettext('local name')"
          v-model="innerValue.local_name"
        />
      </b-col>

      <b-col cols="12" md="6" v-if="showIataCode">
        <custom-input
          :name="$gettext('iata code')"
          :rules="'length:'+iataCodeLength"
          v-model="innerValue.iata_code"
        />
      </b-col>

      <b-col cols="12" md="6" v-if="showRegionCode">
        <custom-input
          v-model="innerValue.iata_region_code"
          :name="$gettext('iata region')"
          :options="regions"
          component="select"
        />
      </b-col>

      <b-col cols="12" md="6" v-if="showCountry">
        <custom-input
          v-model="innerValue.country_id"
          :name="$gettext('country')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :requestParams="{ types: 'C', size: 20 }"
          :initialParams="{ type: 'C' }"
          :reduce="obj => obj.id"
          @change="$emit('countrySelected', $event)"
        />
      </b-col>

      <b-col
        cols="12" md="6"
        v-if="innerValue.country && innerValue.country.has_subdivisions && showSubdivision"
      >
        <custom-input
          v-model="innerValue.subdivision_id"
          :name="$gettext('subdivision')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :requestParams="{ types: 'S', size: 20 }"
          :initialParams="{ type: 'S' }"
          :reduce="obj => obj.id"
        />
      </b-col>

     <b-col cols="12" md="6" v-if="showCity">
        <custom-input
          v-model="innerValue.city_id"
          :name="$gettext('city')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :requestParams="{ types: 'T', size: 20 }"
          :initialParams="{ type: 'T' }"
          :reduce="obj => obj.id"
        />
      </b-col>
    </b-row>

    <b-row v-if="showSubdivisions">
      <b-col cols="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.has_subdivisions"
          :name="$gettext('has subdivisions (states/counties/regions/lands/etc)')"
          noLabel
        />
      </b-col>

      <b-col cols="12" md="6" v-if="innerValue.has_subdivisions">
        <custom-input
          v-model="innerValue.subdivision_label"
          :name="$gettext('subdivision label')"
          :options="subDivisions"
          component="select"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <custom-input
          v-if="showEU"
          component="checkbox"
          v-model="innerValue.is_eu"
          :name="$gettext('is eu')"
          noLabel
        />
        <custom-input
          v-if="showDomestic"
          component="checkbox"
          v-model="innerValue.is_domestic"
          :name="$gettext('is domestic')"
          noLabel
        />
        <custom-input
          component="checkbox"
          v-model="innerValue.active"
          :name="$gettext('active')"
          noLabel
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
/*
  This modal is used for: cities, cityAreas, countries, subdivisions
  Since all have different inputs rendered, we will use props to determine which one is showed
*/

export default {
  mixins: [vModelMixin],
  data() {
    return {
      iata_region: '',
      subDivisions: ['State', 'County', 'Region', 'Land'],
      regions: [
        { value: 'AFR', text: 'Africa' },
        { value: 'CAR', text: 'Caribbean sea countries' },
        { value: 'CEM', text: 'Central America' },
        { value: 'EUR', text: 'Europe' },
        { value: 'JAK', text: 'Japan and Korea' },
        { value: 'MDE', text: 'Middle East' },
        { value: 'NOA', text: 'North America' },
        { value: 'SCH', text: 'Schengen agreement countries' },
        { value: 'SOA', text: 'South America' },
        { value: 'SAS', text: 'South Asia' },
        { value: 'SEA', text: 'South-Eastern Asia' },
        { value: 'SWP', text: 'South-West Pacific' },
        { value: 'TC1', text: 'IATA American Traffic Conference (includes NOA, CEM, SOA and CAR)' },
        { value: 'TC3', text: 'IATA Asian Traffic Conference (includes JAK, SAS, SEA, SWP)' },
        { value: 'TC2', text: 'IATA European and African Traffic Conference (includes AFR, EUR, MDE)' },
      ],
    }
  },
  props: [
    // inputs
    'showIataCode',
    'iataCodeLength',
    'showRegionCode',
    // autocompletes
    'showCountry',
    'initialCountry',
    'showCity',
    'initialCity',
    'showSubdivision',
    'initialSubdivision',
    // checkboxes
    'showSubdivisions', // rename
    'showDomestic',
    'showEU',
  ],
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
