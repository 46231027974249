<template>
  <div>
    <b-col md="12" style="height: 500px; overflow-y: scroll; overflow-x: hidden;">
        <object :data="document" type="application/pdf" width="100%" height="500px" v-if="document">
          <pdf-vue :document="document"/>
        </object>
    </b-col>
    <div class="modal-footer w-100">
      <div class="float-left" v-if="!hideOrientation">
        <b-form-select
          size="sm"
          v-model="selectedOrientation"
          @input="changeOrientation"
          :options="orientations"
        />
      </div>
      <b-btn-group size="sm" class="float-right">
        <b-btn
          variant="outline-dark"
          @click="cancel"
          v-translate
        >
        cancel
        </b-btn>

        <b-btn
          variant="outline-dark"
          @click="download"
          :disabled="!buttonsActive.download"
          v-translate
        >
        download
        </b-btn>
        <b-btn
          variant="outline-dark"
          @click="print"
          :disabled="!buttonsActive.print"
          v-translate
        >
        print
        </b-btn>
      </b-btn-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import pdfMake from 'pdfmake/build/pdfmake.min'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import PdfVue from '../others/PdfVue.vue'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'pdf-doc-view',
  components: {
    'pdf-vue': PdfVue,
  },
  props: ['documentTemplate', 'orientation', 'fileName', 'hideOrientation'],
  data() {
    let docName = this.$pgettext('file name only ascii', 'document.pdf')
    if (this.fileName) {
      if (typeof this.fileName === 'function') {
        docName = this.fileName()
      } else {
        docName = this.fileName
      }
    }

    return {
      document: null,
      documentName: docName,
      buttonsActive: {
        download: true,
        print: true,
      },
      orientations: [
        { text: this.$gettext('portrait'), value: 'portrait' },
        { text: this.$gettext('landscape'), value: 'landscape' },
      ],
      selectedOrientation: this.orientation ? this.orientation : 'portrait',
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    download() {
      this.buttonsActive.download = false
      pdfMake.createPdf(cloneDeep(this.documentTemplate)).download(this.documentName)
    },
    print() {
      this.buttonsActive.print = false
      pdfMake.createPdf(cloneDeep(this.documentTemplate)).print()
    },
    changeOrientation() {
      // eslint-disable-next-line vue/no-mutating-props
      this.documentTemplate.pageOrientation = this.selectedOrientation
      this.generateDocument()
    },
    generateDocument() {
      this.document = null

      pdfMake.createPdf(cloneDeep(this.documentTemplate))
        .getDataUrl((outDoc) => {
          this.document = outDoc
        })
    },
  },
  created() {
    this.generateDocument()
  },
}
</script>
