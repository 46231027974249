<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.offices"
      :fields="fields"
      :show-empty="innerValue.offices && !innerValue.offices.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(address)="{ item }">
        {{ [item.address1, item.address2].join(' ') }}
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import { v4 as uuidv4 } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'
import Form from './OfficeEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        { key: 'name', label: this.$gettext('name') },
        { key: 'address', label: this.$gettext('address') },
        { key: 'city_name', label: this.$gettext('city name') },
        { key: 'tel', label: this.$gettext('tel') },
        { key: 'email', label: this.$gettext('email') },
        { key: 'buttons', label: '' },
      ],
      obj: {},
      objIndex: null,
    }
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (index !== undefined) this.obj = cloneDeep(this.innerValue.offices[index])
      else {
        this.obj = {
          id: uuidv4(),
        }
      }
      this.$refs.modal.show()
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.offices.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.offices.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.offices.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
