import { $gettext } from '@/shared/services/translate-dummy'
import Certificates from './certificates/View.vue'
import Atol from './atol/View.vue'
import Insurance from './protection_cover/View.vue'

export default [
  {
    path: 'certificates',
    name: 'certificates',
    component: Certificates,
    meta: { label: $gettext('certificates'), registerQueryParams: true },
  },
  {
    path: 'atol',
    name: 'admin atol report',
    component: Atol,
    meta: { label: $gettext('atol report'), registerQueryParams: true },
  },
  {
    path: 'protection-cover',
    name: 'admin protection cover',
    component: Insurance,
    meta: { label: $gettext('protection cover'), registerQueryParams: true },
  },
]
