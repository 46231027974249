<template>
  <b-row class="mt-4">
    <template v-if="isNew">
      <b-col sm="6" md="4" lg="3" xl="2">
        <custom-input
          v-model="innerValue.company.code"
          :name="$gettext('code')"
          rules="required"
        />
      </b-col>
    </template>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.users_limit"
        :name="$gettext('users quota')"
        rules="required"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.name"
        :name="$gettext('name')"
        rules="required"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.company_no"
        :name="$gettext('company no')"
        rules="required"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.tel"
        :name="$gettext('tel')"
        rules="required"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.email"
        :name="$gettext('email')"
        rules="required|email"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.default_bcc"
        :name="$gettext('default bcc')"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.agencyCompany.shares_value"
        :name="$gettext('share value')"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.agencyCompany.insurer"
        :name="$gettext('insurer')"
        component="select"
        :options="insurerOptions"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.agencyCompany.country_currency_code"
        :name="$gettext('country currency')"
        component="select"
        apiKey="agency|commonCurrency"
        labelKey="code"
        rules="required"
        :reduce="obj => obj.code"
      />
    </b-col>
    <b-col sm="6" md="4" lg="3" xl="2">
      <custom-input
        v-model="innerValue.company.market"
        :name="$gettext('market')"
        component="select"
        :options="marketOptions"
        labelKey="text"
        rules="required"
        :reduce="obj => obj.value"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        component="textarea"
        v-model="innerValue.company.address"
        :name="$gettext('address')"
        rules="required"
      />
    </b-col>
    <b-col cols="12">
      <hr/>
    </b-col>
    <b-col sm="6">
      <custom-input
        v-model="innerValue.agencyCompany.is_vat_registered"
        :name="$gettext('is vat registered')"
        component="checkbox"
        noLabel
      />
      <custom-input
        v-model="innerValue.company.active"
        :name="$gettext('active')"
        component="checkbox"
        noLabel
      />
      <custom-input
        v-model="innerValue.company.subscription_unpaid"
        :name="$gettext('unpaid subscription')"
        component="checkbox"
        noLabel
      />
      <custom-input
        v-model="innerValue.company.readonly"
        :name="$gettext('read only')"
        component="checkbox"
        noLabel
      />
    </b-col>
    <b-col sm="6">
      <custom-input
        v-model="innerValue.company.vat_no"
        :name="$gettext('vat no')"
        :rules="innerValue.is_vat_registered ? 'required' : ''"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['isNew'],
  data() {
    return {
      insurerOptions: [
        { text: this.$gettext('please select'), value: null },
        { text: 'CBL', value: 'cbl' },
        { text: 'Travel & General', value: 'tag' },
      ],
      marketOptions: [
        { text: 'RO', value: 'ro' },
        { text: 'EN', value: 'en' },
      ],
    }
  },
}
</script>
