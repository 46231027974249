<template>
  <b-row>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.message"
        :name="$gettext('message')"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.valid_from"
        :name="$gettext('valid from')"
        component="date"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.valid_to"
        :name="$gettext('valid to')"
        component="date"
      />
    </b-col>
    <b-col cols="6" md="4">
      <custom-input
        v-model="innerValue.active"
        :name="$gettext('active')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
    <b-col cols="6" md="4">
      <custom-input
        v-model="innerValue.is_public"
        :name="$gettext('is public')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  computed: {
    yesNoOptions() {
      return [
        { text: this.$gettext('all'), value: null },
        { text: this.$gettext('yes'), value: 'yes' },
        { value: 'no', text: this.$pgettext('yes/no', 'no') },
      ]
    },
  },
}
</script>
