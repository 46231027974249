<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <logo-picker
        :mimeType="innerValue.logo_mime"
        @setMimeType="innerValue.logo_mime = $event"
        v-model="innerValue.logo"
        :previewUrl="`${companyUrl}/bin/company_logo/${innerValue.code}`" />
      <b-col cols="12">
        <hr/>
      </b-col>
      <logo-picker
        :mimeType="innerValue.footer_image_mime"
        @setMimeType="innerValue.footer_image_mime = $event"
        v-model="innerValue.footer_image"
        :previewUrl="`${companyUrl}/bin/company_footer/${innerValue.code}`"
        :title="$gettext('footer image')" />
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'
import LogoPicker from '@/shared/components/others/LogoPicker.vue'

export default {
  mixins: [vModelMixin],
  components: {
    'logo-picker': LogoPicker,
  },
  data() {
    return {
      companyUrl: null,
    }
  },
  async created() {
    this.companyUrl = (await this.$root.settings).apis.company
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
