<template>
  <b-input
    :id="id"
    :name="name"
    :state="state"
    :min="min"
    :max="max"
    type="number"
    :placeholder="placeholder || name"
    size="sm"
    v-model.number="innerValue"
    :autocomplete="autocomplete"
    :disabled="disabled"
    @input="$emit('input')"
  />
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  inheritAttrs: false,
  name: 'NumberInput',
  mixins: [vModelMixin],
  props: {
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    state: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
