<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col cols="12" sm="6" lg="4">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('plan name')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <custom-input
          component="number"
          v-model="innerValue.users_limit"
          :name="$gettext('number of users')"
          rules="required"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.public"
          :name="$gettext('is public')"
          noLabel
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.active"
          :name="$gettext('is active')"
          noLabel
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col cols="12" v-for="(gr, grIndex) in options" :key="'group' + grIndex">
        <h5 class="mb-4">{{ gr.group }}</h5>
        <b-row>
          <template v-for="(st, stIndex) in gr.options">
            <b-col cols="12" sm="6" md="4" lg="3" :key="'setting' + stIndex">
              <b-form-checkbox
                style="margin-bottom: 1.5rem;"
                :id="'checkbox' + grIndex + '/' + stIndex"
                :value="st.string"
                v-model="innerValue[gr.group + '_access']"
                size="sm"
              >
                {{ $gettext(st.label) }}
              </b-form-checkbox>
            </b-col>
          </template>
        </b-row>
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col cols="12">
        <b-btn
          variant="primary"
          @click="addPricingModel"
          size="sm">
          <icon icon="plus" /><translate>add pricing model</translate>
        </b-btn>
      </b-col>
      <b-col cols="12">
        <b-row class="mt-4" v-for="(pricingModel, index) in innerValue.pricing_models" :key="index">
          <b-col cols="12">
            <h5 style="display: inline-block;">
              <span v-translate>pricing model</span> {{ index + 1 }}
            </h5>
            <b-btn
              v-show="innerValue.pricing_models.length > 1"
              @click="removePricingModel(index)"
              variant="danger"
              size="sm"
              class="ml-2">
              <b-icon icon="dash"/>
            </b-btn>
          </b-col>
          <b-col cols="12" sm="6" lg="4">
            <custom-input
              component="number"
              v-model="pricingModel.fee"
              :name="$gettext('monthly fee')"
              rules="required"
            />
          </b-col>
          <b-col cols="12" sm="6" lg="4">
            <custom-input
              v-model="pricingModel.currency_code"
              :name="$gettext('currency')"
              component="select"
              apiKey="agency|commonCurrency"
              labelKey="code"
              rules="required"
              :reduce="obj => obj.code"
            />
          </b-col>
          <b-col cols="12">
            <hr/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      options: [
        {
          group: 'agency',
          options: [
            { string: 'test_1', label: this.$gettext('test 1') },
            { string: 'test_2', label: this.$gettext('test 2') },
            { string: 'test_3', label: this.$gettext('test 3') },
          ],
        },
        {
          group: 'etrip',
          options: [
            { string: 'test_1', label: this.$gettext('test 1') },
            { string: 'test_2', label: this.$gettext('test 2') },
            { string: 'test_3', label: this.$gettext('test 3') },
          ],
        },
      ],
    }
  },
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
    addPricingModel() {
      this.innerValue.pricing_models.push({
        fee: '',
        currency_code: '',
      })
    },
    removePricingModel(index) {
      this.innerValue.pricing_models.splice(index, 1)
    },
  },
}
</script>
