<template>
  <b-row class="mt-4">
    <b-col sm="6" md="4">
      <custom-input
        v-model="innerValue.balance_due_days"
        :name="$gettext('balance due days')"
        component="number"
      />
    </b-col>
    <b-col sm="6" md="4">
      <custom-input
        v-model="innerValue.allow_web_management"
        :name="$gettext('allowed web management')"
        component="checkbox"
      />
    </b-col>
    <b-col cols="12">
      <hr/>
    </b-col>
    <b-col cols="12" lg="6">
      <h5 class="mb-4" v-translate>allowed booking types</h5>
      <template v-for="(item, index) in bookingTypeOptions">
        <b-row :key="index">
          <b-col cols="6">
            <custom-input
              v-model="item.model"
              :name="item.label"
              component="checkbox"
              noLabel
              @change="bookingTypeToggle(item.id, item.insurance_percentage)"
            />
          </b-col>
          <b-col cols="6">
            <custom-input
              v-model="item.insurance_percentage"
              :name="$gettext('insurance percentage')"
              component="number"
              noLabel
              @input="insurangePercentageUpdate(item.id, $event)"
            />
          </b-col>
        </b-row>
      </template>
    </b-col>
    <b-col cols="12" lg="6">
      <h5 class="mb-4" v-translate>allowed certificate types</h5>
      <template v-for="(item, index) in certificateTypes">
        <div :key="index">
          <custom-input
            :value="innerValue.allowed_certificates.indexOf(item.code) >= 0"
            :name="item.label"
            component="checkbox"
            noLabel
            @change="certificateTypeToggle(item.code)"
          />
        </div>
      </template>
    </b-col>
    <b-col cols="12">
      <hr/>
    </b-col>
    <b-col cols="12" lg="6" v-for="(gr, grIndex) in options" :key="'group' + grIndex">
      <h5 class="mb-4">{{ $gettext(gr.group) }}</h5>
      <template v-for="(st, stIndex) in gr.options">
        <div :key="'setting' + stIndex">
          <b-form-checkbox
            style="margin-bottom: 1.5rem;"
            :id="'checkbox' + grIndex + '/' + stIndex"
            :value="st.string"
            v-model="innerValue.options"
            size="sm"
          >
            {{ $gettext(st.label) }}
          </b-form-checkbox>
        </div>
      </template>
    </b-col>
    <b-col cols="12" lg="6">
      <h5 class="mb-4" v-translate>smtp</h5>
      <custom-input
        v-model="innerValue.smtp_enabled"
        :name="$gettext('enable smtp')"
        component="checkbox"
        no-label
        style="margin-bottom: 1.5rem;"
      />
      <custom-input
        v-model="innerValue.smtp_allow_user_sender"
        :name="$gettext('allow user sender')"
        component="checkbox"
        no-label
      />
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable no-param-reassign */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['isNew'],
  data() {
    return {
      bookingTypeOptions: [],
      certificateTypes: [
        { code: 'flight_only', label: this.$gettext('flight only') },
        { code: 'flight_plus', label: this.$gettext('flight plus') },
        { code: 'package', label: this.$gettext('package') },
      ],
      options: [
        {
          group: this.$gettext('bookings'),
          options: [
            { string: 'item_supplier', label: this.$gettext('supplier mandatory on booking items') },
            { string: 'item_product_type', label: this.$gettext('product type mandatory on booking items') },
            { string: 'booking_add_travel_date', label: this.$gettext('show travel date when adding booking') },
            { string: 'balance_due_date', label: this.$gettext('balance due date mandatory') },
            { string: 'show_booking_status', label: this.$gettext('allow booking status management') },
            { string: 'show_sale_origin', label: this.$gettext('allow sale origin management') },
            { string: 'show_supplier', label: this.$gettext('show supplier on voucher') },
            { string: 'separate_pages', label: this.$gettext('display one voucher per page') },
            { string: 'skip_documentation', label: this.$gettext('skip on documentation') },
            { string: 'skip_rescheck', label: this.$gettext('skip on rescheck') },
            { string: 'show_notes_on_documentation', label: this.$gettext('show notes on documentation') },
            { string: 'show_pax_names_on_documents', label: this.$gettext('show pax names on documents') },
            { string: 'use_time', label: this.$gettext('display times on voucher (transfer / other items)') },
            { string: 'show_itinerary_on_documentation', label: this.$gettext('show itinerary on documentation') },
            { string: 'show_times_on_voucher', label: this.$gettext('show check-in/out times on voucher') },
            { string: 'booking_destination', label: this.$gettext('destination mandatory on booking') },
            { string: 'pricing_grid_gross', label: this.$gettext('show total gross in pricing grid') },
            { string: 'pricing_grid_nett', label: this.$gettext('show total nett in pricing grid') },
            { string: 'pricing_grid_comm', label: this.$gettext('show commission in pricing grid') },
            { string: 'pricing_grid_vat', label: this.$gettext('show vat in pricing grid') },
            { string: 'pricing_grid_tax', label: this.$gettext('show tax in pricing grid') },
          ],
        },
        {
          group: this.$gettext('clients & suppliers'),
          options: [
            { string: 'partner_id_no', label: this.$gettext('partner id no mandatory') },
            { string: 'partner_personal_id_no', label: this.$gettext('partner personal id no mandatory') },
            { string: 'personal_id_no_skip_validation', label: this.$gettext('skip validation for id no') },
            { string: 'partner_address', label: this.$gettext('partner address mandatory') },
            { string: 'partner_email', label: this.$gettext('partner email mandatory') },
            { string: 'postal_code', label: this.$gettext('partner postcode mandatory') },
            { string: 'partner_tel', label: this.$gettext('partner phone no mandatory') },
            { string: 'partner_vat_no', label: this.$gettext('company vat no mandatory') },
            { string: 'partner_company_no', label: this.$gettext('registered company no mandatory') },
            { string: 'show_country_on_client_address', label: this.$gettext('display client\'s country of residence on documents') },
          ],
        },
        {
          group: this.$gettext('accounts'),
          options: [
            { string: 'show_decont_booking_currency_amounts', label: this.$gettext('show booking currency in combined invoicings report') },
            { string: 'invoices_rescheck_travel_agents', label: this.$gettext('show invoices on rescheck for travel agents') },
            { string: 'e_factura_enabled', label: this.$gettext('enable eFactura') },
            { string: 'saga_enabled', label: this.$gettext('enable SAGA') },
          ],
        },
        {
          group: this.$gettext('services'),
          options: [
            { string: 'allow_stuba', label: this.$gettext('allow stuba') },
          ],
        },
      ],
    }
  },
  methods: {
    certificateTypeToggle(code) {
      const list = this.innerValue.allowed_certificates
      const index = list.indexOf(code)
      if (index < 0) list.push(code)
      else list.splice(index, 1)
    },
    bookingTypeToggle(id, percentage) {
      const index = this.innerValue.allowed_booking_types
        .findIndex((el) => el.booking_type_id === id)
      if (index >= 0) this.innerValue.allowed_booking_types.splice(index, 1)
      else {
        this.innerValue.allowed_booking_types.push({
          booking_type_id: id,
          insurance_percentage: percentage || 0,
        })
      }
    },
    insurangePercentageUpdate(id, percentage) {
      const index = this.innerValue.allowed_booking_types
        .findIndex((el) => el.booking_type_id === id)
      if (index >= 0) {
        this.innerValue.allowed_booking_types[index].insurance_percentage = percentage || 0
      }
    },
    certificateTypeAllowed(code) {
      return this.innerValue.allowed_certificates.indexOf(code) >= 0
    },
  },
  created() {
    this.$crud.agency.bookingType.get().then((bookingTypes) => {
      if (this.isNew) this.bookingTypeOptions = bookingTypes.items
      else {
        this.bookingTypeOptions = bookingTypes.items.map((bookingType) => {
          const foundBookingType = this.innerValue.allowed_booking_types
            .find((el) => el.booking_type_id === bookingType.id)
          if (foundBookingType) {
            bookingType.model = true
            bookingType.insurance_percentage = foundBookingType.insurance_percentage
          } else bookingType.model = false

          return bookingType
        })
      }
    })
  },
}
</script>
