<template>
  <div class="app flex-row align-items-center bg-gradient-primary">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4 text-white">404</h1>
            <h3
              class="text-white"
              translate-comment="404 http error">
              Houston, <translate>we have a problem</translate>!
            </h3>
            <p class="text-white" v-translate>the page you are looking for was not found.</p>
          </div>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input
              :placeholder="$gettext('what are you looking for?')"
              v-model="searchTerm"
              @keyup.enter="search"/>
            <b-input-group-append>
              <b-btn @click="search" variant="success" v-translate>search</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: '',
    }
  },
  methods: {
    search() {
      if (this.searchTerm && this.searchTerm !== this.$router.currentRoute.path.split('/')[1]) {
        this.$router.push(`/${this.searchTerm}`)
      }
    },
  },
}
</script>
