// Validation mixins

export default {
  methods: {
    $vState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    $vErrors({ errors }) {
      return errors[0]
    },
  },
}
