<template>
  <b-input
    :id="id"
    :name="name"
    :state="state"
    :type="type"
    :placeholder="placeholder || name"
    size="sm"
    v-model="innerValue"
    :autocomplete="autocomplete"
    :disabled="disabled"
  />
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'TextInput',
  inheritAttrs: false,
  mixins: [vModelMixin],
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'text',
          'password',
          'number',
          'email',
        ].includes(value)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
  .form-control {
    padding-right: 0;
    background-image: none;
  }
</style>
