<template>
  <b-table
    class="mb-0"
    striped
    small
    hover
    responsive
    no-sort-reset
    :items="results"
    :fields="fields"
    no-local-sorting
    :show-empty="!results.length"
    :empty-text="$gettext('no results found')"
    @sort-changed="sortChanged"
    sticky-header="80vh"
  >
    <template v-slot:cell(index)="{ index }">
      {{ pageIndex + index + 1 }}
    </template>
    <template v-slot:cell(buttons)="{ item }">
      <edit-delete-buttons
        @edit="edit(item.id)"
        @remove="remove(item.id)"
      />
    </template>
  </b-table>
</template>

<script>
export default {
  props: ['results', 'fields', 'pageIndex'],
  methods: {
    edit(id) {
      this.$emit('edit', id)
    },
    remove(id) {
      this.$emit('remove', id)
    },
    sortChanged(ctx) {
      this.$emit('sort-changed', ctx)
    },
  },
}
</script>
