<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      @results-updated="localResults = $event"
      :filters="filters"
      ref="crudView"
      :hidePagination="true"
      :hideSorting="true"
      :hideAdd="true"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
          :bookingTypeOptions="bookingTypeOptions"
        />
      </template>

      <template v-slot:filter-buttons-left="{ results }">
        <b-dropdown
          :disabled="!results.length"
          size="sm"
          right
          variant="danger"
          :text="$gettext('download')"
        >
          <b-dropdown-item name="pdf" @click="openPdfReport()">
            PDF
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- RESULTS -->
      <template v-slot:results>
        <results
          :filteredResults="filteredResults"
          :filters="filters"
          :bookingTypeOptions="bookingTypeOptions"
        />
      </template>
    </crud-view>

    <!-- PDF MODAL -->
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      :ok-disabled="$root.pendingRequestsObj.active"
      button-size="sm"
      ref="pdfView"
      :lazy="true"
      :title="$gettext('view')"
    >
      <pdf-doc-view
        @cancel="hidePdfModal"
        :fileName="fileName"
        :documentTemplate="documentTemplate"
      />
    </b-modal>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'
import PdfDocView from '@/shared/components/modals/PdfDocView.vue'
import moment from 'moment'
import renderReport from '@/shared/documentation/report'

import Filters from './Filters.vue'
import Results from './Results.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
    PdfDocView,
  },
  data() {
    return {
      crudEndpoint: 'agency|atolRevenueReport',
      filters: this.getDefaultFilters(),
      bookingTypeOptions: [],
      documentTemplate: null,
      fileName: null,
      localResults: [],
    }
  },
  async created() {
    const { items } = await this.$crud.agency.bookingType.get()
    items.forEach((bookingType) => {
      this.bookingTypeOptions.push({ value: bookingType.id, text: bookingType.label })
    })
  },
  computed: {
    filteredResults() {
      return this.localResults.map((company) => {
        // eslint-disable-next-line no-param-reassign
        company.travelling_month_processed = {}
        Object.values(company.travelling_month).forEach((el) => {
          if (company.travelling_month_processed[el.month] === undefined) {
            // eslint-disable-next-line no-param-reassign
            company.travelling_month_processed[el.month] = []
          }
          company.travelling_month_processed[el.month].push(el)
        })
        return company
      })
    },
  },
  methods: {
    hidePdfModal() {
      this.$refs.pdfView.hide()
    },
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {
      }
    },
    openPdfReport() {
      const tables = []
      const categories = [
        { name: 'created_month', label: `created ${moment(this.filters.date).format('DD/MM/YYYY')}` },
        { name: 'travelling_future', label: 'travelling beyond 12 months' },
      ]
      this.filteredResults.forEach((company) => {
        categories.forEach((category) => {
          const table = { table: [], headerRows: [] }
          const headers = [
            'booking type',
            'total bookings',
            'total pax',
            'total revenue',
          ]
          table.headerRows.push(headers)

          const keys = Object.keys(company[category.name])
          if (keys.length) {
            company[category.name].forEach((booking) => {
              const tRow = [
                this.getType(booking.booking_type_id),
                booking.bookings,
                booking.pax_count,
                booking.total_price.toFixed(2),
              ]
              table.table.push(tRow)
            })
          }

          const tRow = [
            category.label,
            this.getTotals(company[category.name], 'bookings'),
            this.getTotals(company[category.name], 'pax_count'),
            this.getTotals(company[category.name], 'total_price').toFixed(2),
          ]
          table.table.push(tRow)
          if (table.table.length > 0) tables.push(table)
        })

        Object.keys(company.travelling_month_processed).forEach((key) => {
          const month = company.travelling_month_processed[key]
          const table = { table: [], headerRows: [] }
          const headers = [
            'booking type',
            'total bookings',
            'total pax',
            'total revenue',
          ]
          table.headerRows.push(headers)

          month.forEach((booking) => {
            const tRow = [
              this.getType(booking.booking_type_id),
              booking.bookings,
              booking.pax_count,
              booking.total_price.toFixed(2),
            ]
            table.table.push(tRow)
          })

          const tRow = [
            `travelling ${key}`,
            this.getTotals(month, 'bookings'),
            this.getTotals(month, 'pax_count'),
            this.getTotals(month, 'total_price').toFixed(2),
          ]
          table.table.push(tRow)
          tables.push(table)
        })
      })

      /* title */
      const text = `${this.$gettext('report results')}`
      const title = {
        text: text.toUpperCase(),
        style: 'title',
        alignment: 'center',
      }

      this.documentTemplate = renderReport({
        tables, extra: { top: title },
      })
      this.fileName = `${this.$pgettext('file name only ascii', 'report')}.pdf`

      this.$refs.pdfView.show()
    },
    getType(id) {
      const found = this.bookingTypeOptions.find((el) => el.value === id)
      if (found) return found.text
      return 'n/a'
    },
    getTotals(obj, type) {
      let total = 0
      if (Array.isArray(obj)) {
        obj.forEach((el) => {
          const no = parseFloat(el[type])
          if (!Number.isNaN(no)) total += no
        })
      } else {
        const keys = Object.keys(obj)
        if (keys.length) {
          const no = parseFloat(obj[type])
          if (!Number.isNaN(no)) total += no
        }
      }
      return total
    },
  },
}
</script>
