<template>
  <div class="mb-5" v-if="localResults.length">
    <div v-for="(company, companyIndex) in localResults" :key="companyIndex">
    <h3 class="mb-0">
      <translate>results for</translate> {{ company.company_name }}
    </h3>
      <b-card variant="primary" no-body>
        <table class="table table-responsive">
          <thead class="text-uppercase">
            <th v-translate>ref</th>
            <th v-translate>booking date</th>
            <th v-translate>travel date</th>
            <th v-translate>return date</th>
            <th v-translate>total receipted</th>
            <th v-translate>lead name</th>
            <th v-translate>booking type</th>
            <th v-translate>passengers</th>
            <th v-translate>price</th>
            <th v-translate>premium</th>
            <th v-translate>ipt / vat</th>
            <th v-translate>total</th>
          </thead>
          <tbody>
            <tr
              v-for="(booking, bookingIndex) in company.bookings"
              :key="bookingIndex"
            >
              <td>
                  {{ booking.ref }}
              </td>
              <td>{{ booking.date | date("DD-MM-YYYY") }}</td>
              <td>{{ booking.travel_date | date("DD-MM-YYYY") }}</td>
              <td>{{ booking.return_date | date("DD-MM-YYYY") }}</td>
              <td>{{ booking.total_receipted }}</td>
              <td>{{ booking.lead }}</td>
              <td>{{ booking.booking_type_label }}</td>
              <td>{{ booking.pax }}</td>
              <td>{{ booking.currency }} {{ booking.price | decimal }}</td>
              <td>{{ booking.currency }} {{ booking.premium | decimal }}</td>
              <td>
                {{ booking.currency }} {{ booking.premium_vat | decimal }}
              </td>
              <td>
                <b>{{ (booking.premium + booking.premium_vat) | decimal }}</b>
              </td>
            </tr>
            <tr class="highlighted">
              <td class="no" colspan="7">Totals</td>
              <td>
                <b> {{ company.totals.pax }} </b>
              </td>
              <td>
                <b> {{ company.totals.price | decimal }} </b>
              </td>
              <td>
                <b> {{ company.totals.premium | decimal }} </b>
              </td>
              <td>
                <b> {{ company.totals.vat | decimal }} </b>
              </td>
              <td>
                <b> {{ company.totals.total | decimal }} </b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </div>
  </div>
  <div v-else class="text-center p-2">
    <h5 class="mb-0">
      {{ $gettext('no results found') }}
    </h5>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

export default {
  props: ['results'],
  computed: {
    localResults() {
      this.results.forEach((company) => {
        company.totals = {
          pax: 0,
          price: 0,
          premium: 0,
          vat: 0,
          total: 0,
        }

        company.bookings.forEach((booking) => {
          company.totals.pax += booking.pax
          company.totals.price += booking.price
          company.totals.premium += booking.premium
          company.totals.vat += booking.premium_vat
          company.totals.total += booking.premium + booking.premium_vat
        })
      })
      return this.results
    },
  },
}
</script>
