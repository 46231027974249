// Translate plugin

import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'
import translations from '@/translations/translations.json'

const config = {
  availableLanguages: {
    en_GB: 'English',
    ro_RO: 'Romana',
  },
  defaultLanguage: (typeof localStorage !== 'undefined') && localStorage.language ? localStorage.language : 'en_GB',
  translations,
  // TODO: remove this option, only used to suppress warnings until translations are ready
  silent: true,
}

Vue.use(GetTextPlugin, config)
