<template>
  <b-row class="text-center">
    <template v-for="(item, index) in nav.slice(1)" >
      <b-col
        cols="6"
        lg="3"
        md="4"
        :key="index"
        v-if="!item.children">
        <b-button
          @click="$router.push({ name: item.routeName })"
          class="bg-success m-3"
          style="width: 200px; height: 200px;">
          <div style="color: white;">
            <icon style="font-size: 100px;" :icon="item.icon"/>
            <h2 class="text-white mt-2">{{ item.name }}</h2>
          </div>
        </b-button>
      </b-col>
      <template v-for="(child, childIndex) in item.children">
        <b-col
          cols="6"
          lg="3"
          md="4"
          :key="'i'+index+'c'+childIndex">
          <b-button
            @click="$router.push({ name: child.routeName })"
            class="bg-success m-3"
            style="width: 200px; height: 200px;">
            <div style="color: white;">
              <icon style="font-size: 100px;" :icon="child.icon"/>
              <h2 class="text-white mt-2">{{ child.name }}</h2>
            </div>
          </b-button>
        </b-col>
      </template>
    </template>
  </b-row>
</template>

<script>
import nav from '@/app/nav'

export default {
  computed: {
    nav() {
      return nav
    },
  },
}
</script>
