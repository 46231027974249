// Axios interceptor init
import { $gettext } from '@/shared/services/translate-dummy'

import { loadProgressBar } from 'axios-progress-bar'
import AuthData from './auth-data'
import { HTTP_INSTANCES, pendingRequests } from './http'

const setups = []

Object.keys(HTTP_INSTANCES).forEach((key) => {
  const HTTP_INSTANCE = HTTP_INSTANCES[key]
  const setup = async (Vue) => {
    const inst = await HTTP_INSTANCE()
    // Sending requests
    inst.interceptors.request.use((config) => {
      if (config.metadata && config.metadata.blocker) {
        pendingRequests.count += 1
        pendingRequests.active = true
      }

      return config
    })

    // Recveiving requests
    inst.interceptors.response.use(
      (response) => {
        if (response.config.metadata && response.config.metadata.blocker) {
          pendingRequests.count -= 1
          if (!pendingRequests.count) pendingRequests.active = false
        }

        return response
      },
      (error) => {
        let response
        if (error.response) {
          response = error.response

          if (response.config.metadata && response.config.metadata.blocker) {
            pendingRequests.count -= 1
            if (!pendingRequests.count) pendingRequests.active = false
          }

          // Errors
          if (response.status === 401) {
            Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('Unauthorized access') })
            AuthData.resetData()
            if (Vue.$route.name !== 'Login') Vue.$router.push({ name: 'Login' }) // Condition to avoid multiple router pushes in case of many get requests with 401 server responses at once
          } else if (response.status === 500) {
            Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('Internal server error') })
          } else if (response.status === 409) {
            Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('The resource you\'re editing has already been edited by another user, please refresh the page.') })
          } else if (response.status === 413) {
            Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('The data you are sending to the server is too large. Please reduce the payload.') })
          } else if (response.status === 403) {
            // there's a possibility that the user is read only
            if (AuthData.companyData && AuthData.companyDAta.read_only) {
              Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('Your account is set to read-only') })
            } else {
              Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('Forbidden access') })
              AuthData.resetData()
              if (Vue.$route.name !== 'Login') Vue.$router.push({ name: 'Login' }) // Condition to avoid multiple router pushes in case of many get requests with 401 server responses at once
            }
          }
        } else {
          response = error

          pendingRequests.count = 0
          pendingRequests.active = false

          if (error.constructor.name !== 'Cancel') {
            Vue.$toast({ variant: 'danger', title: $gettext('Error'), message: $gettext('Communication with the server failed, please report the problem and try again later.') })
          }
        }

        return Promise.reject(response)
      },
    )

    loadProgressBar({}, inst)
  }
  setups.push(setup)
})

export default setups
