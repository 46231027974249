<template>
  <div>
    <b-card
      v-for="(cert, certIndex) in results"
      :key="certIndex"
      class="mb-5"
    >
      <template v-slot:header>
        <h6 class="mb-0 text-uppercase">
          <b class="float-left">
            {{ certIndex + 1 }}. <translate>certificate type</translate>
          </b>
          <b class="float-right" v-translate>no issued</b>
        </h6>
      </template>
      <template v-slot:footer>
        <h5 class="text-uppercase">
          <b class="float-left"><translate>total for</translate> {{ cert.name }}:
          </b>
          <b class="float-right">{{ cert.total }}</b>
        </h5>
      </template>
      <table style="width: 100%">
        <tbody>
          <tr
            v-for="(certs, certsIndex) in cert.certificates"
            :key="certsIndex"
          >
            <td class="float-left">- {{ certs.type }}</td>
            <td class="float-right">{{ certs.count }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <div v-if="!results.length" class="text-center p-2">
      <h5 class="mb-0">
        {{ $gettext('no results found') }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  props: ['results'],
}
</script>
