<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.username"
          :name="$gettext('username')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.email"
          :name="$gettext('email')"
          type="email"
          rules="required|email"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.password"
          :name="$gettext('password')"
          type="password"
          :rules="innerValue.id ? '' : 'required'"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.active"
          :name="$gettext('active')"
          noLabel
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          noLabel
          component="checkbox"
          v-model="innerValue.is_super"
          :name="$gettext('is super')"
        />
      </b-col>
      <b-col cols="12">
        <h4>Allowed IP list</h4>
        <b-btn size="sm" @click="addIp" variant="primary">
          <fa-icon icon="plus" />
        </b-btn>
        <hr />
        <!-- IPS -->
        <b-row
          v-for="(item, index) in innerValue.ips"
          :key="index"
          class="d-flex align-items-center"
        >
          <b-col cols="1" class="text-center">
            {{ index + 1 }}
          </b-col>
          <b-col cols="9">
            <custom-input
              :name="$gettext('ip')"
              rules="required|ip"
              v-model="innerValue.ips[index]"
            />
          </b-col>
          <b-col cols="2">
            <b-btn variant="danger" size="sm" @click="deleteIp(index)" class="float-right">
              <fa-icon icon="times" />
            </b-btn>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
    addIp() {
      this.innerValue.ips.push('')
    },
    deleteIp(index) {
      this.innerValue.ips.splice(index, 1)
    },
  },
}
</script>
