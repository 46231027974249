<template>
  <b-overlay
    :show="$root.pendingRequestsObj.active"
    spinner-type="grow"
    opacity="0.2"
    spinner-variant="primary">
    <b-card v-if="loaded">
      <b-tabs v-model="activeTab" content-class="p-3 border border-color">
        <b-row>
          <b-col sm="6">
            <custom-input
              component="select"
              :options="tabs"
              noLabel
              :reduce="obj => obj.value"
              v-model="activeTab"
              :clearable="false"
              class="select-tab"
              v-if="!isNew"
            />
          </b-col>
          <b-col sm="6">
            <custom-input
              component="select"
              :options="detailsTabs"
              noLabel
              :reduce="obj => obj.value"
              v-model="activeDetailsTab"
              :clearable="false"
              class="select-tab"
              v-show="activeTab===0"
              v-if="!isNew"
            />
          </b-col>
        </b-row>
        <b-tab>
          <template v-slot:title>
            <span :class="!detailsValid ? 'text-red' : ''" v-translate>details</span>
          </template>
          <ValidationObserver ref="detailsObserver" slim>
            <company-details
              ref="detailsTab"
              v-model="companyObj"
              :isNew="isNew"
              :activeTab="activeDetailsTab"
              @changeTab="(e) => activeDetailsTab = e"
              @revalidate="revalidate"
            />
          </ValidationObserver>
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('offices')">
          <offices v-model="companyObj.company"/>
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('brands')">
          <brands v-model="companyObj.company" />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('users')">
          <users v-model="companyObj.company" :paxTitles="companyObj.agencyCompany.pax_titles" />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('bank accounts')">
          <bank-accounts
            v-model="companyObj.company"
            :saleCurrencies="companyObj.agencyCompany.sale_currencies"
          />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('attached files')">
          <attached-files v-model="companyObj.company"/>
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('etrip users')">
          <etrip-users :isNew="isNew" v-model="companyObj.agencyCompany"/>
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('ledgers')">
          <ledgers
            :offices="companyObj.company.offices"
            v-model="companyObj.agencyCompany"
          />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('subscriptions')">
          <subscriptions
            v-model="companyObj.company"
            :paymentPlans="paymentPlans"
          />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('discounts')">
          <discounts
            v-model="companyObj.company"
          />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('payments')">
          <payments
            v-model="companyObj.company"
          />
        </b-tab>
        <b-tab v-if="!isNew" :title="$gettext('invoicing rules')">
          <invoicing-rules v-model="companyObj"/>
        </b-tab>
      </b-tabs>
      <template v-slot:footer>
        <modal-buttons @save="save" @cancel="cancel" class="float-right"/>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable array-callback-return, no-param-reassign, object-curly-newline, max-len */
import uniqueId from 'lodash/uniqueId'
import cloneDeep from 'lodash/cloneDeep'
import vModelMixin from '@/shared/mixins/v-model'
import Details from './tabs/Details.vue'
import BankAccounts from './tabs/BankAccounts.vue'
import ETripUsers from './tabs/ETripUsers.vue'
import Brands from './tabs/Brands.vue'
import Offices from './tabs/Offices.vue'
import Users from './tabs/Users.vue'
import AttachedFiles from './tabs/AttachedFiles.vue'
import Ledgers from './tabs/Ledgers.vue'
import Subscriptions from './tabs/Subscriptions.vue'
import Discounts from './tabs/Discounts.vue'
import Payments from './tabs/Payments.vue'
import InvoicingRules from './tabs/InvoicingRules.vue'

export default {
  mixins: [vModelMixin],
  components: {
    'company-details': Details,
    'bank-accounts': BankAccounts,
    'etrip-users': ETripUsers,
    brands: Brands,
    offices: Offices,
    users: Users,
    'attached-files': AttachedFiles,
    ledgers: Ledgers,
    subscriptions: Subscriptions,
    discounts: Discounts,
    payments: Payments,
    'invoicing-rules': InvoicingRules,
  },
  data() {
    return {
      crudEndpoint: 'company',
      isNew: false,
      company: null,
      agencyCompany: null,
      origCompany: null,
      origAgencyCompany: null,
      saveSuccessful: false,
      loaded: false,
      tabs: [
        { value: 0, text: this.$gettext('details') },
        { value: 1, text: this.$gettext('offices') },
        { value: 2, text: this.$gettext('brands') },
        { value: 3, text: this.$gettext('users') },
        { value: 4, text: this.$gettext('bank accounts') },
        { value: 5, text: this.$gettext('attached files') },
        { value: 6, text: this.$gettext('etrip users') },
        { value: 7, text: this.$gettext('ledgers') },
        { value: 8, text: this.$gettext('subscriptions') },
        { value: 9, text: this.$gettext('discounts') },
        { value: 10, text: this.$gettext('payments') },
        { value: 11, text: this.$gettext('invoicing rules') },
      ],
      detailsTabs: [
        { value: 0, text: this.$gettext('company info') },
        { value: 1, text: this.$gettext('currencies') },
        { value: 2, text: this.$gettext('integrations') },
        { value: 3, text: this.$gettext('settings & permissions') },
        { value: 4, text: this.$gettext('documents') },
        { value: 5, text: this.$gettext('notes') },
      ],
      activeTab: 0,
      activeDetailsTab: 0,
      paymentPlans: [],
      detailsValid: true,
    }
  },
  async created() {
    if (this.$route.params.companyCode) {
      await this.getCompany()
    } else {
      this.isNew = true
      this.company = {
        offices: [],
        brands: [],
        users: [],
        bank_accounts: [],
        subscriptions: [],
        invoice_rules: [],
        discounts: [],
        payments: [],
        users_limit: 4,
        active: true,
      }
      this.agencyCompany = this.getAgencyCompanyDefault()
    }
    const paymentPlanData = await this.$newCrud.company.paymentPlan.get({
      query: {
        populate: 'pricing_models',
      },
    })
    this.paymentPlans = paymentPlanData.items
    this.loaded = true
  },
  async beforeRouteLeave(to, from, next) {
    if ((JSON.stringify(this.company) !== JSON.stringify(this.origCompany)
      || JSON.stringify(this.agencyCompany) !== JSON.stringify(this.origAgencyCompany))
      && !this.isNew) {
      this.$confirmModal({
        title: this.$gettext('Unsaved changes'),
        message: this.$gettext('Do you wish to save changes before leaving?'),
        okVariant: 'success',
        cancelVariant: 'danger',
      }).then(async (accepted) => {
        if (accepted) {
          await this.save()
          if (this.saveSuccessful) {
            next()
          }
        } else {
          next()
        }
      }).catch(() => {
        next(false)
      })
    } else {
      next()
    }
  },
  computed: {
    companyObj: {
      get() {
        return { company: this.company, agencyCompany: this.agencyCompany }
      },
      set(newValue) {
        this.company = newValue.company
        this.agencyCompany = newValue.agencyCompany
      },
    },
  },
  methods: {
    getAgencyCompanyDefault() {
      return {
        is_vat_registered: true,
        readonly: false,
        sale_currencies: [],
        country_currency_code: this.setCountryCurrencyCode(),
        allowed_booking_types: [],
        payment_methods: [],
        allowed_certificates: [],
        options: [],
        etrip_users: [],
        paysafe_has_zip_code: true,
        paysafe_has_three_d_secure: false,
        paysafe_currencies: [],
        ledger_currencies: [],
      }
    },
    async getCompany() {
      const company = await this.$newCrud.company[this.crudEndpoint].get({
        id: this.$route.params.companyCode,
        query: {
          populate: [
            'offices',
            'bank_accounts',
            'discounts',
            'payments',
            'invoice_rules',
            {
              name: 'subscriptions',
              query: {
                include: {
                  name: 'payment_plan',
                  query: {
                    populate: 'subscription_pricing_models',
                  },
                },
              },
            },
            {
              name: 'brands',
              query: {
                include: ['logo', 'footer_image'],
              },
            },
            {
              name: 'users',
              query: {
                include: ['avatar_blob'],
              },
            },
            'notes',
          ],
          include: ['logo', 'footer_image'],
        },
        blocker: true,
        cancellable: this,
      }).catch(() => {
        this.$toast({
          variant: 'danger',
          title: this.$gettext('Error'),
          message: this.$gettext('Company not found'),
          router: true,
        })
        this.cancel()
      })
      this.company = company

      const agencyCompany = await this.$crud.agency.company.get({
        id: this.$route.params.companyCode,
        blocker: true,
      }).catch(async (err) => {
        if (err.status === 404) {
          const accepted = await this.$confirmModal({
            title: this.$gettext('Company not found'),
            message: this.$gettext('Do you wish to try to recreate it with default values?'),
            okVariant: 'success',
            cancelVariant: 'danger',
          })
          if (accepted) {
            this.agencyCompany = this.getAgencyCompanyDefault()
            this.agencyCompany.code = company.code
            await this.$crud.agency[this.crudEndpoint].save({
              obj: this.agencyCompany,
              blocker: true,
              cancellable: this,
              disableAppendId: true,
            })
            this.$toastSaveSuccess()
          }
        }
        this.cancel()
      })
      this.agencyCompany = agencyCompany

      this.origCompany = cloneDeep(this.company)
      this.origAgencyCompany = cloneDeep(this.agencyCompany)
    },
    setCountryCurrencyCode() {
      if (this.company.market === 'ro') return 'RON'
      if (this.company.market === 'en') return 'GBP'
      return 'EUR'
    },
    async revalidate() {
      const isValid = await this.$refs.detailsObserver.validate()
      this.detailsValid = isValid
    },
    async save() {
      if (!this.isNew) {
        this.$refs.detailsTab.revalidate()
      }
      const isValid = await this.$refs.detailsObserver.validate()
      this.detailsValid = isValid
      if (isValid) {
        const { agencyCompany } = this.companyObj
        if (this.isNew) {
          if (this.company.market === 'ro') agencyCompany.flight_itinerary_footer_text = 'Acest document este doar pentru serviciile mentionate. Orice serviciu suplimentar va fi platit de catre client.'
          else agencyCompany.flight_itinerary_footer_text = 'This document is valid only for the services mentioned in the order. All extra services will be paid by the client.'

          agencyCompany.pax_titles = [
            ...[
              { id: -1, label: 'Miss', gender: false },
              { id: -2, label: 'Mr', gender: true },
              { id: -3, label: 'Mrs', gender: false },
              { id: -4, label: 'Ms', gender: false },
              { id: -5, label: 'Mstr', gender: true },
            ],
            ...((this.company.market !== 'ro') ? [
              { id: -6, label: 'Prof', gender: null },
              { id: -7, label: 'Rev', gender: null },
              { id: -8, label: 'Sir', gender: true },
              { id: -9, label: 'Dr', gender: null },
            ] : []),
          ]

          if (this.company.market === 'ro') {
            agencyCompany.vat_types = [
              { code: 'RM', rate: 0, margin_vat_rate: 19, label: 'Regimul marjei - agentii de turism', is_default: true, is_active: true },
              { code: 'SDD', rate: 0, margin_vat_rate: 0, label: 'SDD', is_default: false, is_active: true },
              { code: 'SFDD', rate: 0, margin_vat_rate: 0, label: 'SFDD', is_default: false, is_active: true },
              { code: 'NIB', rate: 0, margin_vat_rate: 0, label: 'Neimpozabil', is_default: false, is_active: true },
              { code: 'VAT_19', rate: 19, margin_vat_rate: 0, label: '19%', is_default: false, is_active: true },
              { code: 'VAT_20', rate: 20, margin_vat_rate: 0, label: '20%', is_default: false, is_active: false },
              { code: 'VAT_24', rate: 24, margin_vat_rate: 0, label: '24%', is_default: false, is_active: false },
              { code: 'VAT_9', rate: 9, margin_vat_rate: 0, label: '9%', is_default: false, is_active: false },
              { code: 'VAT_5', rate: 5, margin_vat_rate: 0, label: '5%', is_default: false, is_active: true },
              { code: 'VAT_0', rate: 0, margin_vat_rate: 0, label: '0%', is_default: false, is_active: true },
            ]
          } else {
            agencyCompany.vat_types = [
              { code: 'VAT_TOMS', rate: 0, margin_vat_rate: 20, label: 'TOMS', is_default: true, is_active: true },
              { code: 'VAT_20', rate: 20, margin_vat_rate: 0, label: '20%', is_default: true, is_active: true },
              { code: 'VAT_0', rate: 0, margin_vat_rate: 0, label: '0%', is_default: false, is_active: true },
            ]
          }

          if (this.company.market === 'ro') {
            agencyCompany.accounting_product_types = [
              { code: 'DEPOSIT', label: 'Avans', products: [], vats: ['RM', 'SDD', 'SFDD', 'NIB', 'VAT_19', 'VAT_9', 'VAT_5', 'VAT_0'], default_vat_eu_code: 'RM', defaults: [] },
              { code: 'HOTEL_UE', label: 'Hotel UE', products: ['hotel', 'other'], vats: ['RM'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: true, is_domestic: false }] },
              { code: 'HOTEL_NONUE', label: 'Hotel non UE', products: ['hotel', 'other'], vats: ['SDD', 'NIB'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: false, is_domestic: false }] },
              { code: 'CRUISE_UE', label: 'Croaziera UE', products: ['cruise', 'other'], vats: ['RM'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['cruise'], is_eu: true, is_domestic: false }] },
              { code: 'CRUISE_NONUE', label: 'Croaziera non UE', products: ['cruise', 'other'], vats: ['SDD', 'NIB'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['cruise'], is_eu: false, is_domestic: false }] },
              { code: 'HOTEL_RO', label: 'Hotel RO', products: ['hotel', 'other'], vats: ['RM', 'VAT_0', 'VAT_5', 'VAT_9', 'VAT_19', 'VAT_20', 'VAT_24'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: true, is_domestic: true }] },
              { code: 'PACHET_UE', label: 'Pachet UE', products: ['package', 'etrip_package', 'other'], vats: ['RM'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: true, is_domestic: false }] },
              { code: 'PACHET_NONUE', label: 'Pachet non UE', products: ['package', 'etrip_package', 'other'], vats: ['SDD', 'NIB'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: false, is_domestic: false }] },
              { code: 'PACHET_RO', label: 'Pachet RO', products: ['package', 'etrip_package', 'other'], vats: ['VAT_9', 'RM', 'VAT_19'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: true, is_domestic: true }] },
              { code: 'ZBOR_EXT', label: 'Zbor ext', products: ['flight', 'other'], vats: ['SDD', 'RM'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['flight'], is_eu: true, is_domestic: false }, { id: -uniqueId(), products: ['flight'], is_eu: false, is_domestic: false }] },
              { code: 'ZBOR_RO', label: 'Zbor int RO', products: ['flight', 'other'], vats: ['SDD', 'NIB', 'RM', 'VAT_19'], default_vat_eu_code: 'VAT_19', defaults: [{ id: -uniqueId(), products: ['flight'], is_eu: true, is_domestic: true }] },
              { code: 'ALT_UE', label: 'Alt produs UE', products: ['other', 'car_rental'], vats: ['RM'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: true, is_domestic: false }], default_eu: true },
              { code: 'ALT_NONUE', label: 'Alt produs non UE', products: ['other', 'car_rental'], vats: ['SDD', 'NIB'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: false, is_domestic: false }], default_non_eu: true },
              { code: 'ALT_RO', label: 'Alt produs RO', products: ['other', 'car_rental'], vats: ['VAT_19', 'VAT_9', 'VAT_5'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: true, is_domestic: true }], default_domestic: true },
              { code: 'TRANS_UE', label: 'Transport UE', products: ['transfer', 'other'], vats: ['SDD', 'NIB', 'RM'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: true, is_domestic: false }] },
              { code: 'TRANS_NONUE', label: 'Transport non UE', products: ['transfer', 'other'], vats: ['SDD', 'NIB'], default_vat_eu_code: 'SDD', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: false, is_domestic: false }] },
              { code: 'TRANS_RO', label: 'Transport RO', products: ['transfer', 'other'], vats: ['RM', 'VAT_19'], default_vat_eu_code: 'RM', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: false, is_domestic: false }] },
              { code: 'INSUR', label: 'Asigurare', products: ['insurance', 'mondial_insurance', 'other'], vats: ['SFDD', 'SDD', 'NIB', 'RM'], default_vat_eu_code: 'SFDD', defaults: [{ id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: true, is_domestic: false }, { id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: false, is_domestic: false }, { id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: true, is_domestic: true }] },
              { code: 'TAX', label: 'Taxe', products: ['flight', 'other'], vats: ['SDD'], default_vat_eu_code: 'SDD', defaults: [] },
            ]
          } else {
            agencyCompany.accounting_product_types = [
              { code: 'DEPOSIT', label: 'Deposit', products: [], vats: ['VAT_TOMS', 'VAT_20', 'VAT_0'], default_vat_eu_code: 'VAT_TOMS', defaults: [] },
              { code: 'HOTEL_EU', label: 'EU hotel', products: ['hotel', 'other'], vats: ['VAT_TOMS'], default_vat_eu_code: 'VAT_TOMS', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: true, is_domestic: false }] },
              { code: 'HOTEL_NONEU', label: 'Non EU hotel', products: ['hotel', 'other'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: false, is_domestic: false }] },
              { code: 'CRUISE_EU', label: 'EU cruise', products: ['cruise', 'other'], vats: ['VAT_TOMS'], default_vat_eu_code: 'VAT_TOMS', defaults: [{ id: -uniqueId(), products: ['cruise'], is_eu: true, is_domestic: false }] },
              { code: 'CRUISE_NONEU', label: 'Non EU cruise', products: ['cruise', 'other'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['cruise'], is_eu: false, is_domestic: false }] },
              { code: 'HOTEL_UK', label: 'UK hotel', products: ['hotel', 'other'], vats: ['VAT_TOMS', 'VAT_20', 'VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['hotel'], is_eu: true, is_domestic: true }] },
              { code: 'PACK_EU', label: 'EU package', products: ['package', 'etrip_package', 'other'], vats: ['VAT_TOMS'], default_vat_eu_code: 'VAT_TOMS', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: true, is_domestic: false }] },
              { code: 'PACK_NONEU', label: 'Non EU package', products: ['package', 'etrip_package', 'other'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: false, is_domestic: false }] },
              { code: 'PACK_UK', label: 'UK package', products: ['package', 'etrip_package', 'other'], vats: ['VAT_20', 'VAT_0', 'VAT_TOMS'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['package', 'etrip_package'], is_eu: true, is_domestic: true }] },
              { code: 'FLIGHT_INT', label: 'International flight', products: ['flight', 'other'], vats: ['VAT_0', 'VAT_TOMS'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['flight'], is_eu: true, is_domestic: false }, { id: -uniqueId(), products: ['flight'], is_eu: false, is_domestic: false }] },
              { code: 'FLIGHT_UK', label: 'Internal UK flight', products: ['flight', 'other'], vats: ['VAT_0', 'VAT_TOMS', 'VAT_20'], default_vat_eu_code: 'VAT_20', defaults: [{ id: -uniqueId(), products: ['flight'], is_eu: true, is_domestic: true }] },
              { code: 'OTHER_EU', label: 'Other EU', products: ['other', 'car_rental'], vats: ['VAT_TOMS', 'VAT_0'], default_vat_eu_code: 'VAT_TOMS', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: true, is_domestic: false }], default_eu: true },
              { code: 'OTHER_NOEU', label: 'Other non UE', products: ['other', 'car_rental'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: false, is_domestic: false }], default_non_eu: true },
              { code: 'OTHER_UK', label: 'Other UK', products: ['other', 'car_rental'], vats: ['VAT_0', 'VAT_20'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['other', 'car_rental'], is_eu: true, is_domestic: true }], default_domestic: true },
              { code: 'TRANS_EU', label: 'Transport EU', products: ['transfer', 'other'], vats: ['VAT_0', 'VAT_TOMS'], default_vat_eu_code: 'VAT_TOMS', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: true, is_domestic: false }] },
              { code: 'TRANS_NONEU', label: 'Transport non EU', products: ['transfer', 'other'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: false, is_domestic: false }] },
              { code: 'TRANS_UK', label: 'Transport UK', products: ['transfer', 'other'], vats: ['VAT_0', 'VAT_20'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['transfer'], is_eu: true, is_domestic: true }] },
              { code: 'INSUR', label: 'INSURANCE', products: ['insurance', 'other'], vats: ['VAT_0'], default_vat_eu_code: 'VAT_0', defaults: [{ id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: true, is_domestic: false }, { id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: false, is_domestic: false }, { id: -uniqueId(), products: ['insurance', 'mondial_insurance'], is_eu: true, is_domestic: true }] },
            ]
          }
          agencyCompany.accounting_product_types.forEach((a) => { a.default_vat_non_eu_code = a.default_vat_eu_code })
          if (this.company.market === 'ro') {
            agencyCompany.payment_methods = [
              { id: -uniqueId(), type: 'bank', label: 'transfer bancar', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'credit_card', label: 'card credit', has_ledger: false, force_value: true, allow_issue: false },
              { id: -uniqueId(), type: 'cash', label: 'chitanță', has_ledger: true, force_value: true, allow_issue: true },
              { id: -uniqueId(), type: 'debit_card', label: 'card debit', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'voucher', label: 'voucher', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'cheque', label: 'cec', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'refund', label: 'dispoziție de plată', has_ledger: true, force_value: false, allow_issue: true },
              { id: -uniqueId(), type: 'other', label: 'altă metodă de plată', has_ledger: false, force_value: null, allow_issue: false },
            ]
          } else {
            agencyCompany.payment_methods = [
              { id: -uniqueId(), type: 'bank', label: 'bank transfer', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'credit_card', label: 'credit card', has_ledger: false, force_value: true, allow_issue: false },
              { id: -uniqueId(), type: 'cash', label: 'cash', has_ledger: true, force_value: true, allow_issue: true },
              { id: -uniqueId(), type: 'debit_card', label: 'debit card', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'voucher', label: 'voucher', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'cheque', label: 'cheque', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'refund', label: 'refund', has_ledger: true, force_value: false, allow_issue: true },
              { id: -uniqueId(), type: 'other', label: 'other payment method', has_ledger: false, force_value: null, allow_issue: false },
              { id: -uniqueId(), type: 'credit_note', label: 'credit note', has_ledger: true, force_value: false, allow_issue: true },
              { id: -uniqueId(), type: 'sps_taps', label: 'SPS / TAPS', has_ledger: false, force_value: null, allow_issue: false },
            ]
          }
        }
        if (!agencyCompany.code) agencyCompany.code = this.companyObj.company.code
        this.companyObj.company.payments.forEach((pay) => {
          if (pay.subscription_id < 0) {
            // eslint-disable-next-line max-len
            pay.subscription = this.companyObj.company.subscriptions.find((sub) => sub.id === pay.subscription_id)
            delete pay.subscription_id
          }
        })
        this.companyObj.company.subscriptions.forEach((sub) => {
          if (sub.id < 0) delete sub.id
        })
        this.companyObj.company.invoice_rules.forEach((rule) => {
          delete rule.intervalCount
          delete rule.intervalUnit
        })
        await this.$newCrud.company[this.crudEndpoint].save({
          obj: this.companyObj.company,
          blocker: true,
          cancellable: this,
          disableAppendId: !this.$route.params.companyCode,
        })
        await this.$crud.agency[this.crudEndpoint].save({
          obj: agencyCompany,
          blocker: true,
          cancellable: this,
          disableAppendId: !this.$route.params.companyCode,
        })
        this.$toastSaveSuccess()
        this.saveSuccessful = true
        if (!this.$route.params.companyCode) this.$router.replace({ path: `/companies/edit/${this.companyObj.company.code}` })
        else await this.getCompany()
      } else {
        this.$toastInvalidForm()
        this.saveSuccessful = false
      }
    },
    cancel() {
      this.$router.push({ name: 'Company' })
    },
  },
}
</script>

<style>
  @media (max-width: 1350px) {
    .nav-tabs .nav-link {
      display: none !important;
    }
  }
  @media (min-width: 1351px) {
    .select-tab {
      display: none;
    }
  }
</style>
