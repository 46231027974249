<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.label"
          :name="$gettext('label')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.url"
          :name="$gettext('url')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.meta_username"
          :name="$gettext('username')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.meta_password"
          :name="$gettext('password')"
          :rules="!innerValue.id ? 'required' : ''"
          type="password"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
