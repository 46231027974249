<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.currency_code"
          :name="$gettext('currency')"
          rules="required"
          component="select"
          :options="currencies"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.account_number"
          :name="$gettext('account number')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.bank_name"
          :name="$gettext('bank name')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.branch"
          :name="$gettext('branch')"
          rules="required"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['saleCurrencies'],
  data() {
    return {
      currencies: [],
    }
  },
  created() {
    if (this.saleCurrencies[0].currency_code) {
      this.saleCurrencies.map((currency) => {
        if (currency.currency_code) {
          this.currencies.push({ value: currency.currency_code, text: currency.currency_code })
        }
      })
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
