<template>
  <div class="app">
    <main class="app-content d-flex flex-row">
      <div
        style="min-width: 250px;"
        class="d-none d-md-block">
        <b-nav
          vertical
          style="position: fixed;"
          class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white px-0">
          <Nav class="mb-4 rest"/>
        </b-nav>
      </div>
      <div class="main-content">
        <b-breadcrumb class="bg-white" :items="breadcrumb"></b-breadcrumb>
        <b-container fluid style="margin-bottom: 25px;">
          <transition name="fade">
            <router-view :key="$route.meta.ignoreRouteKey ? null : $route.path" />
          </transition>
        </b-container>
      </div>
    </main>
    <Footer style="display: flex;"/>
    <b-button
      variant="primary"
      v-b-toggle.side
      class="sidebar-btn d-md-none">
      <div style="height: 10vh; display: flex; align-items: center">
        <fa-icon icon="arrow-right"/>
      </div>
    </b-button>
    <b-sidebar bg-variant="white" shadow backdrop id="side">
      <Nav/>
    </b-sidebar>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
import Nav from './partials/Nav.vue'

export default {
  components: {
    Footer,
    Nav,
  },
  computed: {
    breadcrumb() {
      return this.$route.matched
        .filter((el) => (el.name || el.redirect) && el.meta.label && !el.meta.skipBreadcrumb)
        .map((el) => ({
          text: this.$vueTranslate(el.meta.label), to: { name: el.name || el.redirect.name },
        }))
    },
  },
}
</script>

<style scoped>
  .sidebar-btn {
    position: fixed;
    top: 45vh;
    border-radius: 0 100% 100% 0;
    opacity: 0.25;
    transition: opacity .25s;
    z-index: 5;
  }
  .sidebar-btn:hover {
    opacity: 1;
  }
  .main-content {
    width: 100%;
    overflow-x: scroll;
  }
  .main-content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
</style>
