<template>
  <b-form @submit.prevent="save" novalidate>
    <custom-input
      v-model="innerValue.text"
      :name="$gettext('text')"
      component="textarea"
      rules="required"
      :rows="5"
    />
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
