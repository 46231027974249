<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col sm="6">
        <custom-input
          component="date"
          v-model="innerValue.valid_from"
          :name="$gettext('valid from')"
          :time="true"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          component="date"
          v-model="innerValue.valid_to"
          :name="$gettext('valid to')"
          :time="true"
        />
      </b-col>
      <b-col sm="12">
        <custom-input
          component="textarea"
          rules="required"
          v-model="innerValue.message"
          :name="$gettext('message')"
        />
      </b-col>
      <b-col sm="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.active"
          :name="$gettext('active')"
          noLabel
        />
      </b-col>
      <b-col sm="12">
        <custom-input
          noLabel
          component="checkbox"
          v-model="innerValue.is_public"
          :name="$gettext('is public')"
        />
      </b-col>
      <b-col sm="12">
        <custom-input
          noLabel
          component="checkbox"
          v-model="innerValue.is_html"
          :name="$gettext('is html')"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
