<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('name')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.default_office_id"
          :name="$gettext('default office')"
          component="select"
          :options="defaultOfficeOptions"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          v-model="innerValue.invoice_custom_text"
          :name="$gettext('invoice custom text')"
          component="textarea"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          v-model="innerValue.invoice_footer_text"
          :name="$gettext('invoice footer text')"
          component="textarea"
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <logo-picker
        :mimeType="innerValue.logo_mime"
        @setMimeType="innerValue.logo_mime = $event"
        v-model="innerValue.logo"
        :previewUrl="innerValue.id ? `${companyUrl}/bin/brand_logo/${innerValue.id}` : null" />
      <b-col cols="12">
        <hr/>
      </b-col>
      <logo-picker
        :mimeType="innerValue.footer_image_mime"
        @setMimeType="innerValue.footer_image_mime = $event"
        v-model="innerValue.footer_image"
        :previewUrl="innerValue.id
          ? `${companyUrl}/bin/brand_footer/${innerValue.id}`
          : null" :title="$gettext('footer image')" />
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'
import LogoPicker from '@/shared/components/others/LogoPicker.vue'

export default {
  mixins: [vModelMixin],
  props: ['offices'],
  components: {
    'logo-picker': LogoPicker,
  },
  data() {
    return {
      defaultOfficeOptions: [],
      companyUrl: null,
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
  async created() {
    this.companyUrl = (await this.$root.settings).apis.company
    this.offices.map((office) => {
      this.defaultOfficeOptions.push({ text: office.name, value: office.id })
    })
  },
}
</script>
