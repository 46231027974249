import { $gettext } from '@/shared/services/translate-dummy'
import Countries from './countries/View.vue'
import Users from './users/View.vue'
import CountrySubdivisions from './country_subdivisions/View.vue'
import Cities from './cities/View.vue'
import CityAreas from './city_areas/View.vue'
import Currencies from './currencies/View.vue'
import BookingTypes from './booking_types/View.vue'
import EtripSuppliers from './etrip_suppliers/View.vue'
import Airports from './airports/View.vue'
import Airlines from './airlines/View.vue'
import Messages from './messages/View.vue'
import PaymentPlans from './payment_plans/View.vue'
import Imports from './imports/View.vue'

export default [
  {
    path: 'countries',
    name: 'countries',
    component: Countries,
    meta: { label: $gettext('countries'), registerQueryParams: true },
  },
  {
    path: 'country-subdivisions',
    name: 'country subdivisions',
    component: CountrySubdivisions,
    meta: { label: $gettext('country subdivisions'), registerQueryParams: true },
  },
  {
    path: 'cities',
    name: 'cities',
    component: Cities,
    meta: { label: $gettext('cities'), registerQueryParams: true },
  },
  {
    path: 'city-areas',
    name: 'city areas',
    component: CityAreas,
    meta: { label: $gettext('city areas'), registerQueryParams: true },
  },
  {
    path: 'booking-types',
    name: 'booking types',
    component: BookingTypes,
    meta: { label: $gettext('booking types'), registerQueryParams: true },
  },
  {
    path: 'currencies',
    name: 'currencies',
    component: Currencies,
    meta: { label: $gettext('currencies'), registerQueryParams: true },
  },
  {
    path: 'etrip-suppliers',
    name: 'etrip suppliers',
    component: EtripSuppliers,
    meta: { label: $gettext('etrip suppliers'), registerQueryParams: true },
  },
  {
    path: 'airlines',
    name: 'airlines',
    component: Airlines,
    meta: { label: $gettext('airlines'), registerQueryParams: true },
  },
  {
    path: 'airports',
    name: 'airports',
    component: Airports,
    meta: { label: $gettext('airports'), registerQueryParams: true },
  },
  {
    path: 'users',
    name: 'admin users',
    component: Users,
    meta: { label: $gettext('admin users'), registerQueryParams: true },
  },
  {
    path: 'messages',
    name: 'messages',
    component: Messages,
    meta: { label: $gettext('messages'), registerQueryParams: true },
  },
  {
    path: 'payment-plans',
    name: 'payment plans',
    component: PaymentPlans,
    meta: { label: $gettext('payment plans'), registerQueryParams: true },
  },
  {
    path: 'imports',
    name: 'imports',
    component: Imports,
    meta: { label: $gettext('imports') },
  },
]
