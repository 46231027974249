// Auth requests
import crud from './crud'
import newCrud from './crud-new'
import AuthData from './auth-data'

const instance = {
  async login(data) {
    const { user } = await newCrud.company.auth.save({ obj: data, blocker: true })

    await crud.agency.auth.save({ obj: data, blocker: true })
    AuthData.isLoggedInChecked = true

    const language = localStorage.getItem('language')
    localStorage.clear()
    if (language) localStorage.setItem('language', language)
    AuthData.populateData(user)

    return user
  },

  async refreshLoggedUser() {
    const { user = null } = await newCrud.company.whoAmI.get().catch(() => ({})) || {}
    AuthData.isLoggedInChecked = true

    if (!user) {
      AuthData.resetData()
    } else {
      AuthData.populateData(user)
    }

    return user
  },

  async agencyWhoAmI() {
    const { user = null } = await crud.agency.whoAmI.get().catch(() => ({})) || {}

    return user
  },

  async logOut() {
    await newCrud.company.logout.save({ blocker: true })

    AuthData.resetData()
    AuthData.isLoggedInChecked = true
  },
}

export default instance
