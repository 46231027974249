<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      @add="edit()"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      :fields="fields"
      :filters="filters"
      ref="crudView"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
        />
      </template>

      <!-- RESULTS -->
      <template v-slot:results="{ results, sortChanged, pageIndex }">
        <results
          :pageIndex="pageIndex"
          :results="results"
          :fields="fields"
          @edit="edit"
          @remove="remove"
          @sort-changed="sortChanged"
        />
      </template>
    </crud-view>

    <!-- MODAL -->
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="obj.id ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons @save="save" @cancel="cancel" />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form v-model="obj" @save="save" />
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'

import Filters from './Filters.vue'
import Results from './Results.vue'
import Form from './Form.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
    Form,
  },
  data() {
    return {
      crudEndpoint: 'company|systemUser',
      filters: this.getDefaultFilters(),
      obj: {},
      fields: [
        { key: 'index', label: '', tdClass: 'td-index-class' },
        {
          key: 'username',
          label: this.$gettext('username'),
          stickyColumn: true,
        },
        {
          key: 'name',
          label: this.$gettext('name'),
        },
        {
          key: 'email',
          label: this.$gettext('email'),
        },
        {
          key: 'active',
          label: this.$gettext('active'),
          formatter: (val) => (val ? this.$gettext('yes') : this.$gettext('no')),
        },
        {
          key: 'buttons',
          label: '',
        },
      ],
    }
  },
  computed: {
    api() {
      return this.crudEndpoint.split('|')
    },
  },
  methods: {
    async edit(id) {
      if (id) this.obj = await this.$newCrud[this.api[0]][this.api[1]].get({ id })
      else this.obj = { ips: [], active: true, is_super: true }

      this.$refs.modal.show()
    },
    async remove(id) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        await this.$newCrud[this.api[0]][this.api[1]].delete({ id })

        this.resetSearch()

        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()

      if (isValid) {
        await this.$newCrud[this.api[0]][this.api[1]].save({ obj: this.obj, blocker: true })

        this.resetSearch()

        this.$toastSaveSuccess()

        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    cancel() {
      this.$refs.modal.hide()
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {
      }
    },
  },
}
</script>
