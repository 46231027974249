<!-- eslint-disable no-tabs -->
<template>
	<div>
		<div style="width: 100% !important">
			<div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="background-color: green; color: white; text-align: center"
        :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%
      </div>
			<button @click="prev">PREV</button>
      <button @click="next">NEXT</button>
      <pdf
        ref="pdf"
        style="border: 1px solid black; width: 100%; height: 500px;"
        :src="document"
        :page="page"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event">
      </pdf>
		</div>
	</div>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  props: [
    'document',
  ],
  data() {
    return {
      loadedRatio: 0,
      page: 1,
      numPages: 0,
    }
  },
  methods: {
    error() {
    },
    prev() {
      if (this.page > 1) this.page -= 1
    },
    next() {
      if (this.page < this.numPages) this.page += 1
    },
  },
}
</script>
