import Vue from 'vue'
import validationMixin from './validation'
import toastMixin from './toaster'
import confirmMixin from './confirm'
import lodashMixin from './lodash'
import globalMixin from './global'

Vue.mixin(validationMixin)
Vue.mixin(toastMixin)
Vue.mixin(confirmMixin)
Vue.mixin(globalMixin)
Vue.mixin(lodashMixin)
