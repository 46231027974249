// Validation plugin

import Vue from 'vue'

import en from 'vee-validate/dist/locale/en.json'
import ro from 'vee-validate/dist/locale/ro.json'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// Install translates
localize({
  en,
  ro,
})
