<template>
  <b-row class="mt-4">
    <b-col cols="12">
      <custom-input
        v-model="innerValue.invoice_custom_text"
        :name="$gettext('invoice custom text')"
        component="textarea"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        v-model="innerValue.invoice_footer_text"
        :name="$gettext('invoice footer text')"
        component="textarea"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        v-model="innerValue.receipt_footer_text"
        :name="$gettext('receipt footer text')"
        component="textarea"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        v-model="innerValue.travel_documents_text"
        :name="$gettext('travel documents text')"
        component="textarea"
      />
    </b-col>
    <b-col cols="12">
      <custom-input
        v-model="innerValue.flight_itinerary_footer_text"
        :name="$gettext('flight itinerary footer text')"
        component="textarea"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
}
</script>
