<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.supplier_id"
          :name="$gettext('etrip supplier')"
          rules="required"
          component="select"
          :options="etripSupplierOptions"
          @change="onEtripSupplierUpdated"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.partner_id"
          :name="$gettext('supplier')"
          :rules="isNew ? '' : 'required'"
          component="select"
          :requestParams="{ is_supplier: true }"
          :placeholders="{ company: company.code }"
          apiKey="agency|autocompletePartner"
          labelKey="name"
          :reduce="obj => obj.id"
          :disabled="isNew"
          @change="onSupplierUpdated"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.agent_code"
          :name="$gettext('agent code')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" class="mb-2">
        <hr/>
        <div v-translate>search types</div>
      </b-col>
      <b-col sm="4">
        <custom-input
          v-model="innerValue.show_packages"
          :name="$gettext('show packages')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="4">
        <custom-input
          v-model="innerValue.show_hotel_only"
          :name="$gettext('show hotel only')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="4">
        <custom-input
          v-model="innerValue.show_flight_only"
          :name="$gettext('show flight only')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col
        sm="12"
      >
        <b-form-group label-size="md" :label="$gettext('flight search types')">
          <b-form-checkbox-group
            size="sm"
            id="flightTypes"
            v-model="innerValue.flight_search_types"
            :options="flightSearchTypes"
            name="flightTypes"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      etripSuppliers: [],
      etripSupplierOptions: [],
      flightSearchTypes: [
        { value: 'internal_charter', text: this.$gettext('charters') },
        { value: 'internal_scheduled', text: this.$gettext('scheduled') },
        { value: 'xmlpro_published', text: this.$gettext('worldspan powershopper fares') },
      ],
    }
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
    },
  },
  methods: {
    onEtripSupplierUpdated(e) {
      this.innerValue.supplier = { label: e.text }
    },
    onSupplierUpdated(e) {
      this.innerValue.partner = { name: e.name }
    },
    save() {
      this.innerValue.supplier = this.etripSuppliers.items
        .find((item) => item.id === this.innerValue.supplier_id)
      this.$emit('save')
    },
  },
  async created() {
    if (!this.innerValue.flight_search_types) {
      this.innerValue.flight_search_types = []
    }
    this.$crud.agency.etripSupplier.get().then((etripSuppliers) => {
      this.etripSuppliers = etripSuppliers
      etripSuppliers.items.map((etripSupplier) => {
        this.etripSupplierOptions.push({ value: etripSupplier.id, text: etripSupplier.label })
      })
    })
  },
}
</script>
