<template>
  <b-row>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.code"
        :name="$gettext('code')"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.name"
        :name="$gettext('name')"
      />
    </b-col>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.active"
        :name="$gettext('active')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  computed: {
    yesNoOptions() {
      return [
        { text: this.$gettext('all'), value: null },
        { text: this.$gettext('yes'), value: 'yes' },
        { value: 'no', text: this.$pgettext('yes/no', 'no') },
      ]
    },
  },
}
</script>
