<template>
  <b-form-file
    size="sm"
    :state="state"
    :browse-text="$gettext('browse')"
    @input="$emit('input', $event)"
    :accept="accept"
    :drop-placeholder="$gettext('drop file here')"
  >
  </b-form-file>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'FileInput',
  mixins: [vModelMixin],
  props: {
    accept: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
}
</script>
