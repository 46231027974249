<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.code"
          :name="$gettext('code')"
          rules="required|length:3"
        />
      </b-col>
      <b-col cols="12" md="6">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('name')"
          rules="required"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
