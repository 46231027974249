<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.code"
          :name="$gettext('code')"
          rules="required|length:3"
          :disabled="isEditing"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('name')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.symbol"
          :name="$gettext('symbol')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.subdivision_size"
          :name="$gettext('exponent')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.name_inflect"
          :name="$gettext('currency inflection')"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.subdivision_inflect"
          :name="$gettext('subdivision inflection')"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.active"
          :name="$gettext('active')"
          :options="activeOptions"
          component="radio"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.gender"
          :name="$gettext('currency gender')"
          :options="genderOptions"
          component="radio"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.subdivision_gender"
          :name="$gettext('subdivision gender')"
          :options="genderOptions"
          component="radio"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['isEditing'],
  data() {
    return {
      activeOptions: [
        { value: true, text: this.$gettext('yes') },
        { value: false, text: this.$pgettext('yes/no', 'no') },
      ],
      genderOptions: [
        { value: false, text: 'M' },
        { value: true, text: 'F' },
      ],
    }
  },
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
