<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      @add="edit()"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      :fields="fields"
      :filters="filters"
      ref="crudView"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
        />
      </template>

      <!-- RESULTS -->
      <template v-slot:results="{ results, sortChanged, pageIndex }">
        <results
          :pageIndex="pageIndex"
          :results="results"
          :fields="fields"
          @edit="edit"
          @remove="remove"
          @sort-changed="sortChanged"
        />
      </template>
    </crud-view>

    <!-- MODAL -->
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="obj.code ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons @save="save" @cancel="cancel" />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form v-model="obj" :isEditing="isEditing" @save="save" />
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'

import Filters from './Filters.vue'
import Results from './Results.vue'
import Form from './Form.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
    Form,
  },
  data() {
    return {
      crudEndpoint: 'agency|currency',
      filters: this.getDefaultFilters(),
      obj: {},
      isEditing: false,
      fields: [
        { key: 'index', label: '', tdClass: 'td-index-class' },
        {
          key: 'code',
          label: this.$gettext('code'),
          sortable: true,
          stickyColumn: true,
        },
        {
          key: 'name',
          label: this.$gettext('name'),
          sortable: true,
        },
        {
          key: 'symbol',
          label: this.$gettext('symbol'),
        },
        {
          key: 'num',
          label: this.$gettext('iso3166 number'),
        },
        {
          key: 'subdivision_size',
          label: this.$gettext('exponent'),
        },
        {
          key: 'inflections',
          label: this.$gettext('inflections and gender'),
        },
        {
          key: 'active',
          label: this.$gettext('active'),
          formatter: (val) => (val ? this.$gettext('yes') : this.$gettext('no')),
        },
        {
          key: 'buttons',
          label: '',
        },
      ],
    }
  },
  computed: {
    api() {
      return this.crudEndpoint.split('|')
    },
  },
  methods: {
    async edit(code) {
      if (code) {
        this.isEditing = true
        this.obj = await this.$crud[this.api[0]][this.api[1]].get({ id: code })
      } else {
        this.isEditing = false
        this.obj = { active: true }
      }

      this.$refs.modal.show()
    },
    async remove(code) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        await this.$crud[this.api[0]][this.api[1]].delete({ id: code })

        this.resetSearch()

        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()

      if (isValid) {
        await this.$crud[this.api[0]][this.api[1]]
          .save({ obj: this.obj, blocker: true, disableAppendId: !this.isEditing })

        this.resetSearch()

        this.$toastSaveSuccess()

        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    cancel() {
      this.$refs.modal.hide()
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {
        order: 'code',
      }
    },
  },
}
</script>
