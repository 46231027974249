<template>
  <v-date-picker
    :min-date="notBefore"
    :max-date="notAfter"
    :locale="lang"
    :update-on-input="false"
    v-model="innerValue"
    :attributes="calendarAttributes"
    @input="onDateChange"
  >
    <template v-slot="{ inputEvents }">
      <b-input-group
        size="sm"
        :label-for="name"
        label-size="sm"
        :label="name"
      >
        <input
          type="search"
          :name="name"
          v-mask="vMask"
          :class="inputClass"
          v-model="text"
          v-on="filterInputEvents(inputEvents)"
          :placeholder="placeholder || innerPlaceholder"
          autocomplete="off"
        />
      </b-input-group>
    </template>
  </v-date-picker>
</template>

<script>
/* text property automatically changes the innerValue */
import moment from 'moment'

export default {
  props: ['min', 'max', 'value', 'rules', 'name', 'id', 'altCalendarHighlight', 'time', 'placeholder', 'state'],
  created() {
    if (this.value) {
      this.onDateChange(this.value)
    }
  },
  data() {
    return {
      text: null,
      innerValue: null,
      vMask: this.time === true ? '##-##-#### ##:##' : '##-##-####', // Mask rule
      innerPlaceholder: this.time === true ? 'DD-MM-YYYY HH:MM' : 'DD-MM-YYYY', // Input placeholder
      momentFormat: this.time === true ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY', // Momentjs format
      serverFormat: 'YYYY-MM-DDTHH:mm:ss', // Server format
    }
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        if (!val) this.text = ''
        else {
          this.onDateChange(val)
        }
      }
    },
    text(val) {
      // Validate the date with the current placeholder
      const date = moment(val, this.momentFormat, true) // might work without this complexity
      if (date.isValid()) {
        if (this.time) this.innerValue = date.format(this.serverFormat)
        else this.innerValue = date.format(this.serverFormat.split('T')[0])
      } else {
        this.innerValue = null
      }
    },
  },
  computed: {
    calendarAttributes() {
      const ret = [
        {
          key: 'today',
          bar: true,
          dates: new Date(),
          popover: {
            label: this.$gettext('today'),
          },
        },
      ]
      if (this.innerValue) {
        ret.push({
          key: 'selection',
          dates: this.innerValue,
          popover: {
            label: this.$gettext('selected date'),
          },
        })
      }
      if (this.altCalendarHighlight) ret.push(this.altCalendarHighlight)
      return ret
    },
    notBefore() {
      if (this.min) {
        return moment(this.min).toDate()
      }
      return null
    },
    notAfter() {
      if (this.max) {
        return moment(this.max).toDate()
      }
      return null
    },
    lang() {
      return this.$language.current.substring(0, 2)
    },
    inputClass() {
      let c = 'form-control form-control-sm no-validation-icon'
      if (this.state === false) {
        c += ' is-invalid'
      }
      return c
    },
  },
  methods: {
    onDateChange(val) {
      this.text = moment(val).format(this.momentFormat)
    },
    // To avoid auto completing date input if is incomplete
    filterInputEvents(inputEvents) {
      // eslint-disable-next-line no-param-reassign
      delete inputEvents.input
      // eslint-disable-next-line no-param-reassign
      delete inputEvents.change
      return inputEvents
    },
  },
}
</script>
