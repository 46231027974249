<template>
  <div>
    <div class="mx-4 mb-4 d-flex text-center">
      <div
        class="py-2 px-3 bg-gradient-primary"
        style="border-radius: 7px; width: 100%; cursor: pointer;"
        @click="$router.push({ name: 'Dashboard' })">
        <img src="../../assets/logo.png" width="30px"/>
        <h2
          class="d-inline-block text-white mb-0"
          style="position: relative; top: 4px;">Admin</h2>
      </div>
    </div>
    <template v-for="(item, index) in nav">
      <b-nav-item
        class="menu-item my-1"
        :key="'s'+index"
        v-if="!item.children"
        :class="{
          'item-selected': navActive(item)
        }"
        :to="{ name: item.routeName }">
        <span
          :class="{
            'text-primary': navActive(item)
          }"
          class="ml-2">
          <icon :icon="item.icon" :class="item.color"/>
          {{ $vueTranslate(item.name) }}
        </span>
      </b-nav-item>
      <b-nav-item
        class="menu-item my-1"
        :key="'s'+index"
        v-else
        :class="{
          'item-selected': navActive(item)
        }"
        v-b-toggle="'children'+index">
        <span
          :class="{'text-primary': childActive(item)}"
          class="ml-2">
          <icon :icon="item.icon" :class="item.color"/>
          {{ $vueTranslate(item.name) }}
        </span>
      </b-nav-item>
      <b-collapse :id="'children'+index" :key="'children'+index">
        <b-nav-item
          v-for="(child, childIndex) in item.children"
          :key="'child'+childIndex"
          :active="navActive(child)"
          :to="{ name: child.routeName }"
          :class="{
            'item-selected': navActive(child),
            'mb-1': childIndex != item.children.length-1
          }"
          class="menu-item"
          :style="{ opacity: navActive(child) ? 1 : 0.75 }">
          <span
            :class="{'text-black': navActive(child)}"
            class="ml-4">
            <icon :icon="child.icon"/>
            {{ $vueTranslate(child.name) }}
          </span>
        </b-nav-item>
      </b-collapse>
    </template>
    <hr/>
    <b-nav-item @click="logOut" class="mb-4">
      <span class="ml-2">
        <icon class="text-red" :icon="'sign-out-alt'"/>
        <translate class="ml-1">Log Out</translate>
      </span>
    </b-nav-item>
  </div>
</template>

<script>
import nav from '@/app/nav'
import Auth from '@/shared/http/auth'

export default {
  methods: {
    navActive(item) {
      const routeFound = this.$route.matched.find((r) => r.name === item.routeName || r.name === `${item.routeName}View`)
      return Boolean(routeFound)
    },
    childActive(item) {
      let active = false
      item.children.forEach((i) => {
        if (this.navActive(i)) active = true
      })
      return active
    },
    async logOut() {
      await Auth.logOut()

      this.$router.push({ name: 'Login' })
    },
  },
  computed: {
    nav() {
      return nav
    },
  },
}
</script>

<style scoped>
  li {
    list-style: none;
  }
  .nav-link {
    width: 100%;
  }
  .item-selected {
    border-left: solid #5e72e4 3px;
    background-color: #f7f8fe;
  }
  .menu-item {
    transition: background-color .5s, border-left .1s;
  }
</style>
