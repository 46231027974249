<template>
  <b-row>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.name_like"
        :name="$gettext('name')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.local_name_like"
        :name="$gettext('local name')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.iata_code"
        :name="$gettext('iata code')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.city_id"
        :name="$gettext('city')"
        component="select"
        apiKey="agency|commonGeography"
        labelKey="name"
        :requestParams="{ types: 'T', size: 20 }"
        :initialParams="{ type: 'T' }"
        :reduce="obj => obj.id"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.active"
        :name="$gettext('active')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      yesNoOptions: [
        { value: null, text: this.$gettext('all') },
        { value: 'yes', text: this.$gettext('yes') },
        { value: 'no', text: this.$pgettext('yes/no', 'no') },
      ],
    }
  },
}
</script>
