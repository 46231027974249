<template>
  <div class="bg-gradient-primary">
    <b-container class="d-flex align-items-center justify-content-center" style="height: 100vh;">
      <b-row>
        <b-col sm="12">
          <b-card id="login-card">
            <template v-slot:header>
              <b class="text-center">Company Admin Client</b>
            </template>
            <ValidationObserver ref="form" slim>
              <b-form @submit.prevent="login" novalidate>
                <b-form-group label-for="username">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text class="login-prepend">
                        <fa-icon icon="envelope" />
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <ValidationProvider
                      rules="required"
                      :name="$gettext('username')"
                      v-slot="vContext"
                      slim
                    >
                      <b-input
                        :state="$vState(vContext)"
                        id="username"
                        v-model="credentials.username"
                        :placeholder="$gettext('username')"
                      />
                    </ValidationProvider>
                  </b-input-group>
                </b-form-group>
                <b-form-group label-for="password">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text class="login-prepend">
                        <fa-icon icon="lock" />
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <ValidationProvider
                      rules="required"
                      :name="$gettext('password')"
                      v-slot="vContext"
                      slim
                    >
                      <b-input
                        v-model="credentials.password"
                        :placeholder="$gettext('password')"
                        autocomplete="off"
                        :state="$vState(vContext)"
                        type="password"
                      />
                    </ValidationProvider>
                  </b-input-group>
                </b-form-group>
                <hr />
                <b-btn
                  v-disableOnRequests
                  type="submit"
                  block
                  size="sm"
                  variant="primary"
                  >
                  <icon icon="key"/>
                  <translate>login</translate>
                </b-btn>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Auth from '@/shared/http/auth'

export default {
  name: 'Login',
  data() {
    return {
      company: {},
      credentials: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    async login() {
      const isValid = await this.$refs.form.validate()

      if (isValid) {
        await Auth.login(this.credentials)
          .then(() => {
            this.$toast({
              title: this.$gettext('login'),
              message: this.$gettext('login successful'),
              variant: 'success',
              router: true,
            })
            this.$router.push({ name: 'Dashboard' }).catch(() => {})
          })
      } else {
        this.$toastInvalidForm()
      }
    },
  },
}
</script>

<style>
  @media (min-width: 350px) {
    #login-card {
      width: 300px;
    }
  }
  input {
    padding-left: 10px !important;
  }
</style>
