<template>
  <div>
    <!-- CRUD ENTITY -->
    <crud-view
      :crudEndpoint="crudEndpoint"
      :apiQuery="apiQuery"
      @add="edit()"
      @filters-updated="filters = $event"
      @reset-filters="resetFilters"
      :fields="fields"
      :filters="filters"
      ref="crudView"
    >
      <!-- FILTERS -->
      <template v-slot:filters>
        <filters
          v-model="filters"
        />
      </template>

      <!-- RESULTS -->
      <template v-slot:results="{ results, sortChanged, pageIndex }">
        <results
          :pageIndex="pageIndex"
          :results="results"
          :fields="fields"
          @edit="edit"
          @sort-changed="sortChanged"
        />
      </template>
    </crud-view>
  </div>
</template>

<script>
import CrudView from '@/shared/components/pages/CrudView.vue'

import Filters from './Filters.vue'
import Results from './Results.vue'

export default {
  components: {
    CrudView,
    Filters,
    Results,
  },
  data() {
    return {
      crudEndpoint: 'company|company',
      apiQuery: {
        populate: [
          {
            name: 'brands',
            query: {
              populate: 'default_office',
              exclude: ['logo', 'footer_image'],
            },
          },
          'offices',
        ],
        exclude: ['logo', 'footer_image'],
      },
      filters: this.getDefaultFilters(),
      obj: {},
      fields: [
        { key: 'code', label: this.$gettext('code'), stickyColumn: true },
        { key: 'name', label: this.$gettext('name') },
        { key: 'vat_no', label: this.$gettext('vat no') },
        { key: 'company_no', label: this.$gettext('company no') },
        { key: 'email', label: this.$gettext('email') },
        { key: 'address', label: this.$gettext('address') },
        {
          key: 'active',
          label: this.$gettext('active'),
          formatter: (value) => (value ? this.$gettext('yes') : this.$gettext('no')),
        },
        {
          key: 'buttons',
          label: '',
        },
      ],
    }
  },
  methods: {
    async edit(code) {
      if (code) this.$router.push({ path: `${this.$route.path}/edit/${code}` })
      else this.$router.push({ path: `${this.$route.path}/add` })
    },
    resetSearch() {
      this.resetFilters()
      this.$refs.crudView.search(true)
    },
    resetFilters() {
      this.filters = this.getDefaultFilters()
    },
    getDefaultFilters() {
      return {}
    },
  },
}
</script>
