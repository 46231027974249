// Toaster mixin

/* eslint-disable no-param-reassign */
export default {
  methods: {
    $toast({
      message, title = undefined, variant = 'primary', router = false, toaster = 'b-toaster-bottom-right',
    }) {
      const options = {
        title,
        autoHideDelay: 5000,
        variant,
        solid: true,
        toaster,
      }
      if (router) this.$root.$bvToast.toast(message, options)
      else this.$bvToast.toast(message, options)
    },
    $toastDeleteSuccess() {
      this.$toast({ title: this.$gettext('delete'), message: this.$gettext('delete successful'), variant: 'success' })
    },
    $toastInvalidForm() {
      this.$toast({ title: this.$gettext('invalid form'), message: this.$gettext('please correct the indicated errors'), variant: 'danger' })
    },
    $toastSaveSuccess() {
      this.$toast({ title: this.$gettext('save'), message: this.$gettext('save successful'), variant: 'success' })
    },
  },
}
