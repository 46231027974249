<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.currency_code"
          :name="$gettext('currency')"
          component="select"
          apiKey="agency|commonCurrency"
          labelKey="code"
          rules="required"
          :reduce="obj => obj.code"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.amount"
          :name="$gettext('amount')"
          rules="required"
          component="number"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.subscription_id"
          :name="$gettext('subscription')"
          rules="required"
          component="select"
          :options="subscriptionOptions"
          labelKey="text"
          :reduce="obj => obj.value"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.date"
          :name="$gettext('date')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          v-model="innerValue.notes"
          :name="$gettext('notes')"
          component="textarea"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      subscriptionOptions: [],
    }
  },
  created() {
    this.subscriptions.forEach((s) => {
      this.subscriptionOptions.push({
        value: s.id,
        text: `${s.payment_plan.name} (${this.$options.filters.date(s.start_date)} - ${this.$options.filters.date(s.end_date)})`,
      })
    })
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
