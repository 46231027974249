import clone from 'lodash/clone'
import axios from 'axios'
import { formatQuery, pyliantEncode, pyliantDecode } from 'pyliant'
import func from '@/shared/services/functions'
import settings from '@/shared/settings'
import { v4 as uuidv4 } from 'uuid'
import { HTTP_INSTANCES, pendingRequests } from './http'
import ApiEndpoints from './endpoints/index'

let get; let save; let del // Operations declaraction

const cacheStore = {} // Cached results

// Function to clean request parameters
function clean(params) {
  let cleanedParams = null
  if (params) {
    cleanedParams = clone(params)
    Object.keys(cleanedParams).forEach((key) => {
      if (func.isEmpty(cleanedParams[key]) && key !== 'key') {
        delete cleanedParams[key]
      }
    })
  }
  return cleanedParams
}

function cancelRequest(apiKey, name, method, uuid) {
  // Check before because if the request timeouts it will return error
  const { cancellable } = pendingRequests // ?
  // eslint-disable-next-line max-len
  if (cancellable[apiKey] && cancellable[apiKey][name] && cancellable[apiKey][name][method] && cancellable[apiKey][name][method][uuid]) {
    cancellable[apiKey][name][method][uuid].cancel()
    delete cancellable[apiKey][name][method][uuid]
  }
}

function buildURL(url, placeholders) {
  let finalUrl = clone(url)

  // import AuthData from '@/shared/auth/auth-data';
  // Replace global placeholder
  // if (url.indexOf(':company') !== -1) {
  //   url = url.replace(':company', AuthData.userData.company_code);
  // }

  // Replace placeholders
  if (placeholders) {
    Object.keys(placeholders).forEach((key) => {
      finalUrl = url.replace(`:${key}`, placeholders[key])
    })
  }

  return finalUrl
}
// to do: restrict cache on get all
// to do: catch errors
function setup(apiKey, actionName, actionUrl, workerType, cache, target, primaryKey) {
  get = async ({
    id, params, placeholders, blocker, cancellable, query,
  } = {}) => {
    // Replace url placeholders if necessary
    let url = buildURL(actionUrl, placeholders)

    // Final params build for request
    const requestParams = { params: clean(params), metadata: { blocker, uuid: uuidv4() } }

    // Retrieve result if it is cached and not expire [ url|params ]
    const cacheStoreKey = `${url}|${JSON.stringify(requestParams.params)}`
    if (cache && cacheStore[cacheStoreKey] && cacheStore[cacheStoreKey].expires >= Date.now()) {
      return Promise.resolve(cacheStore[cacheStoreKey].result)
    }

    // If request is cancellable and takes too much start loading modal
    let requestModalTimeout
    if (cancellable) {
      // Save the token on cancellable map
      const cancelTokenObj = axios.CancelToken.source()
      if (!pendingRequests.cancellable[apiKey]) {
        pendingRequests.cancellable[apiKey] = {}
      }
      if (!pendingRequests.cancellable[apiKey][actionName]) {
        pendingRequests.cancellable[apiKey][actionName] = {}
      }
      // eslint-disable-next-line max-len
      if (!pendingRequests.cancellable[apiKey][actionName][workerType]) {
        pendingRequests.cancellable[apiKey][actionName][workerType] = {}
      }
      // eslint-disable-next-line max-len
      pendingRequests.cancellable[apiKey][actionName][workerType][requestParams.metadata.uuid] = cancelTokenObj

      // Save the token on axios request
      requestParams.cancelToken = cancelTokenObj.token

      requestModalTimeout = setTimeout(() => {
        cancellable.$showLoading().then((requestCancelled) => {
          if (requestCancelled) {
            cancelRequest(apiKey, actionName, 'get', requestParams.metadata.uuid)
          }
        })
      }, 1500)
    }

    // Start request
    if (id) url += `@${id}`
    if (query) {
      url += `:${formatQuery(query)}`
    }
    const result = await (await HTTP_INSTANCES[apiKey]()).get(`/${url}`, requestParams)
      .then((data) => {
        if (target && id) return pyliantDecode(data.data[target])
        return data.data
      })
      .catch((err) => {
        if (cancelRequest) clearTimeout(requestModalTimeout)
        if (!(err && err.constructor.name !== 'Cancel')) throw err
        throw err
      }).finally(() => {
        if (cancellable) {
          cancelRequest(apiKey, actionName, 'get', requestParams.metadata.uuid) // to clean the request from memory
          clearTimeout(requestModalTimeout) // clean the timeout from memory
          cancellable.$hideLoading() // hide the modal if necessary
        }
      })

    // If it has cache, store the result
    if (cache) {
      cacheStore[cacheStoreKey] = {
        result,
        expires: Date.now() + cache * 1000,
      }
    }

    return result
  }
  save = async ({
    obj = {}, placeholders, blocker, queryParams, disableAppendId,
  }) => {
    let url = buildURL(actionUrl, placeholders)
    if (!disableAppendId) {
      if (primaryKey && obj[primaryKey]) url += `@${obj[primaryKey]}`
      else if (obj.id) url += `@${obj.id}`
    }
    const requestParams = { params: queryParams, metadata: { blocker } }

    const bodyFormData = new FormData()
    if (obj instanceof Blob) {
      bodyFormData.append('file', obj)
      // eslint-disable-next-line no-param-reassign
      obj = bodyFormData
    }

    // if (isBinary) {
    //   requestParams.responseType = 'arraybuffer';
    //   requestParams.headers = { Accept: 'application/pdf' };
    // }
    let reqObj = {}
    const encodedObj = pyliantEncode(obj)
    if (target) reqObj[target] = encodedObj
    else reqObj = encodedObj
    const result = await (await HTTP_INSTANCES[apiKey]()).post(url, reqObj, requestParams)
      .then((data) => data.data)
      .catch((err) => { throw Error(err) })

    return result
  }
  del = async ({ id, placeholders, blocker }) => {
    let url = buildURL(actionUrl, placeholders)
    if (id) url += `@${id}`

    const result = await (await HTTP_INSTANCES[apiKey]()).delete(url, { metadata: { blocker } })
      .then((data) => data.data)

    return result
  }
  switch (workerType) {
    case 'get': return get
    case 'save': return save
    case 'delete': return del
    case 'getUrl': return async (placeholders) => `${(await settings.get()).apis.company}/${buildURL(actionUrl, placeholders)}`
    default: throw new Error(`Worker type ${workerType} not supported`)
  }
}

const service = (apis) => {
  const api = {}

  Object.keys(apis).forEach((apiKey) => {
    api[apiKey] = {}

    apis[apiKey].forEach((endpoint) => {
      const {
        name, url, cache, target, primaryKey,
      } = endpoint
      const workers = ['get', 'save', 'delete', 'getUrl']

      api[apiKey][name] = {}
      workers.forEach((workerType) => {
        if (!endpoint.restrictions || !endpoint.restrictions.contains(workerType)) {
          // to do: might be able to optimize better?
          // eslint-disable-next-line max-len
          api[apiKey][name][workerType] = setup(apiKey, name, url, workerType, cache, target, primaryKey)
        }
      })
    })
  })

  return api
}

export default service(ApiEndpoints.new_endpoints)
