<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.payments"
      :fields="fields"
      :show-empty="innerValue.payments && !innerValue.payments.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          :subscriptions="innerValue.subscriptions"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './PaymentEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      subscriptions: [],
      fields: [
        {
          key: 'subscription_id',
          label: this.$gettext('subscription'),
          // eslint-disable-next-line max-len
          formatter: (value) => (this.innerValue.subscriptions.find((el) => el.id === value).payment_plan.name),
        },
        {
          key: 'amount',
          label: this.$gettext('amount'),
        },
        {
          key: 'currency_code',
          label: this.$gettext('currency code'),
        },
        {
          key: 'date',
          label: this.$gettext('date'),
          formatter: (value) => this.$options.filters.date(value),
        },
        { key: 'buttons', label: '' },
      ],
      objIndex: null,
      obj: {},
    }
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (this.innerValue.subscriptions.length) {
        if (index !== undefined) this.obj = cloneDeep(this.innerValue.payments[index])
        else this.obj = {}
        this.$refs.modal.show()
      } else {
        this.$toast({
          title: this.$gettext('warning'),
          message: this.$gettext('can\'t create payments without added subscriptions'),
          variant: 'danger',
        })
      }
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.payments.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.payments.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.payments.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
