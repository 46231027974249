// Init of validation plugin
/* eslint-disable max-len */

import {
  // eslint-disable-next-line camelcase
  required, regex, email, min_value, max_value, max, min, length, alpha,
} from 'vee-validate/dist/rules' // eslint-disable-line camelcase
import moment from 'moment'
import { extend, localize } from 'vee-validate'

const setup = ({ $gettext, $language, $gettextInterpolate }) => {
  localize($language.current.split('_')[0]) // select translation language

  // DEFAULT RULES
  extend('required', {
    ...required,
    message: $gettext('field is required'),
  })
  extend('regex', {
    ...regex,
    message: $gettext('field is invalid'),
  })
  extend('email', {
    ...email,
    message: $gettext('field is not a valid email'),
  })
  extend('json', {
    validate(value) {
      if (value) {
        try {
          JSON.parse(value)
        } catch (_) {
          return false
        }
      }
      return true
    },
    message: $gettext('field is not a valid json'),
  })
  extend('min_value', {
    // eslint-disable-next-line camelcase
    ...min_value,
    message: (_, placeholders) => {
      const str = $gettext('field must be %{min} or more')
      return $gettextInterpolate(str, {
        min: placeholders.min,
      })
    },
  })
  extend('max_value', {
    // eslint-disable-next-line camelcase
    ...max_value,
    message: (_, placeholders) => {
      const str = $gettext('field must be %{max} or less')
      return $gettextInterpolate(str, {
        max: placeholders.max,
      })
    },
  }) // eslint-disable-line camelcase
  extend('min', {
    ...min,
    message: (_, placeholders) => {
      const str = $gettext('field must have %{length} or more characters')
      return $gettextInterpolate(str, {
        length: placeholders.length,
      })
    },
  })
  extend('max', {
    ...max,
    message: (_, placeholders) => {
      const str = $gettext('field must have %{length} or less characters')
      return $gettextInterpolate(str, {
        length: placeholders.length,
      })
    },
  })
  extend('length', {
    ...length,
    message: (_, placeholders) => {
      const str = $gettext('field must have %{length} characters')
      return $gettextInterpolate(str, {
        length: placeholders.length,
      })
    },
  })

  extend('min_array', {
    params: ['limit'],
    validate(value, { limit }) {
      if (!value || !Array.isArray(value)) return false
      if (limit && value.length < limit) return false
      return true
    },
    message: (_, placeholders) => {
      const str = $gettext('field must have %{limit} values or more')
      return $gettextInterpolate(str, {
        limit: placeholders.limit,
      })
    },
  })

  extend('max_array', {
    params: ['limit'],
    validate(value, { limit }) {
      if (!value || !Array.isArray(value)) return false
      if (limit && value.length > limit) return false
      return true
    },
    message: (_, placeholders) => {
      const str = $gettext('field must have %{limit} values or less')
      return $gettextInterpolate(str, {
        limit: placeholders.limit,
      })
    },
  })

  // CUSTOM RULES
  // IP ADDRESS
  extend('ip', {
    validate(value) {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/((0|1|2|3(?=1|2))\d|\d))?$/.test(value)
    },
    message: () => $gettext('field is not a valid ip'),
  })

  // DATE
  extend('date', {
    params: ['limit'],
    validate(value) {
      if (!value) return true
      const mVal = moment(value, 'YYYY-MM-DD', true)
      if (!mVal.isValid()) return false
      return true
    },
    message: () => $gettext('field is not a valid date'),
  })

  // DATETIME
  extend('datetime', {
    params: ['limit'],
    validate(value) {
      if (!value) return true
      const mVal = moment(value, 'YYYY-MM-DDTHH:mm:ss', true)
      if (!mVal.isValid()) return false
      return true
    },
    message: () => $gettext('field is not a valid datetime'),
  })

  extend('min_date', {
    params: ['limit', 'time'],
    validate(value, { limit }) {
      const mVal = moment(value)
      if (!mVal.isValid()) return false
      if (limit && moment(limit).isAfter(mVal)) return false
      return true
    },
    message: (_, placeholders) => {
      const str = $gettext('field must be %{date} or more')
      return $gettextInterpolate(str, {
        date: moment(placeholders.limit).format(placeholders.time ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'),
      })
    },
  })

  extend('max_date', {
    params: ['limit'],
    validate(value, { limit }) {
      const mVal = moment(value)
      if (!mVal.isValid()) return false
      if (limit && moment(limit).isBefore(mVal)) return false
      return true
    },
    message: (_, placeholders) => {
      const str = $gettext('field must be %{date} or less')
      return $gettextInterpolate(str, {
        date: moment(placeholders.limit).format(placeholders.time ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'),
      })
    },
  })

  // CONFIRM PASSWORD
  extend('confirmPassword', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    },
    message: $gettext('password confirmation does not match'),
  })

  extend('decimal', {
    params: ['number'],
    validate(value, { number }) {
      const valueSplit = value.toString().split('.')
      if (valueSplit.length === 2) {
        return valueSplit[1].length <= +number
      } return true
    },
    message: $gettext('field is not a valid number'),
  })

  extend('alpha', {
    ...alpha,
    message: $gettext('field must contain only alphabetic characters'),
  })
}

export default setup
