<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="4" md="2">
        <custom-input
          v-model="innerValue.title"
          :name="$gettext('title')"
          rules="required"
          component="select"
          labelKey="text"
          :options="titles"
          :reduce="obj => obj.value"
          :clearable="false"
        />
      </b-col>
      <b-col sm="4" md="5">
        <custom-input
          v-model="innerValue.first_name"
          :name="$gettext('first name')"
          rules="required"
        />
      </b-col>
      <b-col sm="4" md="5">
        <custom-input
          v-model="innerValue.last_name"
          :name="$gettext('last name')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.username"
          :name="$gettext('username')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.password"
          type="password"
          :name="$gettext('password')"
          :rules="innerValue.id < 0 ? 'required' : ''"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.office_id"
          :options="offices"
          labelKey="text"
          component="select"
          :reduce="obj => obj.value"
          :name="$gettext('office')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.booking_default_brand_id"
          :name="$gettext('default brand')"
          component="select"
          :options="brandOptions"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.email"
          :name="$gettext('email')"
          rules="email"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.personal_no"
          :name="$gettext('personal no')"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.id_card"
          :name="$gettext('id card')"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.active"
          :name="$gettext('active')"
          component="checkbox"
        />
      </b-col>
      <b-col cols="12">
        <hr/>
      </b-col>
      <logo-picker
        :mimeType="innerValue.avatar_mime"
        @setMimeType="innerValue.avatar_mime = $event"
        v-model="innerValue.avatar_blob"
        :previewUrl="innerValue.id ? `${companyUrl}/bin/user_avatar/${innerValue.id}` : null"
        :title="$gettext('choose avatar')"/>
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.restrict_to_own"
          :name="$gettext('restrict to own bookings')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.restrict_to_branch"
          :name="$gettext('restrict to branch bookings')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.can_add_invoices"
          :name="$gettext('can add invoices')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.is_super"
          :name="$gettext('is admin')"
          component="checkbox"
          noLabel
        />
      </b-col>
      <b-col sm="12">
        <h4 v-translate>allowed ip list</h4>
        <b-btn size="sm" @click="addIp" variant="primary">
          <fa-icon icon="plus" />
        </b-btn>
        <hr />
        <!-- IPS -->
        <b-row
          v-for="(item, index) in innerValue.ips"
          :key="index"
          class="d-flex align-items-center"
        >
          <b-col cols="1" class="text-center">
            {{ index + 1 }}
          </b-col>
          <b-col cols="9">
            <custom-input
              :name="$gettext('ip')"
              rules="required|ip"
              v-model="innerValue.ips[index]"
            />
          </b-col>
          <b-col cols="2">
            <b-btn variant="danger" size="sm" @click="deleteIp(index)" class="float-right">
              <fa-icon icon="times" />
            </b-btn>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'
import LogoPicker from '@/shared/components/others/LogoPicker.vue'

export default {
  mixins: [vModelMixin],
  props: ['paxTitles', 'companyOffices', 'companyBrands'],
  components: {
    'logo-picker': LogoPicker,
  },
  data() {
    return {
      titles: [{ value: null, text: this.$gettext('select') }],
      offices: [{ value: null, text: this.$gettext('please select') }],
      companyUrl: null,
    }
  },
  computed: {
    brandOptions() {
      return [
        { text: this.$gettext('please select'), value: null },
        ...this.companyBrands
          .filter((brand) => !!brand.id)
          .map((brand) => ({ text: brand.name, value: brand.id }))
          .sort((a, b) => {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          }),
      ]
    },
  },
  methods: {
    save() {
      this.$emit('save')
    },
    addIp() {
      this.innerValue.ips.push('')
    },
    deleteIp(index) {
      this.innerValue.ips.splice(index, 1)
    },
  },
  async created() {
    this.companyUrl = (await this.$root.settings).apis.company

    this.paxTitles.forEach((title) => {
      this.titles.push({ value: title.label, text: title.label })
    })
    this.companyOffices.map((office) => {
      this.offices.push({ value: office.id, text: office.name })
    })
  },
}
</script>
