import { $gettext } from '@/shared/services/translate-dummy'

export default [
  {
    name: $gettext('dashboard'),
    routeName: 'Dashboard',
    icon: 'home',
    color: 'text-blue',
  },
  {
    name: $gettext('companies'),
    routeName: 'Company',
    icon: 'industry',
    color: 'text-orange',
  },
  {
    name: $gettext('settings'),
    routeName: 'Settings',
    url: '/settings',
    icon: 'tools',
    color: 'text-yellow',
    children: [
      {
        name: $gettext('countries'),
        routeName: 'countries',
        url: '/settings/countries',
        icon: 'globe-europe',
      },
      {
        name: $gettext('country subdivisions'),
        routeName: 'country subdivisions',
        url: '/settings/country-subdivisions',
        icon: 'globe',
      },
      {
        name: $gettext('cities'),
        routeName: 'cities',
        url: '/settings/cities',
        icon: 'city',
      },
      {
        name: $gettext('city areas'),
        routeName: 'city areas',
        url: '/settings/city-areas',
        icon: 'building',
      },
      {
        name: $gettext('booking types'),
        routeName: 'booking types',
        url: '/settings/booking-types',
        icon: 'list',
      },
      {
        name: $gettext('currencies'),
        routeName: 'currencies',
        url: '/settings/currencies',
        icon: 'money-bill',
      },
      {
        name: $gettext('etrip suppliers'),
        routeName: 'etrip suppliers',
        url: '/settings/etrip-suppliers',
        icon: 'parachute-box',
      },
      {
        name: $gettext('airlines'),
        routeName: 'airlines',
        url: '/settings/airlines',
        icon: 'plane',
      },
      {
        name: $gettext('airports'),
        routeName: 'airports',
        url: '/settings/airports',
        icon: 'plane-departure',
      },
      {
        name: $gettext('users'),
        routeName: 'admin users',
        url: '/settings/users',
        icon: 'users',
      },
      {
        name: $gettext('messages'),
        routeName: 'messages',
        url: '/settings/messages',
        icon: 'envelope',
      },
      {
        name: $gettext('payment plans'),
        routeName: 'payment plans',
        url: '/settings/payment-plans',
        icon: 'coins',
      },
      {
        name: $gettext('import'),
        routeName: 'imports',
        url: '/settings/imports',
        icon: 'upload',
      },
    ],
  },
  {
    name: $gettext('reports'),
    routeName: 'Report',
    url: '/reports',
    icon: 'chart-pie',
    color: 'text-green',
    children: [
      {
        name: $gettext('certificates'),
        routeName: 'certificates',
        url: '/reports/certificates',
        icon: 'certificate',
      },
      {
        name: $gettext('atol'),
        routeName: 'admin atol report',
        url: '/reports/atol',
        icon: 'plane',
      },
      {
        name: $gettext('protection cover'),
        routeName: 'admin protection cover',
        url: '/reports/protection-cover',
        icon: 'umbrella',
      },
    ],
  },
]
