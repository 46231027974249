var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"mb-0",attrs:{"striped":"","small":"","hover":"","responsive":"","no-sort-reset":"","items":_vm.results,"fields":_vm.fields,"no-local-sorting":"","show-empty":!_vm.results.length,"empty-text":_vm.$gettext('no results found'),"sticky-header":"80vh"},on:{"sort-changed":_vm.sortChanged},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.pageIndex + index + 1)+" ")]}},{key:"cell(country_subdivision)",fn:function(ref){
var item = ref.item;
return [(item.subdivision.name !== '_default')?[_vm._v(" "+_vm._s(item.subdivision.name)+" ")]:[_vm._v(" n/a ")]]}},{key:"cell(country)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subdivision.country.local_name)+" ")]}},{key:"cell(buttons)",fn:function(ref){
var item = ref.item;
return [_c('edit-delete-buttons',{on:{"edit":function($event){return _vm.edit(item.id)},"remove":function($event){return _vm.remove(item.id)}}},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"variant":"secondary","title":_vm.$gettext('view city areas')},on:{"click":function($event){return _vm.$router.push({ name: 'city areas', query: { city_id: item.id } })}}},[_c('fa-icon',{attrs:{"icon":"list-alt"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }