import { $gettext } from '@/shared/services/translate-dummy'

import Dashboard from './dashboard/Dashboard.vue'
import Company from './companies/View.vue'
import CompanyEdit from './companies/Edit.vue'
import SettingsRoutes from './settings/router'
import ReportsRoutes from './reports/router'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      label: $gettext('dashboard'),
    },
  },
  {
    path: '/companies',
    name: 'Company',
    component: Company,
    meta: {
      label: $gettext('company'),
      registerQueryParams: true,
    },
  },
  {
    path: '/companies/edit/:companyCode',
    name: 'Company Edit',
    component: CompanyEdit,
    meta: {
      label: $gettext('company edit'),
    },
  },
  {
    path: '/companies/add',
    name: 'Company Add',
    component: CompanyEdit,
    meta: {
      label: $gettext('company add'),
    },
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: '/settings/countries',
    component: { render(c) { return c('router-view') } },
    children: SettingsRoutes,
    meta: {
      label: $gettext('settings'),
    },
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: '/reports/certificates',
    component: { render(c) { return c('router-view') } },
    children: ReportsRoutes,
    meta: {
      label: $gettext('reports'),
    },
  },
]
