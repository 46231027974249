<template>
  <b-row>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.label"
        :name="$gettext('label')"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
}
</script>
