import AGENCY_URLS from './agency'
import COMPANY_ULRS from './company'

export default {
  old_endpoints: { // old python arhitecture
    agency: AGENCY_URLS,
  },
  new_endpoints: {
    company: COMPANY_ULRS,
  },
}
