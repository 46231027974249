import cloneDeep from 'lodash/cloneDeep'
import { defaultStyles } from './pdf-styles'

const renderReport = ({
  tables, extra, orientation, market,
}) => {
  // extraInfo is an object which can have a few properties: headerRows, footerRows, top, bottom
  function rowProcess(tbl, row, isHeader) {
    const trow = []
    row.forEach((element) => {
      let el // original element value
      let customAlignment // element alingment

      // Check if element is object type with its own settings
      if (element !== null && element !== undefined) {
        if (typeof element !== 'object') {
          el = element
        } else {
          el = element.text ? element.text : ''

          if (element.alignment) customAlignment = element.alignment
        }
      } else {
        el = ''
      }

      const cell = {
        // eslint-disable-next-line prefer-template
        text: '' + el, // text always as string
        style: isHeader ? 'reportHeader' : 'default',
      }
      if (!isHeader) {
        if (!Number.isNaN(el) || (typeof el === 'string' && el.match(/^-?[0-9,.]+$/))) cell.alignment = customAlignment || 'right'
        if (typeof el === 'string' && el.match(/^[A-Z]{3}-?[0-9,.]+$/)) {
          cell.alignment = customAlignment || 'right'
          cell.text = []
          cell.text.push({ text: el.substring(0, 3), fontSize: 5 })
          cell.text.push(el.substring(3))
        }
      }
      trow.push(cell)
    })
    tbl.table.body.push(trow)
  }

  const dd = {}
  dd.pageMargins = [20, 20, 20, 20]
  dd.pageSize = 'A4'
  dd.pageOrientation = orientation
  dd.styles = cloneDeep(defaultStyles)
  dd.content = []
  dd.content = {
    stack: [],
    style: 'default',
  }

  if (extra && extra.top) dd.content.stack.push(extra.top)

  tables.forEach((data) => {
    let headerRows = []
    if (data.headerRows) headerRows = data.headerRows
    let footerRows = []
    if (data.footerRows) footerRows = data.footerRows

    const table = {
      table: {
        headerRows: headerRows.length,
        body: [],
      },
      margin: [2, 10, 2, 10],
      layout: 'lightHorizontalLines',
    }

    headerRows.forEach((row) => { rowProcess(table, row, true) })

    data.table.forEach((row) => { rowProcess(table, row, false) })

    footerRows.forEach((row) => { rowProcess(table, row, true) })

    // widths
    if (data.applyWidth) table.table.widths = data.table[0].map(() => 'auto')

    dd.content.stack.push(cloneDeep(table))
  })

  if (extra && extra.bottom) dd.content.stack.push(extra.bottom)

  dd.footer = (currentPage, pageCount) => {
    const Page = market === 'RO' ? 'Pagina ' : ' Page '
    const Of = market === 'RO' ? ' din ' : ' of '
    return { text: Page + currentPage + Of + pageCount, alignment: 'center', style: 'default' }
  }
  return dd
}

export default renderReport
