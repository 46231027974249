<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col cols="12">
        <custom-input
          v-model="innerValue.label"
          :name="$gettext('label')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="4">
        <label class="col-form-label-sm">
          <translate>allowed item types</translate>:
        </label>
        <b-checkbox
          v-for="(item, index) in itemTypeOptions"
          :key="index"
          size="sm"
          v-model="innerValue.allowed_items"
          :value="item.value"
        >
          {{ item.label }}
        </b-checkbox>
      </b-col>
      <b-col cols="12" md="4">
        <label class="col-form-label-sm">
          <translate>default item types</translate>:
        </label>
        <b-checkbox
          v-for="(item, index) in itemTypeOptions"
          :key="index"
          size="sm"
          v-model="innerValue.default_items"
          :value="item.value"
        >
          {{ item.label }}
        </b-checkbox>
      </b-col>
      <b-col cols="12" md="4">
        <label class="col-form-label-sm">
          <translate>default certificate types</translate>:
        </label>
        <b-checkbox
          v-for="(item, index) in certificateOptions"
          :key="index"
          size="sm"
          v-model="innerValue.allowed_certificates"
          :value="item.value"
        >
          {{ item.label }}
        </b-checkbox>
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      itemTypeOptions: [
        { value: 'flight', label: this.$gettext('flight') },
        { value: 'hotel', label: this.$gettext('hotel') },
        { value: 'package', label: this.$gettext('package') },
        { value: 'transfer', label: this.$gettext('transfer') },
        { value: 'car_rental', label: this.$gettext('car rental') },
        { value: 'insurance', label: this.$gettext('insurance') },
        { value: 'cruise', label: this.$gettext('cruise') },
        { value: 'other', label: this.$pgettext('other product', 'other') },
        { value: 'mondial_insurance', label: this.$gettext('mondial insurance') },
        { value: 'etrip_package', label: this.$gettext('etrip package') },
        { value: 'etrip_hotel', label: this.$gettext('etrip hotel') },
        { value: 'etrip_flight', label: this.$gettext('etrip flight') },
        { value: 'tourico_hotel', label: this.$gettext('tourico hotel') },
        { value: 'tourico_car', label: this.$gettext('tourico car') },
      ],
      certificateOptions: [
        { value: 'flight_only', label: this.$gettext('flight only') },
        { value: 'flight_plus', label: this.$gettext('flight plus') },
        { value: 'package', label: this.$gettext('package') },
      ],
    }
  },
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
