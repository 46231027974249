import Vue from 'vue'

import DateInput from './controls/DateInput.vue'
import ModalButtons from './buttons/ModalButtons.vue'
import EditDeleteButtons from './buttons/EditDeleteButtons.vue'

import CustomInput from './controls/CustomInput.vue'

Vue.component('custom-input', CustomInput)

Vue.component('date-input', DateInput)

Vue.component('modal-buttons', ModalButtons)
Vue.component('edit-delete-buttons', EditDeleteButtons)
