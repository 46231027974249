<template>
  <div>
    <div class="d-flex justify-content-end my-2">
      <b-btn
        variant="outline-primary"
        size="sm"
        @click="edit()">
        <fa-icon icon="plus"/>
        <translate>add note</translate>
      </b-btn>
    </div>
    <b-list-group>
      <b-list-group-item v-for="(note, index) in innerValue.notes" :key="index">
        <strong>{{ note.username }}</strong>
        @ {{ new Date(note.added) | date('DD-MM-YYYY HH:mm') }}:
        {{ note.text }}
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './NoteEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  data() {
    return {
      objIndex: null,
      obj: {},
    }
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (index !== undefined) this.obj = cloneDeep(this.innerValue.notes[index])
      else {
        this.obj = {
          company_code: this.$route.params.companyCode,
          added: new Date(),
        }
      }
      this.$refs.modal.show()
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.notes.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      this.obj.username = this.$root.userData.username
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.notes.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.notes.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
