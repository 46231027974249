<template>
  <b-row>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.name_like"
        :name="$gettext('name')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.local_name_like"
        :name="$gettext('local name')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.iata_value"
        :name="$gettext('iata value')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.iata_region_code"
        :name="$gettext('iata region code')"
        :options="regionOptions"
        component="select"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.is_eu"
        :name="$gettext('is eu')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.active"
        :name="$gettext('active')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      regionOptions: [
        { value: null, text: '--select--' },
        { value: 'AFR', text: 'Africa' },
        { value: 'CAR', text: 'Caribbean sea countries' },
        { value: 'CEM', text: 'Central America' },
        { value: 'EUR', text: 'Europe' },
        { value: 'JAK', text: 'Japan and Korea' },
        { value: 'MDE', text: 'Middle East' },
        { value: 'NOA', text: 'North America' },
        { value: 'SCH', text: 'Schengen agreement countries' },
        { value: 'SOA', text: 'South America' },
        { value: 'SAS', text: 'South Asia' },
        { value: 'SEA', text: 'South-Eastern Asia' },
        { value: 'SWP', text: 'South-West Pacific' },
        { value: 'TC1', text: 'IATA American Traffic Conference (includes NOA, CEM, SOA and CAR)' },
        { value: 'TC3', text: 'IATA Asian Traffic Conference (includes JAK, SAS, SEA, SWP)' },
        { value: 'TC2', text: 'IATA European and African Traffic Conference (includes AFR, EUR, MDE)' },
      ],
      yesNoOptions: [
        { value: null, text: this.$gettext('all') },
        { value: 'yes', text: this.$gettext('yes') },
        { value: 'no', text: this.$pgettext('yes/no', 'no') },
      ],
    }
  },
}
</script>
