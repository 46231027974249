import Vue from 'vue'
import decimal from './decimal'
import toFixed from './toFixed'
import date from './date'
import datetime from './datetime'

Vue.filter('decimal', decimal)
Vue.filter('toFixed', toFixed)
Vue.filter('date', date)
Vue.filter('datetime', datetime)
