// Confirm modal mixin

export default {
  data() {
    return {
      confirmProps: {
        message: this.$gettext('are you sure?'),
        title: this.$gettext('delete'),
        okTitle: this.$gettext('yes'),
        cancelTitle: this.$gettext('no'),
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
      },
    }
  },
  methods: {
    $confirmModal(cp) {
      this.confirmProps = {
        ...this.confirmProps,
        ...cp,
      }
      return this.$bvModal.msgBoxConfirm(this.confirmProps.message, {
        title: this.confirmProps.title,
        okTitle: this.confirmProps.okTitle,
        cancelTitle: this.confirmProps.cancelTitle,
        noCloseOnBackdrop: this.confirmProps.noCloseOnBackdrop,
        noCloseOnEsc: this.confirmProps.noCloseOnEsc,
        okVariant: this.confirmProps.okVariant || 'danger',
        cancelVariant: this.confirmProps.cancelVariant || 'success',
        size: 'sm',
        buttonSize: 'sm',
        footerClass: 'btn-group w-50 align-self-end',
      })
    },
  },
}
