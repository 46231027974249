<template>
  <b-row>
    <b-col cols="12" md="4">
      <custom-input
        v-model="innerValue.name"
        :name="$gettext('name')"
      />
    </b-col>
    <b-col cols="12" md="3">
      <custom-input
        v-model="innerValue.public"
        :name="$gettext('is public')"
        :options="yesNoOptions"
        component="select"
      />
    </b-col>
  </b-row>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      yesNoOptions: [
        { value: null, text: this.$gettext('all') },
        { value: true, text: this.$gettext('yes') },
        { value: false, text: this.$pgettext('yes/no', 'no') },
      ],
    }
  },
}
</script>
