<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.users"
      :fields="fields"
      :show-empty="innerValue.users && !innerValue.users.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:cell(name)="{ item }">
        <span
          :class="{
          'text-red': item.is_super,
          'text-gray': !item.active
          }">
        {{ item.title }} <b>{{ `${item.first_name} ${item.last_name}` }}</b>
        </span>
      </template>
      <template v-slot:cell(username)="{ item }">
        <span
          :class="{
          'text-red': item.is_super,
          'text-gray': !item.active
          }">
          {{ item.username }}
        </span>
      </template>
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(buttons)="{ index, item }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        >
          <b-btn
            @click="impersonate(item.id)"
            variant="success"
          >
            <icon icon="user-alt"/><translate>impersonate</translate>
          </b-btn>
        </edit-delete-buttons>
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          :companyOffices="innerValue.offices"
          :companyBrands="innerValue.brands"
          :paxTitles="paxTitles"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './UserEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  props: ['paxTitles'],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        { key: 'name', label: this.$gettext('name') },
        { key: 'username', label: this.$gettext('username') },
        { key: 'buttons', label: '' },
      ],
      obj: {},
      objIndex: null,
      crudEndpoint: 'impersonate',
    }
  },
  methods: {
    async impersonate(id) {
      const agencyUrl = (await this.$root.settings).agencyUrl
      this.$newCrud.company[this.crudEndpoint].save({
        obj: {
          user_id: id,
        },
        blocker: true,
        cancellable: this,
      }).then((data) => {
        window.open(`${agencyUrl}?token=${data.token}`, 'blank')
      })
    },
    edit(index) {
      if (this.innerValue.offices.length) {
        this.objIndex = index
        if (index !== undefined) {
          this.obj = cloneDeep(this.innerValue.users[index])
          if (!this.obj.ips) this.obj.ips = []
        } else {
          this.obj = {
            type: 'company',
            title: null,
            office_id: null,
            avatar: null,
            active: true,
            ips: [],
          }
        }
        this.$refs.modal.show()
      } else {
        this.$toast({
          title: this.$gettext('warning'),
          message: this.$gettext('can\'t create users without added offices'),
          variant: 'danger',
        })
      }
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.users.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.users.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.users.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
