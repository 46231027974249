<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    button-size="sm"
    size="sm"
    :visible="visible"
    :lazy="true"
    :title="$gettext('loading')"
    @ok.prevent="save"
    @close="$hideLoading()"
  >
    <b-container fluid class="text-center">
      <div v-if="text">{{ text }}</div>
      <div v-else v-translate>
        Loading
      </div>
      <br />
      <b-spinner variant="primary"></b-spinner>
    </b-container>
    <template v-slot:modal-footer>
      <b-btn size="sm" type="button" @click="$hideLoading()" class="mr-2" variant="outline-dark">
        <icon icon="times" /><translate>cancel</translate>
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      text: null,
      visible: false,
    }
  },
}
</script>
