<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('name')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.is_head_office"
          :name="$gettext('office type')"
          :options="officeTypeOptions"
          component="radio"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.address1"
          :name="`${$gettext('address line')} 1`"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.address2"
          :name="`${$gettext('address line')} 2`"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.city_name"
          :name="$gettext('city')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :reduce="obj => obj.name"
          :requestParams="{ types: 'T', size: 20 }"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.post_code"
          :name="$gettext('post code')"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.country_name"
          :name="$gettext('country')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :reduce="obj => obj.name"
          :requestParams="{ types: 'C', size: 20 }"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.subdivision_name"
          :name="$gettext('county')"
          component="select"
          apiKey="agency|commonGeography"
          labelKey="name"
          :reduce="obj => obj.name"
          :requestParams="{ types: 'S', size: 20 }"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.tel"
          :name="$gettext('tel')"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.fax"
          :name="$gettext('fax')"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.email"
          :name="$gettext('email')"
          rules="email"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  data() {
    return {
      officeTypeOptions: [
        { text: this.$gettext('head office'), value: true },
        { text: this.$gettext('secondary office'), value: false },
      ],
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
