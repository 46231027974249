<template>
  <div>
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.bank_accounts"
      :fields="fields"
      :show-empty="innerValue.bank_accounts && !innerValue.bank_accounts.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          :saleCurrencies="saleCurrencies"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './BankAccountEdit.vue'

export default {
  mixins: [vModelMixin],
  components: {
    Form,
  },
  props: ['saleCurrencies'],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        { key: 'currency_code', label: this.$gettext('currency') },
        { key: 'account_number', label: this.$gettext('account number') },
        { key: 'bank_name', label: this.$gettext('bank name') },
        { key: 'branch', label: this.$gettext('branch') },
        { key: 'buttons', label: '' },
      ],
      objIndex: null,
      obj: {},
    }
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (this.saleCurrencies[0].currency_code) {
        if (index !== undefined) {
          this.obj = cloneDeep(this.innerValue.bank_accounts[index])
        } else {
          this.obj = {
            currency_code: null,
          }
        }
        this.$refs.modal.show()
      } else {
        this.$toast({
          title: this.$gettext('warning'),
          message: this.$gettext('can\'t create bank accounts without added currencies in company details'),
          variant: 'danger',
        })
      }
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.bank_accounts.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.bank_accounts.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.bank_accounts.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
