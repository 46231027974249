// Dates filter

import moment from 'moment'

export default (value, format) => {
  const f = format || 'DD/MM/YYYY mm:HH'
  if (value) {
    return moment(String(value)).format(f)
  }
  return ''
}
