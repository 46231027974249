/* eslint-disable object-curly-newline */
// API endpoints
// EX: { name, url, cache, target, primaryKey
// TO DO: add include/exclude

export default [
  { name: 'company', url: 'company', target: 'company', primaryKey: 'code' },
  { name: 'auth', url: 'auth/login', target: null },
  { name: 'logout', url: 'auth/logout', target: null },
  { name: 'whoAmI', url: 'auth/whoami', target: null },
  { name: 'impersonate', url: 'auth/impersonate' },
  { name: 'user', url: 'user', target: 'user' },
  { name: 'systemUser', url: 'user!system', target: 'user' },
  { name: 'office', url: 'office', target: 'office' },
  { name: 'brand', url: 'brand', target: 'brand' },
  { name: 'bankAccount', url: 'bank_account', target: 'bank_account' },
  { name: 'paymentPlan', url: 'payment_plan', target: 'payment_plan' },
  { name: 'invoiceRule', url: 'invoice_rule', target: 'invoice_rule' },
]
