import axios from 'axios'
import settings from '@/shared/settings'

const pendingRequests = {
  count: 0,
  active: false,
  cancellable: {},
}

const instances = {
  agency: null,
  company: null,
}

const HTTP_INSTANCES = {
  agency: async () => {
    if (!instances.agency) {
      const http = axios.create({
        baseURL: (await settings.get()).apis.agency,
        withCredentials: true,
      })
      http.pendingRequests = pendingRequests
      instances.agency = http

      return http
    }
    return instances.agency
  },
  company: async () => {
    if (!instances.company) {
      const http = axios.create({
        baseURL: (await settings.get()).apis.company,
        withCredentials: true,
      })
      http.pendingRequests = pendingRequests
      instances.company = http

      return http
    }
    return instances.company
  },
}

export {
  HTTP_INSTANCES,
  pendingRequests,
}
