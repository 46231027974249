import axios from 'axios'

const state = {
  isLoaded: false,
  data: {},
}

const instance = {
  get() {
    if (state.isLoaded) {
      return new Promise((resolve) => {
        resolve(state.data)
      })
    }
    return this.load()
  },
  async load() {
    const data = await axios.get('/static/settings.json')
    state.data = data.data
    state.isLoaded = true
    return state.data
  },
}

export default instance
