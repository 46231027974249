<template>
  <div class="only-content">
    <b-col sm="6">
      <h5>{{ title ? title : $gettext('logo') }}</h5>
      <vue-cropper
          v-show="logoSrc"
          ref="logoCropper"
          :src="logoSrc"
          class="mb-3"
        >
      </vue-cropper>
      <input
        v-show="false"
        ref="logoInput"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage($event)"
      />
      <b-btn
        size="sm"
        variant="primary"
        @click.prevent="showFileChooser()"
        v-translate
      >
        upload image
      </b-btn>
      <b-btn
        v-if="logoSrc"
        size="sm"
        variant="secondary"
        @click.prevent="zoom(0.2)"
        v-translate
      >
        zoom in
      </b-btn>
      <b-btn
        v-if="logoSrc"
        size="sm"
        variant="secondary"
        @click.prevent="zoom(-0.2)"
        v-translate
      >
        zoom out
      </b-btn>

      <b-btn
        v-if="logoSrc"
        size="sm"
        variant="primary"
        @click.prevent="cropImage"
        class="float-right"
        v-translate
      >
        set
      </b-btn>
    </b-col>
    <b-col class="text-center" sm="6">
      <h5 class="float-left" v-translate>preview</h5>
      <img class="img-fluid mb-3" v-if="mimeType && !innerValue" :src="previewUrl" />
      <img
        v-if="mimeType && innerValue"
        class="img-fluid mb-3"
        :src="!modelIncludesMime ? 'data:'+mimeType+';base64,'+innerValue : innerValue">
      <b-btn
          v-if="mimeType"
          class="float-right"
          size="sm"
          variant="danger"
          @click.prevent="$emit('setMimeType', null); $emit('input', null);"
          v-translate
        >
        delete
      </b-btn>
    </b-col>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  components: { VueCropper },
  created() {
    if (this.value !== undefined) {
      this.innerValue = this.value
    }
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    value: {},
    previewUrl: {
      type: String,
    },
    mimeType: {
      type: String,
    },
    modelIncludesMime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: null,
      logoSrc: '',
    }
  },
  methods: {
    showFileChooser() {
      this.$refs.logoInput.click()
    },
    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        this.$toast({
          title: this.$gettext('file'),
          message: this.$gettext('please select an image file'),
          variant: 'danger',
        })
        return
      }

      const sizeLimitBytes = 4194304
      if (file.size >= sizeLimitBytes) {
        const humanFileSize = (size) => {
          const localSize = Math.abs(size)
          const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
          return `${(localSize / (1024 ** i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
        }
        this.$toast({
          title: this.$gettext('file'),
          message: `${this.$gettext('file size should not exceed')} ${humanFileSize(sizeLimitBytes)}`,
          variant: 'danger',
        })
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.logoSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.logoCropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        this.$toast({
          title: this.$gettext('file'),
          message: this.$gettext('sorry, filereader api not supported'),
          variant: 'danger',
        })
      }
    },
    zoom(percent) {
      this.$refs.logoCropper.relativeZoom(percent)
    },
    cropImage() {
      const img = this.$refs.logoCropper.getCroppedCanvas().toDataURL()
      const imgData = img.split(';base64,')

      this.$emit('setMimeType', imgData[0].split(':')[1])
      if (this.modelIncludesMime) {
        this.$emit('input', img)
      } else {
        this.$emit('input', imgData[1])
      }
    },
  },
}
</script>
