<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.is_one_off"
          :name="$gettext('payment type')"
          component="radio"
          :options="paymentTypeOptions"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.first_invoice_date"
          :name="$gettext('first invoice date')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.amount"
          :name="$gettext('amount')"
          component="number"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.currency_code"
          :name="$gettext('currency')"
          rules="required"
          component="select"
          :options="currencies"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.vat_rate_code"
          :name="$gettext('vat rate')"
          rules="required"
          component="select"
          :options="vatRateOptions"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.acc_product_code"
          :name="$gettext('accounting product')"
          rules="required"
          component="select"
          :options="accountingProductOptions"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.contract_no"
          :name="$gettext('contract number')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.contract_date"
          :name="$gettext('contract date')"
          rules="required"
          component="date"
        />
      </b-col>
      <b-col cols="12">
        <custom-input
          v-model="innerValue.description"
          :name="$gettext('description')"
          component="textarea"
          :rows="4"
        />
      </b-col>
      <b-col cols="12" v-if="!innerValue.is_one_off">
        <hr/>
        <b-row>
          <b-col cols="6" sm="3">
            <custom-input
              v-model="innerValue.intervalCount"
              :name="$gettext('interval')"
              component="number"
              rules="required|min_value:1"
            />
          </b-col>
          <b-col cols="6" sm="3">
            <custom-input
              v-model="innerValue.intervalUnit"
              :name="$gettext('interval unit')"
              component="select"
              rules="required"
              :options="unitsOfTime"
            />
          </b-col>
          <b-col sm="6">
            <custom-input
              v-model="innerValue.due_date_days"
              :name="$gettext('days until due date')"
              component="number"
              rules="required|min_value:0"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  props: ['vatRateOptions', 'accountingProductOptions', 'currencies', 'unitsOfTime', 'paymentTypeOptions'],
  created() {
    if (this.innerValue.interval) {
      const auxStr = this.innerValue.interval.split('P')[1]
      this.innerValue.intervalCount = Number(auxStr.substr(0, auxStr.length - 1))
      this.innerValue.intervalUnit = auxStr[auxStr.length - 1]
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
