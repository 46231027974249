<template>
  <div class="mb-5" v-if="filteredResults.length">
    <div v-for="(company, companyIndex) in filteredResults" :key="companyIndex">
      <h3 class="mb-0">
        <translate>results for</translate> {{ company.company_name }}
      </h3>
      <b-card
        border-variant="primary"
        header="Primary"
        header-bg-variant="primary"
        header-text-variant="white"
        header-tag="header"
      >
        <template v-slot:header>
          <h6 class="mb-0">
            <translate>bookings created in</translate> {{ filters.date | date('MM-YYYY') }}
          </h6>
        </template>
        <table class="table table-responsive">
          <thead>
            <th v-translate>type of booking</th>
            <th v-translate>total bookings</th>
            <th v-translate>total pax</th>
            <th v-translate>total revenue</th>
          </thead>
          <tbody>
            <tr
              v-for="(bookings, bookingsIndex) in company.created_month"
              :key="bookingsIndex"
            >
              <td class="no">
                {{ getType(bookings.booking_type_id) }}
              </td>
              <td>
                <b>{{ bookings.bookings }}</b>
              </td>
              <td>
                <b>{{ bookings.pax_count }}</b>
              </td>
              <td class="sum">
                <b>{{ bookings.total_price | decimal }}</b>
              </td>
            </tr>
            <tr class="highlighted">
              <td class="no">
                <translate>totals for bookings created in</translate>
                &nbsp;<b>{{ filters.date | date("MM-YYYY") }} </b>
              </td>
              <td>
                <b>
                  {{ getTotals(company.created_month, "bookings") }}
                </b>
              </td>
              <td>
                <b>
                  {{ getTotals(company.created_month, "pax_count") }}
                </b>
              </td>
              <td class="sum">
                <b>
                  {{
                    getTotals(company.created_month, "total_price") | decimal
                  }}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
      <div
        v-for="(month, res) in company.travelling_month_processed"
        :key="res"
      >
        <b-card
          border-variant="primary"
          header="Primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header-tag="header"
        >
          <template v-slot:header>
            <h6 class="mb-0">
              <translate>bookings travelling in</translate> {{ res | date("MM-YYYY") }}
            </h6>
          </template>
          <hr />
          <table class="table">
            <thead>
              <tr>
                <th v-translate>type of booking</th>
                <th v-translate>total bookings</th>
                <th v-translate>total pax</th>
                <th v-translate>total revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bookings, bookingRes) in month" :key="bookingRes">
                <td class="no">
                  {{ getType(bookings.booking_type_id) }}
                </td>
                <td>
                  <b>{{ bookings.bookings }}</b>
                </td>
                <td>
                  <b>{{ bookings.pax_count }}</b>
                </td>
                <td class="sum">
                  <b>{{ bookings.total_price }}</b>
                </td>
              </tr>
              <tr class="highlighted">
                <td class="no">
                  <translate>totals for bookings travelling in</translate>
                  &nbsp;<b>{{ res | date("MM-YYYY") }} </b>
                </td>
                <td>
                  <b> {{ getTotals(month, "bookings") }} </b>
                </td>
                <td>
                  <b> {{ getTotals(month, "pax_count") }} </b>
                </td>
                <td class="sum">
                  <b>
                    {{ getTotals(month, "total_price") | decimal }}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
    </div>
  </div>
  <div v-else class="text-center p-2">
    <h5 class="mb-0">
      {{ $gettext('no results found') }}
    </h5>
  </div>
</template>

<script>
export default {
  props: ['filters', 'bookingTypeOptions', 'filteredResults'],
  methods: {
    getType(id) {
      const found = this.bookingTypeOptions.find((el) => el.value === id)
      if (found) return found.text
      return 'n/a'
    },
    getTotals(obj, type) {
      let total = 0
      if (Array.isArray(obj)) {
        obj.forEach((el) => {
          const no = parseFloat(el[type])
          if (!Number.isNaN(no)) total += no
        })
      } else {
        const keys = Object.keys(obj)
        if (keys.length) {
          const no = parseFloat(obj[type])
          if (!Number.isNaN(no)) total += no
        }
      }
      return total
    },
  },
}
</script>
