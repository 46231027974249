// Disables buttons marked with 'blocker' while requests are pending

/* eslint-disable no-param-reassign */
export default {
  bind(el, binding, vnode) {
    /* Watch returns unwatch function, this watches if there are requests pending
    and updates the button style accordingly */
    const unwatch = vnode.context.$watch('$root.pendingRequestsObj.active', (newVal) => {
      el.disabled = newVal
      el.style.cursor = newVal ? 'not-allowed' : 'pointer'
    })
    // adds unwatch to be accessible on directive unbind function
    if (!vnode.context.disableRequestsUnwatch) {
      vnode.context.disableRequestsUnwatch = unwatch
    }
  },
  update(el, binding, newVNode, oldVNode) {
    /*
    There are updates that alters the vnode
    so in order to preserve the unwatch function we need this
    */
    if (oldVNode.context.disableRequestsUnwatch) {
      newVNode.context.disableRequestsUnwatch = oldVNode.context.disableRequestsUnwatch
    }
  },
  unbind(el, binding, vnode) {
    // unwatches the event
    if (vnode.context.disableRequestsUnwatch) {
      vnode.context.disableRequestsUnwatch()
      delete vnode.context.disableRequestsUnwatch
    }
  },
}
