<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
       <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.iata"
          :name="$gettext('iata code')"
          :rules="(!innerValue.use_icao ? 'required|' : '') + 'length:2'"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.icao"
          :name="$gettext('icao code')"
          :rules="(innerValue.use_icao ? 'required|' : '') + 'length:3'"
        />
      </b-col>
      <b-col cols="12" md="4">
        <custom-input
          v-model="innerValue.name"
          :name="$gettext('name')"
          rules="required"
        />
      </b-col>
      <b-col cols="12" md="12">
        <custom-input
          component="checkbox"
          v-model="innerValue.use_icao"
          :name="$gettext('use icao code')"
          noLabel
        />
      </b-col>
      <logo-picker
        model-includes-mime
        :mimeType="innerValue.logo_mime"
        @setMimeType="innerValue.logo_mime = $event"
        v-model="innerValue.logo"
        :previewUrl="innerValue.logo" />
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
import LogoPicker from '@/shared/components/others/LogoPicker.vue'

export default {
  mixins: [vModelMixin],
  components: {
    LogoPicker,
  },
  methods: {
    save() {
      this.$emit('save', this.innerValue)
    },
  },
}
</script>
