// Global variables mixin

import AuthData from '@/shared/http/auth-data'
// import sentry from '@/shared/services/sentry'
import { pendingRequests } from '@/shared/http/http'
import crud from '@/shared/http/crud'
import newCrud from '@/shared/http/crud-new'
import settings from '@/shared/settings'

export default {
  data() {
    return {
      pendingRequestsObj: pendingRequests,
      auth: AuthData,
    }
  },
  methods: {
    $vueTranslate(value) {
      return this.$gettext(value)
    },
    $isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
  },
  computed: {
    $dateFormat() {
      return 'YYYY-MM-DD'
    },
    $dateTimeFormat() {
      return 'YYYY-MM-DD HH:mm'
    },
    settings() { return settings.get() },
    $crud() { return crud },
    $newCrud() { return newCrud },
    userData() { return this.auth.userData },
    companyData() { return this.auth.companyData },
    companyOptions() {
      if (!this.auth.isLoggedIn) return {}
      const options = {}
      const companyOptions = []
      companyOptions.forEach((el) => {
        options[el.string] = (this.auth.companyData.options.indexOf(el.string) >= 0)
      })
      return options
    },
  },
}
