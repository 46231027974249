<template>
  <b-radio-group
    :id="id"
    :name="name"
    :state="state"
    size="sm"
    v-model="innerValue"
    :options="options"/>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'RadioInput',
  inheritAttrs: false,
  mixins: [vModelMixin],
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
    },
  },
}
</script>
