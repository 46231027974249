<template>
  <b-checkbox
    :id="id"
    :name="name"
    :state="state"
    size="sm"
    v-model="innerValue"
    :autocomplete="autocomplete"
    @change="$emit('change')"
  >
    {{ name }}
  </b-checkbox>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'

export default {
  name: 'TextInput',
  inheritAttrs: false,
  mixins: [vModelMixin],
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'text',
          'password',
          'number',
          'email',
        ].includes(value)
      },
    },
  },
}
</script>
