// Auth data

const instance = {
  isLoggedInChecked: false,
  isLoggedIn: false,
  userData: {},

  populateData(user) {
    this.isLoggedIn = true
    this.userData = user
  },

  resetData() {
    this.isLoggedIn = false
    this.userData = {}
  },
}

export default instance
