import Vue from 'vue'
import App from './App.vue'
import router from './router'

// STYLE
import './assets/scss/style.scss'

import './shared/plugins/index'
import './shared/components/index'
import './shared/directives/index'
import './shared/filters/index'
import './shared/mixins/index'

import setupValidator from './shared/plugins/vee-validate-setup'
import interceptorSetups from './shared/http/interceptor-setup'

Vue.config.productionTip = false

// Root instance
const VueInstance = new Vue({
  router,
  render: (h) => h(App),
})

setupValidator(VueInstance) // Validator needs $gettext and $language
interceptorSetups.forEach((setupInterceptor) => {
  setupInterceptor(VueInstance) // Interceptor needs $toast and $gettext
})

export default VueInstance.$mount('#app')
