var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-4 mb-4 d-flex text-center"},[_c('div',{staticClass:"py-2 px-3 bg-gradient-primary",staticStyle:{"border-radius":"7px","width":"100%","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'Dashboard' })}}},[_c('img',{attrs:{"src":require("../../assets/logo.png"),"width":"30px"}}),_c('h2',{staticClass:"d-inline-block text-white mb-0",staticStyle:{"position":"relative","top":"4px"}},[_vm._v("Admin")])])]),_vm._l((_vm.nav),function(item,index){return [(!item.children)?_c('b-nav-item',{key:'s'+index,staticClass:"menu-item my-1",class:{
        'item-selected': _vm.navActive(item)
      },attrs:{"to":{ name: item.routeName }}},[_c('span',{staticClass:"ml-2",class:{
          'text-primary': _vm.navActive(item)
        }},[_c('icon',{class:item.color,attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(_vm.$vueTranslate(item.name))+" ")],1)]):_c('b-nav-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('children'+index),expression:"'children'+index"}],key:'s'+index,staticClass:"menu-item my-1",class:{
        'item-selected': _vm.navActive(item)
      }},[_c('span',{staticClass:"ml-2",class:{'text-primary': _vm.childActive(item)}},[_c('icon',{class:item.color,attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(_vm.$vueTranslate(item.name))+" ")],1)]),_c('b-collapse',{key:'children'+index,attrs:{"id":'children'+index}},_vm._l((item.children),function(child,childIndex){return _c('b-nav-item',{key:'child'+childIndex,staticClass:"menu-item",class:{
          'item-selected': _vm.navActive(child),
          'mb-1': childIndex != item.children.length-1
        },style:({ opacity: _vm.navActive(child) ? 1 : 0.75 }),attrs:{"active":_vm.navActive(child),"to":{ name: child.routeName }}},[_c('span',{staticClass:"ml-4",class:{'text-black': _vm.navActive(child)}},[_c('icon',{attrs:{"icon":child.icon}}),_vm._v(" "+_vm._s(_vm.$vueTranslate(child.name))+" ")],1)])}),1)]}),_c('hr'),_c('b-nav-item',{staticClass:"mb-4",on:{"click":_vm.logOut}},[_c('span',{staticClass:"ml-2"},[_c('icon',{staticClass:"text-red",attrs:{"icon":'sign-out-alt'}}),_c('translate',{staticClass:"ml-1"},[_vm._v("Log Out")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }