<template>
  <b-form novalidate>
    <b-tabs @input="revalidate" v-model="tab">
      <b-tab>
        <template v-slot:title>
          <span :class="!infoValid ? 'text-red' : ''" v-translate>company info</span>
        </template>
        <ValidationObserver ref="infoTabObserver">
          <company-info v-model="innerValue" :isNew="isNew" />
        </ValidationObserver>
      </b-tab>
      <b-tab v-if="!isNew">
        <template v-slot:title>
          <span :class="!currencyValid ? 'text-red' : ''" v-translate>currencies</span>
        </template>
        <ValidationObserver ref="currencyTabObserver">
          <currencies v-model="innerValue.agencyCompany"/>
        </ValidationObserver>
      </b-tab>
      <b-tab v-if="!isNew">
        <template v-slot:title>
          <span :class="!integrationValid ? 'text-red' : ''" v-translate>integrations</span>
        </template>
        <ValidationObserver ref="integrationTabObserver">
          <integrations
            :isNew="isNew"
            :market="innerValue.company.market"
            v-model="innerValue.agencyCompany"
          />
        </ValidationObserver>
      </b-tab>
      <b-tab v-if="!isNew" :title="$gettext('settings & permissions')">
        <settings-permissions :isNew="isNew" v-model="innerValue.agencyCompany"/>
      </b-tab>
      <b-tab v-if="!isNew" :title="$gettext('documents')">
        <documents v-model="innerValue.agencyCompany"/>
      </b-tab>
      <b-tab v-if="!isNew" :title="$gettext('notes')">
        <notes v-model="innerValue.company"/>
      </b-tab>
    </b-tabs>
  </b-form>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
import CompanyInfo from './company_details/CompanyInfo.vue'
import Currencies from './company_details/Currencies.vue'
import Integrations from './company_details/Integrations.vue'
import SettingsPermissions from './company_details/SettingsPermissions.vue'
import Documents from './company_details/Documents.vue'
import Notes from './company_details/Notes.vue'

export default {
  mixins: [vModelMixin],
  props: ['isNew', 'activeTab'],
  components: {
    'company-info': CompanyInfo,
    currencies: Currencies,
    integrations: Integrations,
    'settings-permissions': SettingsPermissions,
    documents: Documents,
    notes: Notes,
  },
  data() {
    return {
      infoValid: true,
      currencyValid: true,
      integrationValid: true,
    }
  },
  computed: {
    tab: {
      get() {
        return this.activeTab
      },
      set(value) {
        this.$emit('changeTab', value)
      },
    },
  },
  methods: {
    async revalidate() {
      this.infoValid = await this.$refs.infoTabObserver.validate()
      this.currencyValid = await this.$refs.currencyTabObserver.validate()
      this.integrationValid = await this.$refs.integrationTabObserver.validate()
      this.$emit('revalidate')
    },
  },
}
</script>
