<template>
  <b-container>
    <b-card>
      <b-row>
        <b-col md="12">
          <h3 v-translate>stuba import</h3>
        </b-col>
        <b-col md="12">
          <b-form-file
            class="mt-3"
            id="stuba_import"
            name="stuba_import"
            v-model="stuba_import"
            size="sm"
            :placeholder="$gettext('choose a file or drop it here...')"
            :drop-placeholder="$gettext('drop file here...')"
            @input="onStubaImport"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      stuba_import: null,
    }
  },
  methods: {
    async onStubaImport(e) {
      if (!e) return
      this.$showLoading()

      this.$toast({
        title: this.$gettext('import'),
        message: this.$gettext('import started'),
        variant: 'success',
      })

      const res = await this.$crud.agency.stubaImport.save({
        obj: e,
        blocker: true,
        cancellable: this,
        disableAppendId: true,
        isBinary: true,
      }).catch(() => {
        this.$toast({
          title: this.$gettext('import'),
          message: this.$gettext('import failed'),
          variant: 'danger',
        })
      }).finally(() => {
        this.$hideLoading()
        this.stuba_import = null
      })

      if (res) {
        this.$toast({
          title: this.$gettext('import'),
          message: this.$gettext('import finished'),
          variant: 'success',
        })
      }
    },
  },
}
</script>
