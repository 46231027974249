// Uppercase force on input

/* eslint-disable no-param-reassign */
export default {
  update(el) {
    const input = el.getElementsByClassName('form-control')[0]
    input.value = input.value.toUpperCase()
    input.dispatchEvent(new Event('input'))
  },
}
