<template>
  <ValidationProvider
    :rules="buildValidationRules"
    :name="uuidv4"
    v-slot="vContext"
    slim
  >
    <!-- FORM GROUP -->
    <b-form-group
      v-if="groupType === 'form'"
      :label-for="$attrs.id || uuidv4"
      label-size="sm"
    >
      <slot v-if="!noLabel && component !== 'file'" name="label">
        <template slot="label">
          {{ $attrs.name }} <span v-if="rules && rules.indexOf('required') !== -1">*</span>
        </template>
      </slot>
      <!-- FILE CASE -->
      <slot v-if="!noLabel && component === 'file'" name="label">
        <template slot="label">
          {{ $attrs.name }} <span v-if="rules && rules.indexOf('required') !== -1">*</span>
        </template>
      </slot>
      <component
        @uploaded="onFileUpload"
        :file="innerFile"
        :is="getComponent"
        :type="type"
        :rules="buildValidationRules"
        v-model="innerValue"
        :id="$attrs.id || uuidv4"
        v-bind="$attrs"
        :state="buildValidationRules ? $vState(vContext) : null"
        v-on="$listeners"
        :size="size"
      />
      <b-form-invalid-feedback :force-show="$vState(vContext) === false">
        {{ $vErrors(vContext) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- INPUT GROUP -->
    <b-input-group
      v-else-if="groupType === 'input'"
      size="sm"
    >
      <slot name="prepend" />
      <component
        @uploaded="onFileUpload"
        :file="innerFile"
        :is="getComponent"
        :type="type"
        :rules="buildValidationRules"
        v-model="innerValue"
        style="flex: 1 1 auto; width: 1%; min-width: 0;"
        v-bind="$attrs"
        :state="buildValidationRules ? $vState(vContext) : null"
        v-on="$listeners"
        :size="size"
      />
      <slot name="append" />
      <b-form-invalid-feedback :force-show="$vState(vContext) === false">
        {{ $vErrors(vContext) }}
      </b-form-invalid-feedback>
    </b-input-group>
  </ValidationProvider>
</template>

<script>
import vModelMixin from '@/shared/mixins/v-model'
import { v4 as uuidv4 } from 'uuid'

import TextInput from './TextInput.vue'
import NumberInput from './NumberInput.vue'
import TextAreaInput from './TextAreaInput.vue'
import FileInput from './FileInput.vue'
import SelectInput from './SelectInput.vue'
import DateInput from './DateInput.vue'
import CheckboxInput from './CheckboxInput.vue'
import RadioInput from './RadioInput.vue'

export default {
  name: 'CustomInput',
  mixins: [vModelMixin],
  props: {
    file: {
      type: Object,
      default: null,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'text',
          'password',
          'email',
        ].includes(value)
      },
    },
    groupType: {
      type: String,
      default: 'form',
      validator(value) {
        return [
          'form',
          'input',
          null,
        ].includes(value)
      },
    },
    component: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'text',
          'number',
          'textarea',
          'file',
          'select', // includes multiselect
          'date', // includes datetime
          'checkbox',
          'radio',
        ].includes(value)
      },
    },
  },
  created() {
    if (this.file) this.innerFile = this.file
  },
  data() {
    return {
      innerFile: null,
      uuidv4: uuidv4(), // unique id
    }
  },
  methods: {
    onFileUpload(event) {
      this.innerFile = event
      this.$emit('uploaded', event)
    },
  },
  computed: {
    buildValidationRules() {
      let dateRule

      if (this.component === 'date') {
        if (typeof this.rules === 'string') {
          if (this.$attrs.time) dateRule = 'datetime'
          else dateRule = 'date'
          return this.rules ? `${this.rules}|${dateRule}` : `${dateRule}`
        }
      }

      return this.rules
    },
    getComponent() {
      switch (this.component) {
        case 'text':
          return TextInput
        case 'number':
          return NumberInput
        case 'textarea':
          return TextAreaInput
        case 'file':
          return FileInput
        case 'select':
          return SelectInput
        case 'date':
          return DateInput
        case 'checkbox':
          return CheckboxInput
        case 'radio':
          return RadioInput
        default:
          return TextInput
      }
    },
  },
}
</script>
