<template>
  <div>
    <custom-input
      v-model="innerValue.booking_default_brand_id"
      :name="$gettext('default brand')"
      component="select"
      :options="brandOptions"
    />
    <b-table
      class="mb-0"
      striped
      small
      hover
      responsive
      :items="innerValue.brands"
      :fields="fields"
      :show-empty="innerValue.brands && !innerValue.brands.length"
      :empty-text="$gettext('no results found')"
    >
      <template v-slot:head(buttons)>
        <b-btn
          variant="outline-primary"
          size="sm"
          class="float-right"
          @click="edit()">
          <fa-icon icon="plus"/>
          <translate>add</translate>
        </b-btn>
      </template>
      <template v-slot:cell(buttons)="{ index }">
        <edit-delete-buttons
          @edit="edit(index)"
          @remove="remove(index)"
        />
      </template>
    </b-table>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      button-size="sm"
      size="lg"
      ref="modal"
      :lazy="true"
      :title="objIndex !== undefined ? $gettext('edit') : $gettext('add')"
      @ok.prevent="save"
      >
      <template v-slot:modal-footer>
        <modal-buttons
          :type="objIndex !== undefined ? 'update' : 'add'"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <ValidationObserver ref="modalForm" slim>
        <Form
          v-model="obj"
          :offices="innerValue.offices"
          @save="save"/>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import vModelMixin from '@/shared/mixins/v-model'
import cloneDeep from 'lodash/cloneDeep'
import Form from './BrandEdit.vue'

export default {
  components: {
    Form,
  },
  mixins: [vModelMixin],
  data() {
    return {
      sortBy: null,
      sortDirection: null,
      fields: [
        { key: 'name', label: this.$gettext('name') },
        { key: 'buttons', label: '' },
      ],
      objIndex: null,
      obj: {},
    }
  },
  computed: {
    brandOptions() {
      return [
        { text: this.$gettext('please select'), value: null },
        ...this.innerValue.brands
          .filter((brand) => !!brand.id)
          .map((brand) => ({ text: brand.name, value: brand.id }))
          .sort((a, b) => {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          }),
      ]
    },
  },
  methods: {
    edit(index) {
      this.objIndex = index
      if (index !== undefined) this.obj = cloneDeep(this.innerValue.brands[index])
      else {
        this.obj = {
          company_code: this.$route.params.companyCode,
          default_office_id: null,
          logo: null,
          footer_image: null,
        }
      }
      this.$refs.modal.show()
    },
    async remove(index) {
      const accepted = await this.$confirmModal()

      if (accepted) {
        this.innerValue.brands.splice(index, 1)
        this.$toastDeleteSuccess()
      }
    },
    async save() {
      const isValid = await this.$refs.modalForm.validate()
      if (isValid) {
        if (this.objIndex !== undefined) {
          this.innerValue.brands.splice(this.objIndex, 1, this.obj)
        } else {
          this.innerValue.brands.push(this.obj)
        }
        this.$toastSaveSuccess()
        this.cancel()
      } else {
        this.$toastInvalidForm()
      }
    },
    cancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
