<template>
  <b-form @submit.prevent="save" novalidate>
    <b-row>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.label"
          :name="$gettext('label')"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.valid_from"
          :name="$gettext('valid from')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.valid_to"
          :name="$gettext('valid to')"
          component="date"
          rules="required"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.times_aplicable"
          :name="$gettext('times applicable')"
          rules="required"
          component="number"
        />
      </b-col>
      <b-col sm="6">
        <custom-input
          v-model="innerValue.percentage"
          :name="$gettext('percentage')"
          rules="required"
          component="number"
        />
      </b-col>
      <b-col sm="12">
        <custom-input
          v-model="innerValue.description"
          :name="$gettext('description')"
          component="textarea"
        />
      </b-col>
    </b-row>
    <b-btn type="submit" v-show="false" />
  </b-form>
</template>

<script>
/* eslint-disable array-callback-return */
import vModelMixin from '@/shared/mixins/v-model'

export default {
  mixins: [vModelMixin],
  methods: {
    save() {
      this.$emit('save')
    },
  },
}
</script>
